import "./full-screen-player.css"
import { Show, onMount, onCleanup, createEffect } from "solid-js"
import {Howl, Howler} from 'howler'
import { PlayerUtils } from '../../../player/utils/_index'
import { Utils } from "../../../../../utils/_index"
import PlaySkipWidget from "../controls/player-controls/widgets/play-skip-widget/play-skip-widget"
import SongTrackerWidget from "../controls/player-controls/widgets/song-tracker-widget/song-tracker-widget"
import VolumeControl from "../controls/volume-control/volume-control"
import VisualizerControl from "../controls/visualizer-control/visualizer-control"
// import Vudio from 'vudio'
import * as Vudio from "./vudio"
import { A, useNavigate  } from "@solidjs/router";

export default () => {
  const navigate = useNavigate()
  let audioWaveWrapperRef
  let canvasRef
  let vudio
  let fullScreenPlayerVideoRef


  const initWaveForm = () => {
    let audioNode = Howler._html5AudioPool.slice(-1)[0];

    let frequency = new Uint8Array(PlayerUtils.audioFrequencyAnalyzer().frequencyBinCount);
    console.log('frequency', frequency)
    
    vudio = new Vudio(audioNode, PlayerUtils.audioFrequencyAnalyzer(), PlayerUtils.audioGainNode(), canvasRef, {
      effect : 'circlebar', // waveform, circlewave, circlebar, lighting (4 visual effect)
      accuracy : 128, // number of freqBar, must be pow of 2.
      circlebar: {
        maxHeight : 40,
        fadeSide: true,
        particle: false,
        shadowBlur : 4,
        shadowColor: 'rgba(244,244,244,.5)',
        coverImg: PlayerUtils.songPlayingObject() ? PlayerUtils.formatSongImagePath(PlayerUtils.songPlayingObject().artwork): null,
      },
    })
    vudio.dance()
  }


  createEffect(() => {
    // pause video when song paused
    if (PlayerUtils.songPaused() && fullScreenPlayerVideoRef && !fullScreenPlayerVideoRef.paused) {
      fullScreenPlayerVideoRef.pause()
    } else if(fullScreenPlayerVideoRef && fullScreenPlayerVideoRef.paused) {
      fullScreenPlayerVideoRef.play()
    }
  })


  onMount(() => {
    Utils.docReady(() => {
      // PlayerUtils.initAudioVisualizer()
      // initWaveForm()

      // setInterval(() => {
      //   let analyser = PlayerUtils.audioFrequencyAnalyzer()
      //   let bufferLength = new Uint8Array(analyser.frequencyBinCount);
      //   const frequencyData = new Uint8Array(bufferLength);
      //   analyser.getByteFrequencyData(frequencyData);
      //   console.log(frequencyData);
      // }, 1000);
    })
  })

  onCleanup(() => {
    if(vudio) {
      vudio.pause()
    }
  })

  return (
    <div class="fullScreenPlayerParentWrapper">
      {/* Video Artwork */}
      <Show when={PlayerUtils.songPlayingObject() && PlayerUtils.songPlayingObject().video_artwork}>
        <video ref={fullScreenPlayerVideoRef} autoplay={!PlayerUtils.songPaused()} loop muted playsinline>
          <source src={PlayerUtils.songPlayingObject().video_artwork} />
          Your browser does not support the video tag.
        </video>
      </Show>

      {/* Rest of full screen player */}
      <div class="fullScreenPlayerContainer" classList={{
        'justify-end height-fill-available': PlayerUtils.songPlayingObject() && PlayerUtils.songPlayingObject().video_artwork
      }}>
        <div class="songDetailsContainer">
          <a
          class="songName"
          href={PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().absolute_url: ''}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate(PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().absolute_url: '')
            PlayerUtils.setFullScreenPlayerOpen(false)
          }}
          >
            {PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().title: ''}
          </a>

          <a
          class="artistName"
          href={PlayerUtils.songPlayingObject() ? `/@${PlayerUtils.songPlayingObject().artistKeyword}/music`: ''}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate(PlayerUtils.songPlayingObject() ? `/@${PlayerUtils.songPlayingObject().artistKeyword}/music`: '')
            PlayerUtils.setFullScreenPlayerOpen(false)
          }}
          >
            {PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().artistName: ''}
          </a>

          <Show when={PlayerUtils.songPlayingObject() && !PlayerUtils.songPlayingObject().video_artwork}>
            <img class="songImage" src={PlayerUtils.songPlayingObject() ? PlayerUtils.formatSongImagePath(PlayerUtils.songPlayingObject().artwork): null}
            classList={{
              'rotate': PlayerUtils.songPlayerInstance() && !PlayerUtils.playerLoadingSong(),
              'pauseRotation': PlayerUtils.songPlayerInstance() && PlayerUtils.songPaused()
            }}/>
          </Show>
        </div>

        <div class="playerControlsContainer" classList={{'videoShowing': PlayerUtils.songPlayingObject() && PlayerUtils.songPlayingObject().video_artwork}}>
          <div class="trackerAndVolumeControlsWrapper">
            {/* Song Tracker */}
            <SongTrackerWidget/>
            <div class="volumeVisualizerIconsWrapper">
              {/* Visualizer */}
              <VisualizerControl/>
              
              {/* Volume Controls - Mobile */}
              <VolumeControl showOnMobile={true}/>

              {/* Volume Controls - Desktop */}
              <VolumeControl showOnMobile={false}/>
            </div>
          </div>

          {/* Play Skip Controls */}
          <PlaySkipWidget/>
        </div>
      </div>
      
      <div 
      onClick={() => PlayerUtils.setFullScreenPlayerOpen(false)}
      class="fullScreenPlayerCloseIcon"
      >
        <i class="ri-close-line"></i>
      </div>
    </div>
  )
}
