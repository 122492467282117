import { onMount, Show } from "solid-js"
import "./player.css"
import FullScreenPlayer from "./widgets/full-screen-player/full-screen-player"
import Visualizer from "./widgets/visualizer/visualizer"
import BottomPlayer from "./widgets/bottom-player/bottom-player"
import CollapsedPlayer from "./widgets/collapsed-player/collapsed-player"
import { PlayerActions, PlayerUtils } from "./utils/_index"
import { Utils } from "../../../utils/_index"
import { sendBeaconPostRequest } from "../../../services/api.service"

export default () => {

  onMount(() => {
    Utils.docReady(() => {
      // Open player web socket connection
      PlayerUtils.openPlayerStateWebsocketConnection()
      
      // Page Reload, Tab and Browser Close, pause song
      window.addEventListener('beforeunload', (event) => {
        const listenId = PlayerUtils.uniqueSongListenId();
        const url = "/pause-song-listen";
        const data = {listen_id: listenId }

        sendBeaconPostRequest({
          url,
          postData:data
        }) 
      })

      // Detect keyboard space bar and left/right arrow presses
      window.onkeydown = (e) => { 
        // space bar
        if (e.which == 32) {
          // ensure user is not typing
          const el = e.srcElement || e.target
          const isTyping = /textarea|input/i.test(el.tagName)
          
          // Check if user is interacting with Sentry feedback dialog
          const sentryFeedback = document.getElementById('sentry-feedback')
          const isInSentryDialog = sentryFeedback && sentryFeedback.contains(el)

          if(!isTyping && !isInSentryDialog && PlayerUtils.songPlayerInstance()) {
            PlayerActions.playPauseSong()
            return false
          }
        }

        // left arrow
        if (e.which == 37) {
          if(PlayerUtils.songPlayerInstance()) {
            PlayerActions.skip('previous')
            return false
          }
        }

        // right arrow
        if (e.which == 39) {
          if(PlayerUtils.songPlayerInstance()) {
            PlayerActions.skip('next')
            return false
          }
        }
      }
    })
  })

  return (
    <Show 
    when={!PlayerUtils.mobilePlayerCollapsed()}
    fallback={
      <CollapsedPlayer/>
    }
    >
      <div
      class="playerParentContainer"
      classList={{'h-full': PlayerUtils.visualizerOpen()}}>

        <Show when={!(PlayerUtils.fullScreenPlayerOpen())} fallback={
          <Show when={!PlayerUtils.visualizerOpen()} fallback={<></>}>
            <FullScreenPlayer/>
          </Show>
        }>
          <Show when={PlayerUtils.visualizerOpen()}>
            <Visualizer/>
          </Show>
          <BottomPlayer/>
        </Show>
      </div>
    </Show>
  )
}
