import { createComponent } from 'solid-js/web';
import { createContext, splitProps, mergeProps, useContext, createSignal, createMemo, onMount, createComputed, on, onCleanup, Show, children } from 'solid-js';

const TransitionGroupContext = createContext(null);
var TransitionGroupContext$1 = TransitionGroupContext;

function nextFrame(fn) {
  requestAnimationFrame(() => {
    requestAnimationFrame(fn);
  });
}

/**
 * The Transition component lets you describe a transition from one component
 * state to another _over time_ with a simple declarative API. Most commonly
 * it's used to animate the mounting and unmounting of a component, but can also
 * be used to describe in-place transition states as well.
 *
 * ---
 *
 * **Note**: `Transition` is a platform-agnostic base component. If you're using
 * transitions in CSS, you'll probably want to use
 * [`CSSTransition`](https://reactcommunity.org/react-transition-group/css-transition)
 * instead. It inherits all the features of `Transition`, but contains
 * additional features necessary to play nice with CSS transitions (hence the
 * name of the component).
 *
 * ---
 *
 * By default the `Transition` component does not alter the behavior of the
 * component it renders, it only tracks "enter" and "exit" states for the
 * components. It's up to you to give meaning and effect to those states. For
 * example we can add styles to a component when it enters or exits:
 *
 * ```jsx
 * import { Transition } from 'react-transition-group';
 *
 * const duration = 300;
 *
 * const defaultStyle = {
 *   transition: `opacity ${duration}ms ease-in-out`,
 *   opacity: 0,
 * }
 *
 * const transitionStyles = {
 *   entering: { opacity: 1 },
 *   entered:  { opacity: 1 },
 *   exiting:  { opacity: 0 },
 *   exited:  { opacity: 0 },
 * };
 *
 * const Fade = ({ in: inProp }) => (
 *   <Transition in={inProp} timeout={duration}>
 *     {state => (
 *       <div style={{
 *         ...defaultStyle,
 *         ...transitionStyles[state]
 *       }}>
 *         I'm a fade Transition!
 *       </div>
 *     )}
 *   </Transition>
 * );
 * ```
 *
 * There are 4 main states a Transition can be in:
 *  - `'entering'`
 *  - `'entered'`
 *  - `'exiting'`
 *  - `'exited'`
 *
 * Transition state is toggled via the `in` prop. When `true` the component
 * begins the "Enter" stage. During this stage, the component will shift from
 * its current transition state, to `'entering'` for the duration of the
 * transition and then to the `'entered'` stage once it's complete. Let's take
 * the following example (we'll use the
 * [useState](https://reactjs.org/docs/hooks-reference.html#usestate) hook):
 *
 * ```jsx
 * function App() {
 *   const [inProp, setInProp] = useState(false);
 *   return (
 *     <div>
 *       <Transition in={inProp} timeout={500}>
 *         {state => (
 *           // ...
 *         )}
 *       </Transition>
 *       <button onClick={() => setInProp(true)}>
 *         Click to Enter
 *       </button>
 *     </div>
 *   );
 * }
 * ```
 *
 * When the button is clicked the component will shift to the `'entering'` state
 * and stay there for 500ms (the value of `timeout`) before it finally switches
 * to `'entered'`.
 *
 * When `in` is `false` the same thing happens except the state moves from
 * `'exiting'` to `'exited'`.
 */

const UNMOUNTED = "unmounted";
const EXITED = "exited";
const ENTERING = "entering";
const ENTERED = "entered";
const EXITING = "exiting";

function noop() {}

const defaultProps = {
  in: false,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,
  enter: true,
  exit: true,
  onEnter: noop,
  onEntering: noop,
  onEntered: noop,
  onExit: noop,
  onExiting: noop,
  onExited: noop
};
const Transition = p => {
  const [local, childProps] = splitProps(mergeProps(defaultProps, p), ["in", "children", "mountOnEnter", "unmountOnExit", "appear", "enter", "exit", "timeout", "addEndListener", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "nodeRef"]);
  let context = useContext(TransitionGroupContext$1);
  let childRef; // In the context of a TransitionGroup all enters are really appears

  let appear = context && !context.isMounting ? local.enter : local.appear;
  let initialStatus;
  let appearStatus = null;

  if (local.in) {
    if (appear) {
      initialStatus = EXITED;
      appearStatus = ENTERING;
    } else {
      initialStatus = ENTERED;
    }
  } else {
    if (local.unmountOnExit || local.mountOnEnter) {
      initialStatus = UNMOUNTED;
    } else {
      initialStatus = EXITED;
    }
  }

  const [status, setStatus] = createSignal(initialStatus);
  let nextCallback = null;
  const [mounted, setMounted] = createSignal(false);
  const notUnmounted = createMemo(() => status() !== UNMOUNTED);
  onMount(() => {
    // componentDidMount
    updateStatus(true, appearStatus);
    setMounted(true);
  }); // Detect actual changes to `in` prop via memo

  const inMemo = createMemo(() => local.in);
  createComputed(on(inMemo, () => {
    // componentDidUpdate
    if (!mounted()) return;
    const prevStatus = status();

    if (inMemo() && prevStatus === UNMOUNTED) {
      // prepare to show again
      setStatus(EXITED);
    }

    let nextStatus = null;

    if (inMemo()) {
      if (prevStatus !== ENTERING && prevStatus !== ENTERED) {
        nextStatus = ENTERING;
      }
    } else {
      if (prevStatus === ENTERING || prevStatus === ENTERED) {
        nextStatus = EXITING;
      }
    }

    updateStatus(false, nextStatus ?? EXITED);
  }));
  onCleanup(() => {
    cancelNextCallback();
  });

  function getTimeouts() {
    const {
      timeout
    } = local;
    let exit, enter, appear;

    if (typeof timeout === "number") {
      exit = enter = appear = timeout;
    } else if (timeout != null) {
      exit = timeout.exit;
      enter = timeout.enter; // TODO: remove fallback for next major

      appear = timeout.appear !== undefined ? timeout.appear : enter;
    }

    return {
      exit,
      enter,
      appear
    };
  }

  function updateStatus(mounting = false, nextStatus) {
    if (nextStatus !== null) {
      // nextStatus will always be ENTERING or EXITING.
      cancelNextCallback();

      if (nextStatus === ENTERING) {
        performEnter(mounting);
      } else {
        performExit();
      }
    } else if (local.unmountOnExit && status() === EXITED) {
      setStatus(UNMOUNTED);
    }
  }

  function performEnter(mounting) {
    const {
      enter
    } = local;
    const appearing = context ? context.isMounting : mounting;
    const [maybeNode, maybeAppearing] = local.nodeRef ? [appearing] : [childRef, appearing];
    const timeouts = getTimeouts();
    const enterTimeout = appearing ? timeouts.appear : timeouts.enter; // no enter animation skip right to ENTERED
    // if we are mounting and running this it means appear _must_ be set

    if (!mounting && !enter) {
      safeSetState(ENTERED, () => {
        local.onEntered(maybeNode);
      });
      return;
    }

    local.onEnter(maybeNode, maybeAppearing);
    nextFrame(() => safeSetState(ENTERING, () => {
      local.onEntering(maybeNode, maybeAppearing);
      onTransitionEnd(enterTimeout, () => {
        safeSetState(ENTERED, () => {
          local.onEntered(maybeNode, maybeAppearing);
        });
      });
    }));
  }

  function performExit() {
    const {
      exit
    } = local;
    const timeouts = getTimeouts();
    const maybeNode = local.nodeRef ? undefined : childRef; // no exit animation skip right to EXITED

    if (!exit) {
      safeSetState(EXITED, () => {
        local.onExited(maybeNode);
      });
      return;
    }

    local.onExit(maybeNode);
    nextFrame(() => safeSetState(EXITING, () => {
      local.onExiting(maybeNode);
      onTransitionEnd(timeouts.exit, () => {
        safeSetState(EXITED, () => {
          local.onExited(maybeNode);
        });

        if (local.unmountOnExit) {
          nextFrame(() => {
            setStatus(UNMOUNTED);
          });
        }
      });
    }));
  }

  function cancelNextCallback() {
    if (nextCallback !== null) {
      nextCallback?.cancel();
      nextCallback = null;
    }
  }

  function safeSetState(nextState, callback) {
    // This shouldn't be necessary, but there are weird race conditions with
    // setState callbacks and unmounting in testing, so always make sure that
    // we can cancel any pending setState callbacks after we unmount.
    callback = setNextCallback(callback);
    setStatus(nextState);
    callback();
  }

  function setNextCallback(callback) {
    let active = true;

    nextCallback = (...args) => {
      if (active) {
        active = false;
        nextCallback = null;
        callback(...args);
      }
    };

    nextCallback.cancel = () => {
      active = false;
    };

    return nextCallback;
  }

  function onTransitionEnd(timeout, handler) {
    setNextCallback(handler);
    const node = local.nodeRef ? local.nodeRef : childRef;
    const doesNotHaveTimeoutOrListener = timeout == null && !local.addEndListener;

    if (!node || doesNotHaveTimeoutOrListener) {
      nextCallback && setTimeout(nextCallback, 0);
      return;
    }

    if (local.addEndListener) {
      const [maybeNode, maybeNextCallback] = local.nodeRef ? [nextCallback] : [node, nextCallback];
      local.addEndListener(maybeNode, maybeNextCallback);
    }

    if (timeout != null && nextCallback) {
      setTimeout(nextCallback, timeout);
    }
  }

  let resolvedChildren;

  function renderChild() {
    // lazily resolve children first time to avoid hydration errors
    if (!resolvedChildren) resolvedChildren = children(() => local.children);
    const c = resolvedChildren();
    return typeof c === "function" ? c(status(), childProps) : c;
  }

  return createComponent(TransitionGroupContext$1.Provider, {
    value: null,

    get children() {
      return createComponent(Show, {
        get when() {
          return notUnmounted();
        },

        get children() {
          return renderChild();
        }

      });
    }

  });
};

export { ENTERED, ENTERING, EXITED, EXITING, Transition, UNMOUNTED };
