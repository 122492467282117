import { onMount, Show, createSignal, createEffect, createMemo, For } from "solid-js"
import "./header-bar.css"
import { Utils } from "../../../utils/_index"
import { APIService } from '../../../services/_index'
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router'
import tuneLogoIcon from "../../../assets/icons/logo_icon.png"
import { Icon } from 'solid-heroicons'
import { chevronDown } from 'solid-heroicons/outline'
import { createPathMatcher } from '../../../utils/utils'
import { setSearchQuery } from '../../../utils/filters'
import $ from 'jquery'
import {
  IoWalletOutline,
  IoCloudUploadOutline,
  IoMailOutline
} from 'solid-icons/io' // IonIcons
import {
  RiUserFacesUser6Line,
  RiMediaGalleryLine,
  RiDocumentFileUserLine,
  RiSystemSettingsLine,
  RiSystemLogoutCircleRLine,
  RiSystemShieldCheckLine,
  RiArrowsArrowLeftRightLine,
  RiArrowsArrowRightSLine,
  RiSystemCloseLine,
  RiSystemMenu5Line,
  RiDesignEditCircleLine
} from 'solid-icons/ri' // Remix Icons

export default () => {
  let headerBarRef, extraOptionsParentDivRef, extraOptionsDropdownRef, switchProfileWrapperRef
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const pathname = createMemo(() => routerLocation.pathname)
  const [lastPathName, setLastPathName] = createSignal(pathname())
  const [profileData, setProfileData] = createSignal(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = createSignal('')
  const [extraOptionsDropdownOpen, setExtraOptionsDropdownOpen] = createSignal(false)
  const [switchProfileMenuOpen, setSwitchProfileMenuOpen] = createSignal(false)
  const urlsToExcludeHeaderBar = [
    '/join', '/login', '/password-recovery', '/verify', '/not-verified', '/player/mobile-app-viz',
    '/kyc/desktop-app-sumsub-verification-page',
  ]
  const pathToFanmail = '/mail/inbox'
  const pathToMusicDashboard = '/my-account/music'
  const pathToProfileDashboard = '/my-account/profile'
  const pathToWalletDashboard = '/my-account/wallet'
  const pathToBuySellDashboard = '/my-account/buy-sell-jam'
  const pathToKYCDashboard = '/my-account/kyc'
  const pathToGalleryDashboard = '/my-account/gallery'
  const pathToPagesDashboard = '/my-account/pages'
  const pathToSettingsDashboard = '/my-account/settings'
  const [pathToOwnProfilePage, setPathToOwnProfilePage] = createSignal('/')

  const checkIfRouteMatches = (path: () => string) => {
    const matcher = createMemo(() => createPathMatcher(path()))
    return createMemo(() => matcher()(Utils.currentPath()))
  }
  
  const manipulateHeaderBarDisplayOnPathChange = () => {
    // Hide header bar on some pages e.g. onboarding
    if(headerBarRef) {
      if(urlsToExcludeHeaderBar.some(path => pathname().includes(path))) {
        headerBarRef.style.display = 'none'
      } else {
        headerBarRef.style.display = 'flex'
      }
    }
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    setSearchQuery(e.target.value)
  }

  const processUserData = () => {
    setProfileData(Utils.userProfileData())
    Utils.setProfileName(Utils.userProfileData().view.name)
    Utils.setProfileType(Utils.userProfileData().view.account_type)
    setPathToOwnProfilePage(Utils.userProfileData().view.url)
  }

  const switchProfileToUser = async (urlpath, params) => {
    await APIService.getRequest({url:'/api/v1/profile/switch-profile', params,
      onSuccess:(response)=>{
        Utils.setPageSwitch(true)
        navigate(urlpath)
        // reload window to ensure every aspect of the web app is reloaded to and new profile is picked up
        setTimeout(() => {
          window.location.reload() 
      }, 300)
      }, displayErrorMessage: true
    })
  }

  /*
  createEffect(() => {
    // Monitor user login status
    if (Utils.userLoggedIn() && !profileData()) {
      fetchUser()
    }
  })
  */

  createEffect(() => {
    if (Utils.userLoggedIn() && Utils.userProfileData()) {
      processUserData()
    }
  })

  createEffect(() => {
    // Monitor pathname changes
    if(pathname() != lastPathName()) {
      setLastPathName(pathname())
      manipulateHeaderBarDisplayOnPathChange()
    }
  }, [pathname()])

  onMount(() => {
    Utils.docReady(() => {
      manipulateHeaderBarDisplayOnPathChange()

      // Check if browser path contains serch term
      // and display it on search box
      if (searchParams.q) {
        let term = searchParams.q
        if (term.slice(-1) == '/') {
          term = term.slice(0, -1)
        }
        setSearchTerm(term)
      }

      // Listen for enter key press when typing on search box
      $(document).keyup((event) => {
        if ($('#searchInput').is(':focus') && event.key == 'Enter') {
          setSearchQuery(searchTerm())
        }
      })

      window.addEventListener('beforeunload', async (event) => {
        // delete all cached data related to profile cache
        Utils.clearCachedAPIdataForSpecificUrls(Utils.urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh())
      })

    })
  })

  // Dismiss options dropdown on outside click
  window.addEventListener("mouseup", (event) => {
    if (extraOptionsDropdownRef)
      if (
        !extraOptionsDropdownRef.contains(event.target) 
        && !extraOptionsParentDivRef.contains(event.target)
        && (!switchProfileWrapperRef || !switchProfileWrapperRef.contains(event.target))
      ) {
        setExtraOptionsDropdownOpen(false)
        setSwitchProfileMenuOpen(false)
      }
  })

  return (
    <div 
    ref={headerBarRef}
    class="headerBarParentContainer"
    // use custom class for logged out view of header to ensure proper dispay of onboarding buttons on mobile web
    classList={{'optimizeHeaderForMobile': !Utils.userLoggedIn()}}
    >
      <div class="rightSectionContainer">
        <div 
        class="sidebarToggleDiv"
        onClick={() => Utils.toggleSidebarDisplay()}
        >
          {/* Menu icon */}
          <Show when={!Utils.isSidebarShowing()}>
            <RiSystemMenu5Line class="sidebarCloseOpenToggleIcon"/>
          </Show>
          {/* Small Tune.fm icon that shows only on mobile web */}
          <img
            class="tuneLogoSmall"
            src={tuneLogoIcon}
          />
        </div>
        
        {/* Search bar */}
        <div class="searchBarContainer">
          <i class="ri-search-line"></i>
          <input
            id="searchInput"
            type="search"
            placeholder="Search by artist, song, album e.t.c"
            autocomplete="off"
            value={searchTerm()}
            onPaste={(e) => e.preventDefault()}
            onInput={handleSearch}
          />
        </div>
      </div>

      {/* Loading indicator for profile and settings menu dropdown */}
      <Show when={Utils.userLoading()}>
        <div 
        ref={extraOptionsParentDivRef}
        class="menuParentContainer"
        >
          <div class="spinner spinner--medium"></div>
        </div>
      </Show>

      {/* Profile and settings menu dropdown (Logged in view) */}
      <Show when={Utils.userLoggedIn() && profileData()}>
        <div 
        ref={extraOptionsParentDivRef}
        class="menuParentContainer"
        >
          {/* Profile details container */}
          <div
          class="profileDetailsContainer"
          onClick={() => {
            setExtraOptionsDropdownOpen(!extraOptionsDropdownOpen())
          }}
          >
            {/* Profile name and profile type */}
            <div class="textDetailsWrapper">
              <span class="profileName">{Utils.profileName()}</span>
              <span class="profileType">{Utils.profileType() == 'band' ? 'Band/Group': Utils.profileType()}</span>
            </div>
            {/* Profile Image */}
            <img
            class="profileImage headerProfileImage"
            src={profileData().view.img_path}
            />
            {/* Options dropdown icon */}
            <Icon
              path={chevronDown}
              aria-hidden="true"
              data-prefix="fas"
              class="optionsDropdownIcon"
            />
          </div>
          {/* Extra settings options dropdown menu container */}
          <Show when={extraOptionsDropdownOpen()}>
            <div ref={extraOptionsDropdownRef} class="optionsDropdownContainer">
              <Show
                when={profileData().accounts.length > 1}
                fallback={<></>}
              >
                <div 
                class="switchProfileWrapper"
                ref={switchProfileWrapperRef}
                >
                  <div 
                  class="buttonWrapper"
                  onClick={() => {setSwitchProfileMenuOpen(true)}}
                  >
                    <span>Switch profile</span>
                    <RiArrowsArrowRightSLine/>
                  </div>
                  <Show when={switchProfileMenuOpen()}>
                    <div class="switchProfileDropdownMenu">
                      {/* switchProfileDropdownMenu title */}
                      <div class="titleWrapper">
                        <span>Switch profile</span>
                        {/* switchProfileDropdownMenu close icon */}
                        <div
                          onClick={() => setSwitchProfileMenuOpen(false)}
                          class="closeIcon"
                        >
                          <RiSystemCloseLine class="text-gray-200 text-2xl" />
                        </div>
                      </div>
                      {/* switchProfileDropdownMenu list */}
                      <div class="list">
                        <For each={profileData().accounts}>
                          {(profile, i) => (
                            <Show
                              when={profile.keyword != profileData().view.keyword}
                              fallback={<></>}
                            >
                              <div 
                              class="switchProfileItem"
                              onClick={() => {
                                switchProfileToUser(profile.url, {
                                  profileid: profile.id,
                                })
                                setSwitchProfileMenuOpen(false)
                                setExtraOptionsDropdownOpen(false)
                              }}
                              >
                                <img
                                  src={profile.img_path}
                                />
                                <span>{profile.short_name}</span>
                              </div>
                            </Show>
                          )}
                        </For>
                      </div>
                    </div>
                  </Show>
                </div>
              </Show>
              <div class="dropdownListWrapper">
                <ul>
                  {/* View profile link */}
                  <li>
                    <a
                      href={pathToOwnProfilePage()}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToOwnProfilePage())
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToOwnProfilePage())()
                        ),
                      }}
                    >
                      <RiUserFacesUser6Line/>
                      <span>View Profile</span>
                    </a>
                  </li>
                  {/* Profile dashboard link */}
                  <li>
                    <a
                      href={pathToProfileDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToProfileDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToProfileDashboard)()
                        ),
                      }}
                    >
                      <RiDesignEditCircleLine/>
                      <span>Edit Profile</span>
                    </a>
                  </li>
                  {/* Music dashboard link */}
                  <li>
                    <a
                      href={pathToMusicDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToMusicDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToMusicDashboard)()
                        ),
                      }}
                    >
                      <IoCloudUploadOutline/>
                      <span>My Music</span>
                    </a>
                  </li>
                  {/* Wallet dashboard link */}
                  <li>
                    <a
                      href={pathToWalletDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToWalletDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToWalletDashboard)()
                        ),
                      }}
                    >
                      <IoWalletOutline/>
                      <span>Wallet</span>
                    </a>
                  </li>
                  {/* Fanmail link */}
                  <li>
                    <a
                      href={pathToFanmail}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToFanmail)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToFanmail)()
                        ),
                      }}
                    >
                      <IoMailOutline/>
                      <span>Fanmail</span>
                    </a>
                  </li>
                  {/* Buy/sell dashboard link */}
                  <li>
                    <a
                      href={pathToBuySellDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToBuySellDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToBuySellDashboard)()
                        ),
                      }}
                    >
                      <RiArrowsArrowLeftRightLine/>
                      <span>Buy/Sell JAM</span>
                    </a>
                  </li>
                  {/* KYC dashboard link */}
                  <li>
                    <a
                      href={pathToKYCDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToKYCDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToKYCDashboard)()
                        ),
                      }}
                    >
                      <RiSystemShieldCheckLine/>
                      <span>Security</span>
                    </a>
                  </li>
                  {/* Gallery dashboard link */}
                  <li>
                    <a
                      href={pathToGalleryDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToGalleryDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToGalleryDashboard)()
                        ),
                      }}
                    >
                      <RiMediaGalleryLine/>
                      <span>Gallery</span>
                    </a>
                  </li>
                  {/* Pages dashboard link */}
                  <li>
                    <a
                      href={pathToPagesDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToPagesDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToPagesDashboard)()
                        ),
                      }}
                    >
                      <RiDocumentFileUserLine/>
                      <span>Pages</span>
                    </a>
                  </li>
                  {/* Settings dashboard link */}
                  <li>
                    <a
                      href={pathToSettingsDashboard}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(pathToSettingsDashboard)
                        setExtraOptionsDropdownOpen(false)
                      }}
                      classList={{
                        routerLinkActiveChildItem: Boolean(
                          checkIfRouteMatches(() => pathToSettingsDashboard)()
                        ),
                      }}
                    >
                      <RiSystemSettingsLine/>
                      <span>Settings</span>
                    </a>
                  </li>
                  {/* Logout link */}
                  <li>
                    <div
                      class="innerDiv"
                      onClick={() => {
                        APIService.logoutUser({ passNextUrlParam: false })
                        setExtraOptionsDropdownOpen(false)
                      }}
                    >
                      <RiSystemLogoutCircleRLine/>
                      <span>Logout</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Show>
        </div>
      </Show>

      {/* Show create-account and sign in options on logged out view */}
      <Show when={!Utils.userLoggedIn()}>
        <div class="onboardingOptions">
          <a
            href='/login'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate('/login')
            }}
            class="loginButton"
          >
            Sign In
          </a>

          <a
            href='/join'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate('/join')
            }}
            class="registerButton"
          >
            Create an Account
          </a>
        </div>
      </Show>
    </div>
  )
}
