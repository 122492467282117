import { For, Show, onMount, createSignal, createEffect, onCleanup } from "solid-js";
import moment from 'moment'
import LoadingPageIndicator from "../../../../../shared/widgets/loading-page-indicator";
import './TransactionsTable.css';
import { Utils } from "../../../../../../utils/_index"
import $ from 'jquery'
import { IoWalletOutline } from "solid-icons/io"; // IonIcons
import * as TransactionsTableUtils  from "./TransactionsTableUtils";
import { JAM_TOKEN_ID } from "../../../../../../utils/Constants";
import { useNavigate  } from "@solidjs/router";
import { APIService } from "../../../../../../services/_index";

export default function TransactionsTable(props) {
    const navigate = useNavigate()
    let walletTransactionsContainerRef, transactionsListElmRef, transactionFiltersButtonRef, transactionFiltersDivRef, transactionFiltersListRef
    const [filtersPosition, setFiltersPosition] = createSignal({ top: 0 })
    const [filtersDropdownHeight, setFiltersDropdownHeight] = createSignal(0)
    const [filtersDropdownTriggeredByClick, setFiltersDropdownTriggeredByClick] = createSignal(false)


    const getTransactionTypes = async () => {
        //get wallet transactions
        await APIService.getRequest({
            url:'/api/v1/wallet/get-wallet-transaction-types',
            allowAPIDataCaching: true, 
            returnAPICachedData: true, 

            onSuccess: (response) => {
                if (response && response?.success) {
                    TransactionsTableUtils.setTransactionTypes(response.transaction_types)
                }
                //selectTransactionType(response.transaction_types[0]) // use this to avoid duplicate transactions
                TransactionsTableUtils.getListens(props.accountId, TransactionsTableUtils.selectedTransactionType(), TransactionsTableUtils.offset())
               
                if(!props.isWalletDropUp) {
                    $("#singlePageWrapper").on('scroll', () => {
                        /////// INFINITE SCROLL
                        // Instead of using items container scrollHeight, using bounding rect as below ensures bottom scroll check is not 
                        // affected by other divs that may appear before the items container e.g. page title
                        const parentRect = document.getElementById('singlePageWrapper').getBoundingClientRect()
                        if(transactionsListElmRef) {
                            const itemsContainerRect = transactionsListElmRef.getBoundingClientRect()
                            const elementHeightRoundingAllowance = 5; // px
                            if (itemsContainerRect.bottom <= parentRect.bottom + elementHeightRoundingAllowance) {
                                loadMore()
                            }
                        }
                    })
                }
            }
        })
    }

    const selectTransactionType = async (transactionType) => {
        const startOffset = 0
        const startPageGT = "0"
        // Ensure transactions are not loaded more than once e.g. if wallet dropup is opened when
        // wallet page is loading, transactions would be displayed twice
        const transactionsAlreadyLoading = TransactionsTableUtils.loadingInfiniteScroll() 
        && TransactionsTableUtils.selectedTransactionType() == transactionType.type
        && TransactionsTableUtils.offset() == startOffset

        if(!transactionsAlreadyLoading) {
            TransactionsTableUtils.setSelectedTransactionType(transactionType.type)
            TransactionsTableUtils.setTableData([])
            localStorage.removeItem('transactionsData')
            TransactionsTableUtils.setNextPageGt(startPageGT)
            hideTransactionFiltersDropdown()
            TransactionsTableUtils.setOffset(startOffset)
            // args below allow us to verify that transactionType global has not changed since
            // request was initiated, so that we do not render the data if it has
            TransactionsTableUtils.getListens(
                props.accountId, 
                transactionType.type, 
                startOffset
            )
        }
    }

    function formatSeconds(seconds) {
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }


    const loadMore = function () {
        if (!TransactionsTableUtils.loadingInfiniteScroll() && TransactionsTableUtils.showLoadMore()) {
            const newOffset = TransactionsTableUtils.offset() + TransactionsTableUtils.count()
            TransactionsTableUtils.setOffset(newOffset)
            TransactionsTableUtils.getListens(
                props.accountId, 
                // args below allow us to verify that transactionType global has not changed since
                // request was initiated, so that we do not render the data if it has
                TransactionsTableUtils.selectedTransactionType(), 
                newOffset
            )
        }
    }

    const showTransactionFiltersDropdown = (triggeredByClick = false) => {
        setFiltersDropdownTriggeredByClick(triggeredByClick)
        setTimeout(() => {
            $('#transactionFilterDropdownList').show()
        }, 100)
    }

    const hideTransactionFiltersDropdown = () => {
        // return standard margin
        $('#transactionFilterDropdownList').hide()
        processDropdownPositioning()
    }

    const toggleDropdown = (event) => {
        if ($('#transactionFilterDropdownList').is(':hidden')) {
            showTransactionFiltersDropdown(true)
        } else {
            hideTransactionFiltersDropdown()
        }
    }

    const processDropdownPositioning = () => {
        // Calculate and set filters dropdown position so it does not extend beyond page height
        if(transactionFiltersButtonRef && walletTransactionsContainerRef) {
            const filtersButtonRect = transactionFiltersButtonRef.getBoundingClientRect()
            const walletTransactionsContainerRect = walletTransactionsContainerRef.getBoundingClientRect()

            // get filters dropdown height 
            if(filtersDropdownHeight() == 0) {
                // Temporarily set dropdown to be visible
                $('#transactionFilterDropdownList').show()
                setFiltersDropdownHeight(transactionFiltersListRef.offsetHeight)
                // hide the dropdown after retrieving height
                $('#transactionFilterDropdownList').hide()
            }

            // if height of filters dropdown exceeds available space to the bottom of the wallet transactions section,
            // update 'top' value with the space needed for the dropdown to be fully visible
            let distanceFromFiltersButtonToBottomOfTxContainer = walletTransactionsContainerRect.bottom - filtersButtonRect.bottom
            let top = 0
            if (filtersDropdownHeight() > distanceFromFiltersButtonToBottomOfTxContainer) {
            top = -(filtersDropdownHeight() - distanceFromFiltersButtonToBottomOfTxContainer)
            }
            setFiltersPosition({ top})
        }
    }

    createEffect(()=>{
        if(transactionFiltersButtonRef && walletTransactionsContainerRef){
            processDropdownPositioning()
        }
    })
    
    onMount(async () => {
        // transaction types dropdown handler
        $('#transactionFiltersButton').on('mouseenter', () => {
            if ($('#transactionFilterDropdownList').is(':hidden')) {
                showTransactionFiltersDropdown(false)
            }
        })

        $('#transactionFilterDropdown').on('mouseleave', (event) => {
            // set timeout to ensure we get mouse location after move complete
            setTimeout(() => {
                const rect = transactionFiltersDivRef.getBoundingClientRect()
                const distances = Utils.getMouseDistancesFromEdgeOfDiv(event, rect)
                // ensure mouse move is at least 15 px from div to avoid 
                // premature closing on the filters dropdown
                if (
                    Math.abs(distances.left) > 15 && 
                    Math.abs(distances.right) > 15 && 
                    Math.abs(distances.top) > 15 && 
                    Math.abs(distances.bottom) > 15 &&
                    !filtersDropdownTriggeredByClick()
                ) {
                    hideTransactionFiltersDropdown()
                }
            }, 200)
        })
        
        getTransactionTypes()
    })

    onCleanup(() => {
        $("#singlePageWrapper").off('scroll'); 
    })

    //closing or refreshing browser
    window.onbeforeunload = function(){
        localStorage.removeItem("transactionsData")
    }
 
    // close transaction filters on clicking outside dropdown list
    window.addEventListener("mouseup", (event) => {
        if (transactionFiltersDivRef)
            if (!transactionFiltersDivRef.contains(event.target) && !transactionFiltersButtonRef.contains(event.target)) {
                hideTransactionFiltersDropdown()
            }
    })

    const transactionItem = (transaction, index) => {
        const [transactionTime, setTransactionTime] = createSignal(
            moment.unix(parseInt(transaction.timestamp)).format("MM-DD-YYYY · HH:mm")
        )
        const [listenTime, setListenTime] = createSignal()
        const [showListenTime, setShowListenTime] = createSignal(false)
        const [transactionTimeDescription, setTransactionTimeDescription] = createSignal(
            `Transaction completed on ${moment.unix(parseInt(transaction.timestamp)).format("MM-DD-YYYY · HH:mm")}`
        )
        const [listenTimeDescription, setListenTimeDescription] = createSignal('')
        const [multipleTXsDropdownOpen, setMultipleTXsDropdownOpen] = createSignal(false)
        let multipleTXsDivRef, multipleTXsDropdownRef

        const isFreeTransaction = Boolean(transaction.listen_or_video_play) && transaction.listen_or_video_play.first_stream_options == 'free'
        
        const isCreditTransaction = Boolean(transaction.listen_or_video_play) && ((Boolean(transaction.listen_or_video_play.video) && !transaction.listen_or_video_play.is_mine) ||
        transaction.listen_or_video_play.first_stream_options == 'paid' && !transaction.listen_or_video_play.is_mine  ||
        transaction.listen_or_video_play.first_stream_options == 'promoted' && transaction.listen_or_video_play.is_mine)
        
        const isDebitTransaction = Boolean(transaction.listen_or_video_play) && ((Boolean(transaction.listen_or_video_play.video) && transaction.listen_or_video_play.is_mine) ||
        transaction.listen_or_video_play && transaction.listen_or_video_play.first_stream_options == 'paid' && transaction.listen_or_video_play.is_mine  ||
        transaction.listen_or_video_play && transaction.listen_or_video_play.first_stream_options == 'promoted' && !transaction.listen_or_video_play.is_mine)

        if(transaction.listen_or_video_play && transaction.listen_or_video_play.timestamp) {
            setListenTime(moment.unix(parseInt(transaction.listen_or_video_play.timestamp)).format("MM-DD-YYYY · HH:mm"))
            setListenTimeDescription(
                `Streamed on ${moment.unix(parseInt(transaction.listen_or_video_play.timestamp)).format("MM-DD-YYYY · HH:mm")}`
            )
            setShowListenTime(listenTime() != transactionTime())

            if(!showListenTime()) {
                setTransactionTimeDescription(
                    `Streamed and transaction completed on ${moment.unix(parseInt(transaction.timestamp)).format("MM-DD-YYYY · HH:mm")}`
                )
            }
        }

        window.addEventListener("mouseup", (event) => {
            if (multipleTXsDropdownRef)
              if (!multipleTXsDropdownRef.contains(event.target) && !multipleTXsDivRef.contains(event.target)) {
                setMultipleTXsDropdownOpen(false)
              }
        })
    
        return (
            <div 
            class="transactionItem"
            classList={{'fade-item fade-in': index() >= TransactionsTableUtils.lengthOfPreviousTableData()}}
            >
                {/* Left section */}
                <div class="leftWrapper">
                    <div class="firstDiv">
                        {/* User image */}
                        <Show when={transaction.listen_or_video_play} fallback={
                            <div
                            class="imagePlaceholder"
                            classList={{ 
                                'bg-green-500': transaction.amount > 0,
                                'bg-red-500': transaction.amount < 0
                            }}>
                                {transaction.amount > 0 ? <i class="ri-arrow-left-down-line"></i>: <i class="ri-arrow-right-up-line"></i>}
                            </div>
                        }>
                            <Show when={!transaction.listen_or_video_play.is_mine} fallback={
                                <img src={transaction.listen_or_video_play.user.img_path} />
                            }>
                                <a href={transaction.listen_or_video_play.user.url} target="_blank">
                                    <img src={transaction.listen_or_video_play.user.img_path} />
                                </a>
                            </Show>
                        </Show>
                    </div>
                    
                    {/* Tx From/To Info */}
                    <div class="textWrapper">
                        <Show when={transaction.listen_or_video_play} fallback={
                            <>
                                <div class="titleContainer">
                                    <span class="title">{transaction.amount > 0 ?  'From': 'To'}</span>
                                    <a class="subTitle" href={transaction.amount > 0 ? transaction.sender_account_url: transaction.receiver_account_url} target="_blank">
                                        {transaction.amount > 0 ? transaction.sender: transaction.receiver}
                                    </a>
                                    <span class="title">
                                        {transaction.memo ? `- (${transaction.memo})` : ''}
                                    </span>
                                </div>
                            </>
                        }>
                            <>
                                <div class="titleContainer">
                                    <i class="ri-headphone-line title"></i>
                                    <Show when={!transaction.listen_or_video_play.is_mine}>
                                        <a class="title" href={transaction.listen_or_video_play.user.url} target="_blank">
                                            {transaction.listen_or_video_play.user.short_name}
                                        </a>
                                    </Show>
                                    <a class="subTitle" href={transaction.listen_or_video_play.song ? transaction.listen_or_video_play.song.url : transaction.listen_or_video_play.video.url} target="_blank">
                                        {transaction.listen_or_video_play.song ?  transaction.listen_or_video_play.song.title : transaction.listen_or_video_play.video.title}
                                    </a>
                                </div>
                                <div class="timeArtistContainer">
                                    <Show when={transaction.listen_or_video_play.is_mine}>
                                        <span class="artistText overflow-visible">
                                            by
                                        </span>
                                        <a class="artistImage" href={transaction.listen_or_video_play.song ? transaction.listen_or_video_play.song.profile.url: transaction.listen_or_video_play.video.profile.url} target="_blank">
                                            <img class="artistImage" src={transaction.listen_or_video_play.song ? transaction.listen_or_video_play.song.profile.img_path: transaction.listen_or_video_play.video.profile.img_path}/>
                                        </a>
                                        <a class="artistText" href={transaction.listen_or_video_play.song ? transaction.listen_or_video_play.song.profile.url: transaction.listen_or_video_play.video.profile.url} target="_blank">
                                            {transaction.listen_or_video_play.song ? transaction.listen_or_video_play.song.profile.short_name: transaction.listen_or_video_play.video.profile.short_name}
                                        </a>
                                    </Show>
                                    {/* time below appears on mobile only */}
                                    <span class="timeCount"> 
                                        ({formatSeconds(transaction.listen_or_video_play.seconds)})
                                    </span>
                                </div>
                            </>
                        </Show>
                    </div>
                </div>

                {/* Right section */}
                <div class="rightWrapper">
                    <div class="transactionInfoWrapper">
                        {/* TX IDs */}
                        <div class="txIdsWrapper">
                            <Show when={transaction.listen_or_video_play} fallback={
                                // Non-listen tx
                                <a href={transaction.transaction_url} target="_blank">TX</a>
                            }>
                                {/* Listen txs */}
                                <Show when={transaction.listen_or_video_play.transfer_tx_ids.length > 1} fallback={
                                    // Single tx
                                    <Show when={transaction.listen_or_video_play.transfer_tx_ids.length > 0}>
                                        <a href={transaction.listen_or_video_play.transfer_tx_ids[0]} target="_blank">TX</a>
                                    </Show>
                                }>
                                    {/* Multiple txs dropdown */}
                                    <div class="dropdownContainer" classList={{'rounded-b-[0px]': multipleTXsDropdownOpen()}}>
                                        <div
                                        ref={multipleTXsDivRef}
                                        onClick={() => {
                                            setMultipleTXsDropdownOpen(!multipleTXsDropdownOpen())
                                        }}
                                        class="selectionField">
                                            <div>
                                                <span class="capitalize">Transactions</span>
                                            </div>
                                            <i class="ri-arrow-down-s-line text-white cursor-pointer"></i>
                                        </div>

                                        <Show when={multipleTXsDropdownOpen()}>
                                            <div ref={multipleTXsDropdownRef} class="selectionDropDown">
                                            <div class="dropdownListWrapper">
                                                <ul>
                                                    <For each={transaction.listen_or_video_play.transfer_tx_ids}>{(tx_id, tx_index) =>
                                                        <li>
                                                            <a class="itemName" href={tx_id} target="_blank">Transaction {tx_index() + 1}</a>
                                                        </li>
                                                    }
                                                    </For>
                                                </ul>
                                            </div>
                                            </div>
                                        </Show>
                                    </div>
                                </Show>
                            </Show>
                        </div>
                        
                        {/* Listen/Video Play time second count below appears on desktop only */}
                        <span class="timeCount">
                            {transaction.listen_or_video_play ? `${formatSeconds(transaction.listen_or_video_play.seconds)}`: ``}
                        </span>

                        {/* Tx Amount Info */}
                        <Show when={!transaction.isNFT}>
                        <Show when={transaction.listen_or_video_play} fallback={
                            // Info UI for TX from network
                            <div
                            class="amountWrapper"
                            classList={{ 
                                'text-green-400': transaction.amount > 0,
                                'text-red-400': transaction.amount < 0
                            }}>
                                <span class="tokenAmount">{transaction.tokenId == JAM_TOKEN_ID ? "Ɉ": transaction.symbol} {Utils.formatJamAmount(transaction.amount).substring(0, 10)}</span>
                                <Show when={transaction.tokenId == JAM_TOKEN_ID}>
                                <span class="usdAmount">$ {Utils.jamToUSDAmount(transaction.amount, props.jamRate().jam_price).substring(0, 10)}</span>
                                </Show>
                            </div>
                        }>
                            {/* Info UI for Listen/Video Play TX */}
                            
                            <div
                            class="amountWrapper"
                            classList={{ 
                                'text-white': isFreeTransaction || (!isCreditTransaction && !isDebitTransaction),
                                'text-green-400': isCreditTransaction,
                                'text-red-400': isDebitTransaction,
                            }}>
                                <span class="tokenAmount">
                                    {
                                    Boolean(transaction.listen_or_video_play.video) || transaction.listen_or_video_play.first_stream_options != 'free'
                                    ? `${transaction.tokenId == JAM_TOKEN_ID ? "Ɉ": transaction.symbol} ${Utils.formatJamAmount(transaction.amount).substring(0, 10)}`
                                    : 'Free Listen'
                                    }
                                </span>
                                <Show when={transaction.tokenId == JAM_TOKEN_ID}>
                                <span class="usdAmount">
                                    {
                                    Boolean(transaction.listen_or_video_play.video) || transaction.listen_or_video_play.first_stream_options != 'free'
                                    ? `$${Utils.jamToUSDAmount(transaction.amount, props.jamRate().jam_price).substring(0, 10)}`
                                    : 'N/A'}
                                </span>
                                </Show>
                            </div>
                        </Show>
                        </Show>
                    </div>
                    
                    <div class="transactionTimeDiv">
                        {/* Transaction Time */}
                        <span 
                        class="transactionTime"
                        title={transactionTimeDescription()}
                        onClick={() => Utils.showToast(transactionTimeDescription(), 'info', 3000)}
                        >
                            {transactionTime()}
                        </span>
                        {/* Listen record time */}
                        <Show when={showListenTime()}>
                            <div 
                            class="listenTimeDiv"
                            title={listenTimeDescription()}
                            onClick={() => Utils.showToast(listenTimeDescription(), 'info', 3000)}
                            >
                                <i class="ri-headphone-line title"></i>
                                <span class="transactionTime">
                                    {listenTime()}
                                </span>
                            </div>
                        </Show>
                    </div>

                    <i
                    classList={{ 
                        'ri-check-line text-green-400': transaction.status,
                        'ri-close-line text-red-400': !transaction.status
                    }}
                    title={transaction.status ? 'All transactions processed' :'All or some transactions yet to be processed'}
                    class="statusIcon"></i>
                </div>
            </div>
        );
      };


    return (
        <div 
        id="walletTransactionsContainer"
        class="walletTransactionsContainer"
        ref={walletTransactionsContainerRef}>
            <div class="headerSection">
                <Show when={TransactionsTableUtils.transactionTypes().length}>
                    <span class="title">{TransactionsTableUtils.transactionTypes().find(transaction => transaction.type ==  TransactionsTableUtils.selectedTransactionType()).title}</span>
                </Show>
                <div class="infoSection">
                    <div>
                        USD/ɈAM Rate:&nbsp;
                        <code class="text-[#3FE37A]">${props.jamRate().jam_price}</code>
                    </div>
                    <Show when={props.jamRate().jam_per_minute}>
                        <div>
                            ɈAM/Minute Rate:&nbsp;
                            <code class="text-[#3FE37A]">Ɉ{props.jamRate().jam_per_minute.toLocaleString()}/m</code>
                        </div>
                    </Show>

                    <a
                    class="buyJAMButton"
                    href='/my-account/buy-sell-jam'
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate('/my-account/buy-sell-jam')
                    }}
                    >
                        <IoWalletOutline/>
                        Buy/Sell
                    </a>

                    <a href="https://hashscan.io/mainnet/token/0.0.127877" target="_blank">
                        <span class="material-symbols-rounded">receipt_long</span>
                        Transaction Explorer
                    </a>
                    
                </div>
            </div>
            <Show when={!props.isWalletDropUp}>
                <div class="filterContainer dropdown dropdown-hover" id="transactionFilterDropdown">
                    <div 
                    id="transactionFilterDropdownList"
                    ref={transactionFiltersDivRef} 
                    class="dropdown-menu"
                    style={{ top: `${filtersPosition().top}px` }}>
                        <ul
                        ref={transactionFiltersListRef}
                        >
                            <For each={TransactionsTableUtils.transactionTypes()}>
                                {(transactionType, index) => (
                                    <li
                                    class="list-item"
                                    classList={{'list-item-title': transactionType.type == TransactionsTableUtils.selectedTransactionType()}}
                                    onClick={() => {
                                        selectTransactionType(transactionType)
                                    }}
                                    
                                    >
                                    {transactionType.title}
                                    </li>
                                )}
                            </For>
                        </ul>
                    </div>
                    <div
                    id="transactionFiltersButton"
                    ref={transactionFiltersButtonRef}
                    class="cursor-pointer"
                    onClick={(event) => {
                        toggleDropdown(event)
                    }}
                    >
                        <svg class="fill-current h-[1.7rem] w-[1.7rem] text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                        </svg>
                    </div>
                </div> 
            </Show>
            <div ref={transactionsListElmRef}>
                <Show when={TransactionsTableUtils.tableData()} fallback={<></>}>
                <For each={TransactionsTableUtils.tableData().sort((a, b) => b.timestamp - a.timestamp)}>
                    {(transaction, index) => (
                        transactionItem(transaction, index)
                    )}
                </For>
                </Show>
            </div>

            <Show when={!TransactionsTableUtils.loadingInfiniteScroll() && TransactionsTableUtils.showLoadMore() && props.isWalletDropUp} fallback={<></>}>
                <div class="loadMore">
                    <span class="cursor-pointer" onClick={() => loadMore()}>Load More</span>
                </div>
            </Show>

            <Show when={TransactionsTableUtils.loadingInfiniteScroll()} fallback={<></>}>
                <div class="loadMore">
                    <LoadingPageIndicator centered={false} />
                </div>
            </Show>
            <Show 
            when={
                !TransactionsTableUtils.loadingInfiniteScroll() 
                && (!TransactionsTableUtils.tableData() 
                || TransactionsTableUtils.tableData().length == 0)
            } fallback={<></>}>
                <div class="mt-12 flex justify-center w-full h-full">
                <span class="text-white text-xl">
                    No transactions found
                </span>
                </div>
            </Show>
        </div>
    )
}