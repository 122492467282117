import { APIService } from '../../../../services/_index'
import { PlayerUtils, EntityTypes } from './_index'
import { _shuffle} from './play-functions'

export const _addPlaylistToPlaylistQueue = async ({
    playlistId, entitySongs, includesAllEntitySongs = false, addToBottom = false, runAsynchronously = false, allowSongLazyLoad = false
}) => {
    // addToBottom - default to add song,album, playlist or profiles songs to top of profile
    const {fetchedAllSongs, songs} = await PlayerUtils.getPlayablePlaylistSongs(playlistId, entitySongs, includesAllEntitySongs, runAsynchronously)
    await _addEntityToPlayQueueAndUpdateQueueOrdering({
        entityType: EntityTypes.Playlist, entityId: playlistId, entitySongs: songs, 
        includesAllEntitySongs: fetchedAllSongs, addToBottom: addToBottom, runAsynchronously: runAsynchronously, 
        isRerunOfAsynchronousCall: false, allowSongLazyLoad: allowSongLazyLoad
    })
    return Promise.resolve()
}

export const _addAlbumToPlaylistQueue = async ({
    albumId, entitySongs, includesAllEntitySongs = false, addToBottom = false, runAsynchronously = false, allowSongLazyLoad = false
}) => {
    const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableAlbumSongs(albumId, entitySongs, includesAllEntitySongs, runAsynchronously)
    await _addEntityToPlayQueueAndUpdateQueueOrdering({
        entityType: EntityTypes.Album, entityId: albumId, entitySongs: songs, 
        includesAllEntitySongs: fetchedAllSongs, addToBottom: addToBottom, runAsynchronously: runAsynchronously, 
        isRerunOfAsynchronousCall: false, allowSongLazyLoad: allowSongLazyLoad
    })
    return Promise.resolve()
}

export const _addProfileSongsToPlaylistQueue = async ({
    profileId, entitySongs, includesAllEntitySongs = false, addToBottom = false, runAsynchronously = false, allowSongLazyLoad = false
}) => {
    const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableProfileSongs(profileId, entitySongs, includesAllEntitySongs, runAsynchronously)
    await _addEntityToPlayQueueAndUpdateQueueOrdering({
        entityType: EntityTypes.Profile, entityId: profileId, entitySongs: songs, 
        includesAllEntitySongs: fetchedAllSongs, addToBottom: addToBottom, runAsynchronously: runAsynchronously, 
        isRerunOfAsynchronousCall: false, allowSongLazyLoad: allowSongLazyLoad
    })

    return Promise.resolve()
}

export const _addSongToPlaylistQueue = async ({songId, addToBottom=false}) => {
    await APIService.postRequest({url:'/api/v1/playlist/queue/add-item-to-playlist-queue', postData:{
        id: songId,
        type: EntityTypes.Song
        }, displayErrorMessage: true
    })
    reorderOrRefreshPlayQueue({
        newlyAddedSongs: [{id: songId}],
        includesAllEntitySongs: false, 
        addToBottom: addToBottom
    })


    return Promise.resolve()
}

const _addEntityToPlayQueueAndUpdateQueueOrdering = async ({
    entityType, entityId, entitySongs, includesAllEntitySongs = false, 
    addToBottom = false, runAsynchronously = false, isRerunOfAsynchronousCall = false, allowSongLazyLoad = true
}) => {
    if(!runAsynchronously) {
        await APIService.postRequest({url:'/api/v1/playlist/queue/add-item-to-playlist-queue', postData:{
            id: entityId,
            type: entityType
        }})
    }
    await reorderOrRefreshPlayQueue({
        newlyAddedSongs: entitySongs, 
        includesAllEntitySongs: includesAllEntitySongs, 
        addToBottom: addToBottom, 
        runAsynchronously: runAsynchronously
    })

   if(runAsynchronously) {
    // if runAsynchronously=true, ensure actual API calls still happen 
    // after running the asynchronous operations above
    _addEntityToPlayQueueAndUpdateQueueOrdering({
        entityType: entityType, entityId: entityId, entitySongs: entitySongs, 
        includesAllEntitySongs: includesAllEntitySongs, addToBottom: addToBottom, runAsynchronously: false, 
        isRerunOfAsynchronousCall: true, allowSongLazyLoad: allowSongLazyLoad
    })
   }

   // Lazy load additional songs that were not part of entitySongs list for previous runAsynchronously=true call
   if(!includesAllEntitySongs && !runAsynchronously && isRerunOfAsynchronousCall && allowSongLazyLoad) {
    _lazyLoadAdditionalEntitySongsFromLatestPlayQueue()
   }
}

export const reorderOrRefreshPlayQueue = async ({newlyAddedSongs, includesAllEntitySongs = false,  addToBottom = false, runAsynchronously = false}) => {
    if(runAsynchronously) {
        // check if any songs were in play queue before reordering, otherwise play queue does not need reordering
        if(includesAllEntitySongs && addToBottom && PlayerUtils.playQueue().length > 0) {
            if(!runAsynchronously) await PlayerUtils.getPlayQueue()
            let play_queue_songs = PlayerUtils.playQueue()
            let queue_songs = []
            let queue_song_ids = []
            let new_songs = []
            let new_song_ids = []

            // push all play queue song ids
            for (let i = 0; i < play_queue_songs.length; i++) {
                const song = play_queue_songs[i]
                queue_song_ids.push(song.id)
                queue_songs.push(song)
            }
    
            // get newly added songs from last to first (for asynchronus processing only)
            for(let i = newlyAddedSongs.length - 1; i >= 0; i--) {
                let song = newlyAddedSongs[i]
                const songId = song.id
                new_song_ids.push(songId)
                new_songs.push(song)
            }
            // filter out new_song_ids from queue_song_ids
            queue_song_ids = queue_song_ids.filter(item => !new_song_ids.includes(item))
            // do the same for song lists (filter out new_songs from queue_songs)
            queue_songs = queue_songs.filter(item => !new_song_ids.includes(item.id))

            // append new song ids
            const combined_songs = queue_songs.concat(new_songs)

            if(!runAsynchronously) {
                await PlayerUtils.getPlayQueue()
            } else {
                PlayerUtils.setPlayQueue(combined_songs)
            }
        }
        // else refresh play queue or set newlyAddedSongs as play queue
        else {
            PlayerUtils.setPlayQueue(newlyAddedSongs.toReversed()) // reverse() ensures order matches that of play queue from API. Last for first
        }
    }
}

const _lazyLoadAdditionalEntitySongsFromLatestPlayQueue = async() => {
    // This function should only be called inside _addEntityToPlayQueueAndUpdateQueueOrdering function
    // and should only be called when the said function was invoked with a isRerunOfAsynchronousCall=true argument

    // The code loads more songs for entities where only a partial portion of their songs were 
    // passed to the PLAYER when playItem funtion in player-actions.ts was called

     // All the entity's songs have been added to the PLAY queue on the API backend on reorderOrRefreshPlayQueue function
    // HENCE use latest play queue from API and set it as active PLAYER queue
    const playQueueSongs = await PlayerUtils.getPlayQueue()
    const playQueueSongsReversed = playQueueSongs.toReversed() // read from last to first

    if(playQueueSongsReversed.length > 0) {
        _updatePlayerQueue({unshuffledSongs: playQueueSongsReversed, removeCurrentSongFromPlayQueue: true})
    }
}


export const lazyLoadAdditionalEntitySongs = async({entityType, entityId}) => {
    // The code loads more songs for entities where only a partial portion of their songs were 
    // passed to the PLAYER when playSongOnList funtion in player-actions.ts was called

    // Get all the entity's songs
    let allEntitySongs = []
    if(entityType === EntityTypes.Album) {
        const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableAlbumSongs(entityId, [], false, false)
        allEntitySongs = songs
    } else if(entityType === EntityTypes.Profile) {
        const {fetchedAllSongs, songs}  = await PlayerUtils.getPlayableProfileSongs(entityId, [], false, false)
        allEntitySongs = songs
    } else if(entityType === EntityTypes.Playlist) {
        const {fetchedAllSongs, songs} = await PlayerUtils.getPlayablePlaylistSongs(entityId, [], false, false)
        allEntitySongs = songs
    }

    if(allEntitySongs.length > 0) {
        _updatePlayerQueue({unshuffledSongs: allEntitySongs})
    }
}


const _updatePlayerQueue = ({unshuffledSongs, removeCurrentSongFromPlayQueue = false}) => {
    // If shuffle is on, ensure playQueueSongs are shuffled so shuffle state will not be 
    // lost when we setActivePlayerQueue
    let newPlayerQueueSongsShuffledOrUnshuffled = PlayerUtils.shuffleOn() ? _shuffle(unshuffledSongs) : unshuffledSongs

    // get id current playing song if any
    const currentPlayingSongIndex = PlayerUtils.activePlayerQueueIndex()
    let currentPlayingSongId
    if(currentPlayingSongIndex 
        && currentPlayingSongIndex >= 0
        && currentPlayingSongIndex < PlayerUtils.activePlayerQueue().length) {
        currentPlayingSongId = PlayerUtils.activePlayerQueue()[currentPlayingSongIndex].id
    }

    // Find song index with currentPlayingSongId in newPlayerQueueSongsShuffledOrUnshuffled list
    let newActivePlayerQueueIndex
    if(currentPlayingSongId) {
        // If shuffle on, move currentPlayingSong to first on list, hence index will be 0
        if(PlayerUtils.shuffleOn()) {
            let currentPlayingSong
            let newSongList = []
            for(let i = 0; i < newPlayerQueueSongsShuffledOrUnshuffled.length; i++) {
                let song = newPlayerQueueSongsShuffledOrUnshuffled[i]
                const songId = song.id
                if(songId == currentPlayingSongId) {
                    currentPlayingSong = song
                } else {
                    newSongList.push(song)
                }
            }
            if(currentPlayingSong) {
                // add currentPlayingSong as first on list
                newSongList.unshift(currentPlayingSong)
                newActivePlayerQueueIndex = 0
            }
            newPlayerQueueSongsShuffledOrUnshuffled = newSongList
        } else {
            // Else find currentPlayingSong index on the list as is
            for(let i = 0; i < newPlayerQueueSongsShuffledOrUnshuffled.length; i++) {
                let song = newPlayerQueueSongsShuffledOrUnshuffled[i]
                const songId = song.id
                if(songId == currentPlayingSongId) {
                    newActivePlayerQueueIndex = i
                    break
                }
            }
        }
    }

    // set new player queue
    PlayerUtils.setUnshuffledPlayerQueue(unshuffledSongs)
    PlayerUtils.setActivePlayerQueue(newPlayerQueueSongsShuffledOrUnshuffled)

    // Update player queue index for current playing song to its position in newPlayerQueueSongsShuffledOrUnshuffled
    // and since song is already playing, remove it from play queue
    if(newActivePlayerQueueIndex && newActivePlayerQueueIndex >= 0) {
        PlayerUtils.setActivePlayerQueueIndex(newActivePlayerQueueIndex)
        if(removeCurrentSongFromPlayQueue) {
            PlayerUtils._removeCurrentSongFromPlayQueue()
        }
    }
}