import { IconTemplate } from "../lib/index.js";
  export function FiActivity(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 12 18 12 15 21 9 3 6 12 2 12"/>'
      }, props)
  }
  export function FiAirplay(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"/><path d="M12 15 17 21 7 21 12 15z"/>'
      }, props)
  }
  export function FiAlertCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 8 12 12"/><path d="M12 16 12.01 16"/>'
      }, props)
  }
  export function FiAlertOctagon(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2z"/><path d="M12 8 12 12"/><path d="M12 16 12.01 16"/>'
      }, props)
  }
  export function FiAlertTriangle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><path d="M12 9 12 13"/><path d="M12 17 12.01 17"/>'
      }, props)
  }
  export function FiAlignCenter(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 10 6 10"/><path d="M21 6 3 6"/><path d="M21 14 3 14"/><path d="M18 18 6 18"/>'
      }, props)
  }
  export function FiAlignJustify(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 10 3 10"/><path d="M21 6 3 6"/><path d="M21 14 3 14"/><path d="M21 18 3 18"/>'
      }, props)
  }
  export function FiAlignLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 10 3 10"/><path d="M21 6 3 6"/><path d="M21 14 3 14"/><path d="M17 18 3 18"/>'
      }, props)
  }
  export function FiAlignRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 10 7 10"/><path d="M21 6 3 6"/><path d="M21 14 3 14"/><path d="M21 18 7 18"/>'
      }, props)
  }
  export function FiAnchor(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A3 3 0 1 0 12 8 3 3 0 1 0 12 2z"/><path d="M12 22 12 8"/><path d="M5 12H2a10 10 0 0 0 20 0h-3"/>'
      }, props)
  }
  export function FiAperture(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M14.31 8 20.05 17.94"/><path d="M9.69 8 21.17 8"/><path d="M7.38 12 13.12 2.06"/><path d="M9.69 16 3.95 6.06"/><path d="M14.31 16 2.83 16"/><path d="M16.62 12 10.88 21.94"/>'
      }, props)
  }
  export function FiArchive(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 8 21 21 3 21 3 8"/><path d="M1 3H23V8H1z"/><path d="M10 12 14 12"/>'
      }, props)
  }
  export function FiArrowDownCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M8 12 12 16 16 12"/><path d="M12 8 12 16"/>'
      }, props)
  }
  export function FiArrowDownLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 7 7 17"/><path d="M17 17 7 17 7 7"/>'
      }, props)
  }
  export function FiArrowDownRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7 7 17 17"/><path d="M17 7 17 17 7 17"/>'
      }, props)
  }
  export function FiArrowDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 5 12 19"/><path d="M19 12 12 19 5 12"/>'
      }, props)
  }
  export function FiArrowLeftCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 8 8 12 12 16"/><path d="M16 12 8 12"/>'
      }, props)
  }
  export function FiArrowLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 12 5 12"/><path d="M12 19 5 12 12 5"/>'
      }, props)
  }
  export function FiArrowRightCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 16 16 12 12 8"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiArrowRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 12 19 12"/><path d="M12 5 19 12 12 19"/>'
      }, props)
  }
  export function FiArrowUpCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M16 12 12 8 8 12"/><path d="M12 16 12 8"/>'
      }, props)
  }
  export function FiArrowUpLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 17 7 7"/><path d="M7 17 7 7 17 7"/>'
      }, props)
  }
  export function FiArrowUpRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7 17 17 7"/><path d="M7 7 17 7 17 17"/>'
      }, props)
  }
  export function FiArrowUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 19 12 5"/><path d="M5 12 12 5 19 12"/>'
      }, props)
  }
  export function FiAtSign(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 8A4 4 0 1 0 12 16 4 4 0 1 0 12 8z"/><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"/>'
      }, props)
  }
  export function FiAward(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 1A7 7 0 1 0 12 15 7 7 0 1 0 12 1z"/><path d="M8.21 13.89 7 23 12 20 17 23 15.79 13.88"/>'
      }, props)
  }
  export function FiBarChart2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 20 18 10"/><path d="M12 20 12 4"/><path d="M6 20 6 14"/>'
      }, props)
  }
  export function FiBarChart(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 20 12 10"/><path d="M18 20 18 4"/><path d="M6 20 6 16"/>'
      }, props)
  }
  export function FiBatteryCharging(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19"/><path d="M23 13 23 11"/><path d="M11 6 7 12 13 12 9 18"/>'
      }, props)
  }
  export function FiBattery(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="12" x="1" y="6" rx="2" ry="2"/><path d="M23 13 23 11"/>'
      }, props)
  }
  export function FiBellOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M13.73 21a2 2 0 0 1-3.46 0M18.63 13A17.89 17.89 0 0 1 18 8M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14M18 8a6 6 0 0 0-9.33-5"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiBell(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 0 1-3.46 0"/>'
      }, props)
  }
  export function FiBluetooth(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6.5 6.5 17.5 17.5 12 23 12 1 17.5 6.5 6.5 17.5"/>'
      }, props)
  }
  export function FiBold(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6zM6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"/>'
      }, props)
  }
  export function FiBookOpen(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/>'
      }, props)
  }
  export function FiBook(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"/><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"/>'
      }, props)
  }
  export function FiBookmark(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"/>'
      }, props)
  }
  export function FiBox(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/><path d="M3.27 6.96 12 12.01 20.73 6.96"/><path d="M12 22.08 12 12"/>'
      }, props)
  }
  export function FiBriefcase(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="14" x="2" y="7" rx="2" ry="2"/><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"/>'
      }, props)
  }
  export function FiCalendar(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="4" rx="2" ry="2"/><path d="M16 2 16 6"/><path d="M8 2 8 6"/><path d="M3 10 21 10"/>'
      }, props)
  }
  export function FiCameraOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 1 23 23"/><path d="M21 21H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3m3-3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56"/>'
      }, props)
  }
  export function FiCamera(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/><path d="M12 9A4 4 0 1 0 12 17 4 4 0 1 0 12 9z"/>'
      }, props)
  }
  export function FiCast(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6"/><path d="M2 20 2.01 20"/>'
      }, props)
  }
  export function FiCheckCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><path d="M22 4 12 14.01 9 11.01"/>'
      }, props)
  }
  export function FiCheckSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 11 12 14 22 4"/><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"/>'
      }, props)
  }
  export function FiCheck(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20 6 9 17 4 12"/>'
      }, props)
  }
  export function FiChevronDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 9 12 15 18 9"/>'
      }, props)
  }
  export function FiChevronLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 18 9 12 15 6"/>'
      }, props)
  }
  export function FiChevronRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 18 15 12 9 6"/>'
      }, props)
  }
  export function FiChevronUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 15 12 9 6 15"/>'
      }, props)
  }
  export function FiChevronsDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7 13 12 18 17 13"/><path d="M7 6 12 11 17 6"/>'
      }, props)
  }
  export function FiChevronsLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 17 6 12 11 7"/><path d="M18 17 13 12 18 7"/>'
      }, props)
  }
  export function FiChevronsRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M13 17 18 12 13 7"/><path d="M6 17 11 12 6 7"/>'
      }, props)
  }
  export function FiChevronsUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 11 12 6 7 11"/><path d="M17 18 12 13 7 18"/>'
      }, props)
  }
  export function FiChrome(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 8A4 4 0 1 0 12 16 4 4 0 1 0 12 8z"/><path d="M21.17 8 12 8"/><path d="M3.95 6.06 8.54 14"/><path d="M10.88 21.94 15.46 14"/>'
      }, props)
  }
  export function FiCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/>'
      }, props)
  }
  export function FiClipboard(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/><rect width="8" height="4" x="8" y="2" rx="1" ry="1"/>'
      }, props)
  }
  export function FiClock(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 6 12 12 16 14"/>'
      }, props)
  }
  export function FiCloudDrizzle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 19 8 21"/><path d="M8 13 8 15"/><path d="M16 19 16 21"/><path d="M16 13 16 15"/><path d="M12 21 12 23"/><path d="M12 15 12 17"/><path d="M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25"/>'
      }, props)
  }
  export function FiCloudLightning(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 16.9A5 5 0 0 0 18 7h-1.26a8 8 0 1 0-11.62 9"/><path d="M13 11 9 17 15 17 11 23"/>'
      }, props)
  }
  export function FiCloudOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22.61 16.95A5 5 0 0 0 18 10h-1.26a8 8 0 0 0-7.05-6M5 5a8 8 0 0 0 4 15h9a5 5 0 0 0 1.7-.3"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiCloudRain(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 13 16 21"/><path d="M8 13 8 21"/><path d="M12 15 12 23"/><path d="M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25"/>'
      }, props)
  }
  export function FiCloudSnow(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20 17.58A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.25"/><path d="M8 16 8.01 16"/><path d="M8 20 8.01 20"/><path d="M12 18 12.01 18"/><path d="M12 22 12.01 22"/><path d="M16 16 16.01 16"/><path d="M16 20 16.01 20"/>'
      }, props)
  }
  export function FiCloud(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"/>'
      }, props)
  }
  export function FiCode(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 18 22 12 16 6"/><path d="M8 6 2 12 8 18"/>'
      }, props)
  }
  export function FiCodepen(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2z"/><path d="M12 22 12 15.5"/><path d="M22 8.5 12 15.5 2 8.5"/><path d="M2 15.5 12 8.5 22 15.5"/><path d="M12 2 12 8.5"/>'
      }, props)
  }
  export function FiCodesandbox(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/><path d="M7.5 4.21 12 6.81 16.5 4.21"/><path d="M7.5 19.79 7.5 14.6 3 12"/><path d="M21 12 16.5 14.6 16.5 19.79"/><path d="M3.27 6.96 12 12.01 20.73 6.96"/><path d="M12 22.08 12 12"/>'
      }, props)
  }
  export function FiCoffee(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 8h1a4 4 0 0 1 0 8h-1M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"/><path d="M6 1 6 4"/><path d="M10 1 10 4"/><path d="M14 1 14 4"/>'
      }, props)
  }
  export function FiColumns(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"/>'
      }, props)
  }
  export function FiCommand(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"/>'
      }, props)
  }
  export function FiCompass(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76z"/>'
      }, props)
  }
  export function FiCopy(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="13" height="13" x="9" y="9" rx="2" ry="2"/><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>'
      }, props)
  }
  export function FiCornerDownLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 10 4 15 9 20"/><path d="M20 4v7a4 4 0 0 1-4 4H4"/>'
      }, props)
  }
  export function FiCornerDownRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 10 20 15 15 20"/><path d="M4 4v7a4 4 0 0 0 4 4h12"/>'
      }, props)
  }
  export function FiCornerLeftDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 15 9 20 4 15"/><path d="M20 4h-7a4 4 0 0 0-4 4v12"/>'
      }, props)
  }
  export function FiCornerLeftUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 9 9 4 4 9"/><path d="M20 20h-7a4 4 0 0 1-4-4V4"/>'
      }, props)
  }
  export function FiCornerRightDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10 15 15 20 20 15"/><path d="M4 4h7a4 4 0 0 1 4 4v12"/>'
      }, props)
  }
  export function FiCornerRightUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10 9 15 4 20 9"/><path d="M4 20h7a4 4 0 0 0 4-4V4"/>'
      }, props)
  }
  export function FiCornerUpLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 14 4 9 9 4"/><path d="M20 20v-7a4 4 0 0 0-4-4H4"/>'
      }, props)
  }
  export function FiCornerUpRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 14 20 9 15 4"/><path d="M4 20v-7a4 4 0 0 1 4-4h12"/>'
      }, props)
  }
  export function FiCpu(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="16" height="16" x="4" y="4" rx="2" ry="2"/><path d="M9 9H15V15H9z"/><path d="M9 1 9 4"/><path d="M15 1 15 4"/><path d="M9 20 9 23"/><path d="M15 20 15 23"/><path d="M20 9 23 9"/><path d="M20 14 23 14"/><path d="M1 9 4 9"/><path d="M1 14 4 14"/>'
      }, props)
  }
  export function FiCreditCard(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="22" height="16" x="1" y="4" rx="2" ry="2"/><path d="M1 10 23 10"/>'
      }, props)
  }
  export function FiCrop(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6.13 1 6 16a2 2 0 0 0 2 2h15"/><path d="M1 6.13 16 6a2 2 0 0 1 2 2v15"/>'
      }, props)
  }
  export function FiCrosshair(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M22 12 18 12"/><path d="M6 12 2 12"/><path d="M12 6 12 2"/><path d="M12 22 12 18"/>'
      }, props)
  }
  export function FiDatabase(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A9 3 0 1 0 12 8 9 3 0 1 0 12 2z"/><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"/><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"/>'
      }, props)
  }
  export function FiDelete(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"/><path d="M18 9 12 15"/><path d="M12 9 18 15"/>'
      }, props)
  }
  export function FiDisc(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 9A3 3 0 1 0 12 15 3 3 0 1 0 12 9z"/>'
      }, props)
  }
  export function FiDivideCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 12 16 12"/><path d="M12 16 12 16"/><path d="M12 8 12 8"/><path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/>'
      }, props)
  }
  export function FiDivideSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M8 12 16 12"/><path d="M12 16 12 16"/><path d="M12 8 12 8"/>'
      }, props)
  }
  export function FiDivide(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 4A2 2 0 1 0 12 8 2 2 0 1 0 12 4z"/><path d="M5 12 19 12"/><path d="M12 16A2 2 0 1 0 12 20 2 2 0 1 0 12 16z"/>'
      }, props)
  }
  export function FiDollarSign(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 1 12 23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/>'
      }, props)
  }
  export function FiDownloadCloud(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 17 12 21 16 17"/><path d="M12 12 12 21"/><path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"/>'
      }, props)
  }
  export function FiDownload(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><path d="M7 10 12 15 17 10"/><path d="M12 15 12 3"/>'
      }, props)
  }
  export function FiDribbble(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32"/>'
      }, props)
  }
  export function FiDroplet(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m12 2.69 5.66 5.66a8 8 0 1 1-11.31 0z"/>'
      }, props)
  }
  export function FiEdit2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>'
      }, props)
  }
  export function FiEdit3(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 20h9M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"/>'
      }, props)
  }
  export function FiEdit(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>'
      }, props)
  }
  export function FiExternalLink(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/><path d="M15 3 21 3 21 9"/><path d="M10 14 21 3"/>'
      }, props)
  }
  export function FiEyeOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiEye(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><path d="M12 9A3 3 0 1 0 12 15 3 3 0 1 0 12 9z"/>'
      }, props)
  }
  export function FiFacebook(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/>'
      }, props)
  }
  export function FiFastForward(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M13 19 22 12 13 5 13 19z"/><path d="M2 19 11 12 2 5 2 19z"/>'
      }, props)
  }
  export function FiFeather(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"/><path d="M16 8 2 22"/><path d="M17.5 15 9 15"/>'
      }, props)
  }
  export function FiFigma(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5zM12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"/><path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0zM5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0zM5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"/>'
      }, props)
  }
  export function FiFileMinus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><path d="M14 2 14 8 20 8"/><path d="M9 15 15 15"/>'
      }, props)
  }
  export function FiFilePlus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><path d="M14 2 14 8 20 8"/><path d="M12 18 12 12"/><path d="M9 15 15 15"/>'
      }, props)
  }
  export function FiFileText(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><path d="M14 2 14 8 20 8"/><path d="M16 13 8 13"/><path d="M16 17 8 17"/><path d="M10 9 9 9 8 9"/>'
      }, props)
  }
  export function FiFile(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"/><path d="M13 2 13 9 20 9"/>'
      }, props)
  }
  export function FiFilm(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="20" x="2" y="2" rx="2.18" ry="2.18"/><path d="M7 2 7 22"/><path d="M17 2 17 22"/><path d="M2 12 22 12"/><path d="M2 7 7 7"/><path d="M2 17 7 17"/><path d="M17 17 22 17"/><path d="M17 7 22 7"/>'
      }, props)
  }
  export function FiFilter(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3z"/>'
      }, props)
  }
  export function FiFlag(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"/><path d="M4 22 4 15"/>'
      }, props)
  }
  export function FiFolderMinus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/><path d="M9 14 15 14"/>'
      }, props)
  }
  export function FiFolderPlus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/><path d="M12 11 12 17"/><path d="M9 14 15 14"/>'
      }, props)
  }
  export function FiFolder(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"/>'
      }, props)
  }
  export function FiFramer(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 16V9h14V2H5l14 14h-7m-7 0 7 7v-7m-7 0h7"/>'
      }, props)
  }
  export function FiFrown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M16 16s-1.5-2-4-2-4 2-4 2"/><path d="M9 9 9.01 9"/><path d="M15 9 15.01 9"/>'
      }, props)
  }
  export function FiGift(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20 12 20 22 4 22 4 12"/><path d="M2 7H22V12H2z"/><path d="M12 22 12 7"/><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7zM12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"/>'
      }, props)
  }
  export function FiGitBranch(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 3 6 15"/><path d="M18 3A3 3 0 1 0 18 9 3 3 0 1 0 18 3z"/><path d="M6 15A3 3 0 1 0 6 21 3 3 0 1 0 6 15z"/><path d="M18 9a9 9 0 0 1-9 9"/>'
      }, props)
  }
  export function FiGitCommit(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 8A4 4 0 1 0 12 16 4 4 0 1 0 12 8z"/><path d="M1.05 12 7 12"/><path d="M17.01 12 22.96 12"/>'
      }, props)
  }
  export function FiGitMerge(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 15A3 3 0 1 0 18 21 3 3 0 1 0 18 15z"/><path d="M6 3A3 3 0 1 0 6 9 3 3 0 1 0 6 3z"/><path d="M6 21V9a9 9 0 0 0 9 9"/>'
      }, props)
  }
  export function FiGitPullRequest(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 15A3 3 0 1 0 18 21 3 3 0 1 0 18 15z"/><path d="M6 3A3 3 0 1 0 6 9 3 3 0 1 0 6 3z"/><path d="M13 6h3a2 2 0 0 1 2 2v7"/><path d="M6 9 6 21"/>'
      }, props)
  }
  export function FiGithub(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"/>'
      }, props)
  }
  export function FiGitlab(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22.65 14.39 12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"/>'
      }, props)
  }
  export function FiGlobe(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M2 12 22 12"/><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"/>'
      }, props)
  }
  export function FiGrid(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 3H10V10H3z"/><path d="M14 3H21V10H14z"/><path d="M14 14H21V21H14z"/><path d="M3 14H10V21H3z"/>'
      }, props)
  }
  export function FiHardDrive(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 12 2 12"/><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/><path d="M6 16 6.01 16"/><path d="M10 16 10.01 16"/>'
      }, props)
  }
  export function FiHash(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 9 20 9"/><path d="M4 15 20 15"/><path d="M10 3 8 21"/><path d="M16 3 14 21"/>'
      }, props)
  }
  export function FiHeadphones(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 18v-6a9 9 0 0 1 18 0v6"/><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"/>'
      }, props)
  }
  export function FiHeart(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"/>'
      }, props)
  }
  export function FiHelpCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17 12.01 17"/>'
      }, props)
  }
  export function FiHexagon(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>'
      }, props)
  }
  export function FiHome(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><path d="M9 22 9 12 15 12 15 22"/>'
      }, props)
  }
  export function FiImage(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M8.5 7A1.5 1.5 0 1 0 8.5 10 1.5 1.5 0 1 0 8.5 7z"/><path d="M21 15 16 10 5 21"/>'
      }, props)
  }
  export function FiInbox(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 12 16 12 14 15 10 15 8 12 2 12"/><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/>'
      }, props)
  }
  export function FiInfo(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 16 12 12"/><path d="M12 8 12.01 8"/>'
      }, props)
  }
  export function FiInstagram(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><path d="M17.5 6.5 17.51 6.5"/>'
      }, props)
  }
  export function FiItalic(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 4 10 4"/><path d="M14 20 5 20"/><path d="M15 4 9 20"/>'
      }, props)
  }
  export function FiKey(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m21 2-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0 3 3L22 7l-3-3m-3.5 3.5L19 4"/>'
      }, props)
  }
  export function FiLayers(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2 2 7 12 12 22 7 12 2z"/><path d="M2 17 12 22 22 17"/><path d="M2 12 12 17 22 12"/>'
      }, props)
  }
  export function FiLayout(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M3 9 21 9"/><path d="M9 21 9 9"/>'
      }, props)
  }
  export function FiLifeBuoy(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 8A4 4 0 1 0 12 16 4 4 0 1 0 12 8z"/><path d="M4.93 4.93 9.17 9.17"/><path d="M14.83 14.83 19.07 19.07"/><path d="M14.83 9.17 19.07 4.93"/><path d="M14.83 9.17 18.36 5.64"/><path d="M4.93 19.07 9.17 14.83"/>'
      }, props)
  }
  export function FiLink2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiLink(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/>'
      }, props)
  }
  export function FiLinkedin(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><path d="M2 9H6V21H2z"/><path d="M4 2A2 2 0 1 0 4 6 2 2 0 1 0 4 2z"/>'
      }, props)
  }
  export function FiList(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 6 21 6"/><path d="M8 12 21 12"/><path d="M8 18 21 18"/><path d="M3 6 3.01 6"/><path d="M3 12 3.01 12"/><path d="M3 18 3.01 18"/>'
      }, props)
  }
  export function FiLoader(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2 12 6"/><path d="M12 18 12 22"/><path d="M4.93 4.93 7.76 7.76"/><path d="M16.24 16.24 19.07 19.07"/><path d="M2 12 6 12"/><path d="M18 12 22 12"/><path d="M4.93 19.07 7.76 16.24"/><path d="M16.24 7.76 19.07 4.93"/>'
      }, props)
  }
  export function FiLock(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 10 0v4"/>'
      }, props)
  }
  export function FiLogIn(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"/><path d="M10 17 15 12 10 7"/><path d="M15 12 3 12"/>'
      }, props)
  }
  export function FiLogOut(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/><path d="M16 17 21 12 16 7"/><path d="M21 12 9 12"/>'
      }, props)
  }
  export function FiMail(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><path d="M22 6 12 13 2 6"/>'
      }, props)
  }
  export function FiMapPin(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><path d="M12 7A3 3 0 1 0 12 13 3 3 0 1 0 12 7z"/>'
      }, props)
  }
  export function FiMap(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6z"/><path d="M8 2 8 18"/><path d="M16 6 16 22"/>'
      }, props)
  }
  export function FiMaximize2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15 3 21 3 21 9"/><path d="M9 21 3 21 3 15"/><path d="M21 3 14 10"/><path d="M3 21 10 14"/>'
      }, props)
  }
  export function FiMaximize(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"/>'
      }, props)
  }
  export function FiMeh(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M8 15 16 15"/><path d="M9 9 9.01 9"/><path d="M15 9 15.01 9"/>'
      }, props)
  }
  export function FiMenu(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 12 21 12"/><path d="M3 6 21 6"/><path d="M3 18 21 18"/>'
      }, props)
  }
  export function FiMessageCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"/>'
      }, props)
  }
  export function FiMessageSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>'
      }, props)
  }
  export function FiMicOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 1 23 23"/><path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"/><path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"/><path d="M12 19 12 23"/><path d="M8 23 16 23"/>'
      }, props)
  }
  export function FiMic(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"/><path d="M19 10v2a7 7 0 0 1-14 0v-2"/><path d="M12 19 12 23"/><path d="M8 23 16 23"/>'
      }, props)
  }
  export function FiMinimize2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 14 10 14 10 20"/><path d="M20 10 14 10 14 4"/><path d="M14 10 21 3"/><path d="M3 21 10 14"/>'
      }, props)
  }
  export function FiMinimize(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"/>'
      }, props)
  }
  export function FiMinusCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiMinusSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiMinus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 12 19 12"/>'
      }, props)
  }
  export function FiMonitor(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="14" x="2" y="3" rx="2" ry="2"/><path d="M8 21 16 21"/><path d="M12 17 12 21"/>'
      }, props)
  }
  export function FiMoon(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"/>'
      }, props)
  }
  export function FiMoreHorizontal(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 11A1 1 0 1 0 12 13 1 1 0 1 0 12 11z"/><path d="M19 11A1 1 0 1 0 19 13 1 1 0 1 0 19 11z"/><path d="M5 11A1 1 0 1 0 5 13 1 1 0 1 0 5 11z"/>'
      }, props)
  }
  export function FiMoreVertical(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 11A1 1 0 1 0 12 13 1 1 0 1 0 12 11z"/><path d="M12 4A1 1 0 1 0 12 6 1 1 0 1 0 12 4z"/><path d="M12 18A1 1 0 1 0 12 20 1 1 0 1 0 12 18z"/>'
      }, props)
  }
  export function FiMousePointer(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m3 3 7.07 16.97 2.51-7.39 7.39-2.51L3 3zM13 13l6 6"/>'
      }, props)
  }
  export function FiMove(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 9 2 12 5 15"/><path d="M9 5 12 2 15 5"/><path d="M15 19 12 22 9 19"/><path d="M19 9 22 12 19 15"/><path d="M2 12 22 12"/><path d="M12 2 12 22"/>'
      }, props)
  }
  export function FiMusic(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 18V5l12-2v13"/><path d="M6 15A3 3 0 1 0 6 21 3 3 0 1 0 6 15z"/><path d="M18 13A3 3 0 1 0 18 19 3 3 0 1 0 18 13z"/>'
      }, props)
  }
  export function FiNavigation2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2 19 21 12 17 5 21 12 2z"/>'
      }, props)
  }
  export function FiNavigation(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 11 22 2 13 21 11 13 3 11z"/>'
      }, props)
  }
  export function FiOctagon(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2z"/>'
      }, props)
  }
  export function FiPackage(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16.5 9.4 7.5 4.21"/><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/><path d="M3.27 6.96 12 12.01 20.73 6.96"/><path d="M12 22.08 12 12"/>'
      }, props)
  }
  export function FiPaperclip(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"/>'
      }, props)
  }
  export function FiPauseCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M10 15 10 9"/><path d="M14 15 14 9"/>'
      }, props)
  }
  export function FiPause(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 4H10V20H6z"/><path d="M14 4H18V20H14z"/>'
      }, props)
  }
  export function FiPenTool(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m12 19 7-7 3 3-7 7-3-3z"/><path d="m18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5zM2 2l7.586 7.586"/><path d="M11 9A2 2 0 1 0 11 13 2 2 0 1 0 11 9z"/>'
      }, props)
  }
  export function FiPercent(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 5 5 19"/><path d="M6.5 4A2.5 2.5 0 1 0 6.5 9 2.5 2.5 0 1 0 6.5 4z"/><path d="M17.5 15A2.5 2.5 0 1 0 17.5 20 2.5 2.5 0 1 0 17.5 15z"/>'
      }, props)
  }
  export function FiPhoneCall(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPhoneForwarded(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 1 23 5 19 9"/><path d="M15 5 23 5"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPhoneIncoming(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 2 16 8 22 8"/><path d="M23 1 16 8"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPhoneMissed(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 1 17 7"/><path d="M17 1 23 7"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPhoneOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"/><path d="M23 1 1 23"/>'
      }, props)
  }
  export function FiPhoneOutgoing(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 7 23 1 17 1"/><path d="M16 8 23 1"/><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPhone(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>'
      }, props)
  }
  export function FiPieChart(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21.21 15.89A10 10 0 1 1 8 2.83"/><path d="M22 12A10 10 0 0 0 12 2v10z"/>'
      }, props)
  }
  export function FiPlayCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M10 8 16 12 10 16 10 8z"/>'
      }, props)
  }
  export function FiPlay(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 3 19 12 5 21 5 3z"/>'
      }, props)
  }
  export function FiPlusCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 8 12 16"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiPlusSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M12 8 12 16"/><path d="M8 12 16 12"/>'
      }, props)
  }
  export function FiPlus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 5 12 19"/><path d="M5 12 19 12"/>'
      }, props)
  }
  export function FiPocket(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"/><path d="M8 10 12 14 16 10"/>'
      }, props)
  }
  export function FiPower(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18.36 6.64a9 9 0 1 1-12.73 0"/><path d="M12 2 12 12"/>'
      }, props)
  }
  export function FiPrinter(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 9 6 2 18 2 18 9"/><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"/><path d="M6 14H18V22H6z"/>'
      }, props)
  }
  export function FiRadio(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 10A2 2 0 1 0 12 14 2 2 0 1 0 12 10z"/><path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"/>'
      }, props)
  }
  export function FiRefreshCcw(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 4 1 10 7 10"/><path d="M23 20 23 14 17 14"/><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4-4.64 4.36A9 9 0 0 1 3.51 15"/>'
      }, props)
  }
  export function FiRefreshCw(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 4 23 10 17 10"/><path d="M1 20 1 14 7 14"/><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"/>'
      }, props)
  }
  export function FiRepeat(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 1 21 5 17 9"/><path d="M3 11V9a4 4 0 0 1 4-4h14"/><path d="M7 23 3 19 7 15"/><path d="M21 13v2a4 4 0 0 1-4 4H3"/>'
      }, props)
  }
  export function FiRewind(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 19 2 12 11 5 11 19z"/><path d="M22 19 13 12 22 5 22 19z"/>'
      }, props)
  }
  export function FiRotateCcw(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 4 1 10 7 10"/><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"/>'
      }, props)
  }
  export function FiRotateCw(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 4 23 10 17 10"/><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"/>'
      }, props)
  }
  export function FiRss(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16"/><path d="M5 18A1 1 0 1 0 5 20 1 1 0 1 0 5 18z"/>'
      }, props)
  }
  export function FiSave(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"/><path d="M17 21 17 13 7 13 7 21"/><path d="M7 3 7 8 15 8"/>'
      }, props)
  }
  export function FiScissors(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 3A3 3 0 1 0 6 9 3 3 0 1 0 6 3z"/><path d="M6 15A3 3 0 1 0 6 21 3 3 0 1 0 6 15z"/><path d="M20 4 8.12 15.88"/><path d="M14.47 14.48 20 20"/><path d="M8.12 8.12 12 12"/>'
      }, props)
  }
  export function FiSearch(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 3A8 8 0 1 0 11 19 8 8 0 1 0 11 3z"/><path d="M21 21 16.65 16.65"/>'
      }, props)
  }
  export function FiSend(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22 2 11 13"/><path d="M22 2 15 22 11 13 2 9 22 2z"/>'
      }, props)
  }
  export function FiServer(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="8" x="2" y="2" rx="2" ry="2"/><rect width="20" height="8" x="2" y="14" rx="2" ry="2"/><path d="M6 6 6.01 6"/><path d="M6 18 6.01 18"/>'
      }, props)
  }
  export function FiSettings(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 9A3 3 0 1 0 12 15 3 3 0 1 0 12 9z"/><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>'
      }, props)
  }
  export function FiShare2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 2A3 3 0 1 0 18 8 3 3 0 1 0 18 2z"/><path d="M6 9A3 3 0 1 0 6 15 3 3 0 1 0 6 9z"/><path d="M18 16A3 3 0 1 0 18 22 3 3 0 1 0 18 16z"/><path d="M8.59 13.51 15.42 17.49"/><path d="M15.41 6.51 8.59 10.49"/>'
      }, props)
  }
  export function FiShare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/><path d="M16 6 12 2 8 6"/><path d="M12 2 12 15"/>'
      }, props)
  }
  export function FiShieldOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18M4.73 4.73 4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiShield(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"/>'
      }, props)
  }
  export function FiShoppingBag(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><path d="M3 6 21 6"/><path d="M16 10a4 4 0 0 1-8 0"/>'
      }, props)
  }
  export function FiShoppingCart(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 20A1 1 0 1 0 9 22 1 1 0 1 0 9 20z"/><path d="M20 20A1 1 0 1 0 20 22 1 1 0 1 0 20 20z"/><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"/>'
      }, props)
  }
  export function FiShuffle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 3 21 3 21 8"/><path d="M4 20 21 3"/><path d="M21 16 21 21 16 21"/><path d="M15 15 21 21"/><path d="M4 4 9 9"/>'
      }, props)
  }
  export function FiSidebar(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M9 3 9 21"/>'
      }, props)
  }
  export function FiSkipBack(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M19 20 9 12 19 4 19 20z"/><path d="M5 19 5 5"/>'
      }, props)
  }
  export function FiSkipForward(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 4 15 12 5 20 5 4z"/><path d="M19 5 19 19"/>'
      }, props)
  }
  export function FiSlack(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5zM20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5zM3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14zM14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5zM15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zM10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5zM8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z"/>'
      }, props)
  }
  export function FiSlash(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M4.93 4.93 19.07 19.07"/>'
      }, props)
  }
  export function FiSliders(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 21 4 14"/><path d="M4 10 4 3"/><path d="M12 21 12 12"/><path d="M12 8 12 3"/><path d="M20 21 20 16"/><path d="M20 12 20 3"/><path d="M1 14 7 14"/><path d="M9 8 15 8"/><path d="M17 16 23 16"/>'
      }, props)
  }
  export function FiSmartphone(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="14" height="20" x="5" y="2" rx="2" ry="2"/><path d="M12 18 12.01 18"/>'
      }, props)
  }
  export function FiSmile(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M8 14s1.5 2 4 2 4-2 4-2"/><path d="M9 9 9.01 9"/><path d="M15 9 15.01 9"/>'
      }, props)
  }
  export function FiSpeaker(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="16" height="20" x="4" y="2" rx="2" ry="2"/><path d="M12 10A4 4 0 1 0 12 18 4 4 0 1 0 12 10z"/><path d="M12 6 12.01 6"/>'
      }, props)
  }
  export function FiSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/>'
      }, props)
  }
  export function FiStar(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2z"/>'
      }, props)
  }
  export function FiStopCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M9 9H15V15H9z"/>'
      }, props)
  }
  export function FiSun(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 7A5 5 0 1 0 12 17 5 5 0 1 0 12 7z"/><path d="M12 1 12 3"/><path d="M12 21 12 23"/><path d="M4.22 4.22 5.64 5.64"/><path d="M18.36 18.36 19.78 19.78"/><path d="M1 12 3 12"/><path d="M21 12 23 12"/><path d="M4.22 19.78 5.64 18.36"/><path d="M18.36 5.64 19.78 4.22"/>'
      }, props)
  }
  export function FiSunrise(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 18a5 5 0 0 0-10 0"/><path d="M12 2 12 9"/><path d="M4.22 10.22 5.64 11.64"/><path d="M1 18 3 18"/><path d="M21 18 23 18"/><path d="M18.36 11.64 19.78 10.22"/><path d="M23 22 1 22"/><path d="M8 6 12 2 16 6"/>'
      }, props)
  }
  export function FiSunset(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 18a5 5 0 0 0-10 0"/><path d="M12 9 12 2"/><path d="M4.22 10.22 5.64 11.64"/><path d="M1 18 3 18"/><path d="M21 18 23 18"/><path d="M18.36 11.64 19.78 10.22"/><path d="M23 22 1 22"/><path d="M16 5 12 9 8 5"/>'
      }, props)
  }
  export function FiTable(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"/>'
      }, props)
  }
  export function FiTablet(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="16" height="20" x="4" y="2" rx="2" ry="2"/><path d="M12 18 12.01 18"/>'
      }, props)
  }
  export function FiTag(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="m20.59 13.41-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"/><path d="M7 7 7.01 7"/>'
      }, props)
  }
  export function FiTarget(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M12 6A6 6 0 1 0 12 18 6 6 0 1 0 12 6z"/><path d="M12 10A2 2 0 1 0 12 14 2 2 0 1 0 12 10z"/>'
      }, props)
  }
  export function FiTerminal(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 17 10 11 4 5"/><path d="M12 19 20 19"/>'
      }, props)
  }
  export function FiThermometer(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z"/>'
      }, props)
  }
  export function FiThumbsDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"/>'
      }, props)
  }
  export function FiThumbsUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"/>'
      }, props)
  }
  export function FiToggleLeft(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="22" height="14" x="1" y="5" rx="7" ry="7"/><path d="M8 9A3 3 0 1 0 8 15 3 3 0 1 0 8 9z"/>'
      }, props)
  }
  export function FiToggleRight(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="22" height="14" x="1" y="5" rx="7" ry="7"/><path d="M16 9A3 3 0 1 0 16 15 3 3 0 1 0 16 9z"/>'
      }, props)
  }
  export function FiTool(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"/>'
      }, props)
  }
  export function FiTrash2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><path d="M10 11 10 17"/><path d="M14 11 14 17"/>'
      }, props)
  }
  export function FiTrash(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>'
      }, props)
  }
  export function FiTrello(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M7 7H10V16H7z"/><path d="M14 7H17V12H14z"/>'
      }, props)
  }
  export function FiTrendingDown(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 18 13.5 8.5 8.5 13.5 1 6"/><path d="M17 18 23 18 23 12"/>'
      }, props)
  }
  export function FiTrendingUp(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 6 13.5 15.5 8.5 10.5 1 18"/><path d="M17 6 23 6 23 12"/>'
      }, props)
  }
  export function FiTriangle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/>'
      }, props)
  }
  export function FiTruck(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 3H16V16H1z"/><path d="M16 8 20 8 23 11 23 16 16 16 16 8z"/><path d="M5.5 16A2.5 2.5 0 1 0 5.5 21 2.5 2.5 0 1 0 5.5 16z"/><path d="M18.5 16A2.5 2.5 0 1 0 18.5 21 2.5 2.5 0 1 0 18.5 16z"/>'
      }, props)
  }
  export function FiTv(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="20" height="15" x="2" y="7" rx="2" ry="2"/><path d="M17 2 12 7 7 2"/>'
      }, props)
  }
  export function FiTwitch(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7"/>'
      }, props)
  }
  export function FiTwitter(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"/>'
      }, props)
  }
  export function FiType(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M4 7 4 4 20 4 20 7"/><path d="M9 20 15 20"/><path d="M12 4 12 20"/>'
      }, props)
  }
  export function FiUmbrella(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 12a11.05 11.05 0 0 0-22 0zm-5 7a3 3 0 0 1-6 0v-7"/>'
      }, props)
  }
  export function FiUnderline(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3"/><path d="M4 21 20 21"/>'
      }, props)
  }
  export function FiUnlock(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 9.9-1"/>'
      }, props)
  }
  export function FiUploadCloud(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 16 12 12 8 16"/><path d="M12 12 12 21"/><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"/><path d="M16 16 12 12 8 16"/>'
      }, props)
  }
  export function FiUpload(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><path d="M17 8 12 3 7 8"/><path d="M12 3 12 15"/>'
      }, props)
  }
  export function FiUserCheck(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><path d="M8.5 3A4 4 0 1 0 8.5 11 4 4 0 1 0 8.5 3z"/><path d="M17 11 19 13 23 9"/>'
      }, props)
  }
  export function FiUserMinus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><path d="M8.5 3A4 4 0 1 0 8.5 11 4 4 0 1 0 8.5 3z"/><path d="M23 11 17 11"/>'
      }, props)
  }
  export function FiUserPlus(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><path d="M8.5 3A4 4 0 1 0 8.5 11 4 4 0 1 0 8.5 3z"/><path d="M20 8 20 14"/><path d="M23 11 17 11"/>'
      }, props)
  }
  export function FiUserX(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><path d="M8.5 3A4 4 0 1 0 8.5 11 4 4 0 1 0 8.5 3z"/><path d="M18 8 23 13"/><path d="M23 8 18 13"/>'
      }, props)
  }
  export function FiUser(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/><path d="M12 3A4 4 0 1 0 12 11 4 4 0 1 0 12 3z"/>'
      }, props)
  }
  export function FiUsers(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/><path d="M9 3A4 4 0 1 0 9 11 4 4 0 1 0 9 3z"/><path d="M23 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75"/>'
      }, props)
  }
  export function FiVideoOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiVideo(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M23 7 16 12 23 17 23 7z"/><rect width="15" height="14" x="1" y="5" rx="2" ry="2"/>'
      }, props)
  }
  export function FiVoicemail(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5.5 7A4.5 4.5 0 1 0 5.5 16 4.5 4.5 0 1 0 5.5 7z"/><path d="M18.5 7A4.5 4.5 0 1 0 18.5 16 4.5 4.5 0 1 0 18.5 7z"/><path d="M5.5 16 18.5 16"/>'
      }, props)
  }
  export function FiVolume1(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 5 6 9 2 9 2 15 6 15 11 19 11 5z"/><path d="M15.54 8.46a5 5 0 0 1 0 7.07"/>'
      }, props)
  }
  export function FiVolume2(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 5 6 9 2 9 2 15 6 15 11 19 11 5z"/><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"/>'
      }, props)
  }
  export function FiVolumeX(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 5 6 9 2 9 2 15 6 15 11 19 11 5z"/><path d="M23 9 17 15"/><path d="M17 9 23 15"/>'
      }, props)
  }
  export function FiVolume(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 5 6 9 2 9 2 15 6 15 11 19 11 5z"/>'
      }, props)
  }
  export function FiWatch(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 5A7 7 0 1 0 12 19 7 7 0 1 0 12 5z"/><path d="M12 9 12 12 13.5 13.5"/><path d="m16.51 17.35-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"/>'
      }, props)
  }
  export function FiWifiOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M1 1 23 23"/><path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55M5 12.55a10.94 10.94 0 0 1 5.17-2.39M10.71 5.05A16 16 0 0 1 22.58 9M1.42 9a15.91 15.91 0 0 1 4.7-2.88M8.53 16.11a6 6 0 0 1 6.95 0"/><path d="M12 20 12.01 20"/>'
      }, props)
  }
  export function FiWifi(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M5 12.55a11 11 0 0 1 14.08 0M1.42 9a16 16 0 0 1 21.16 0M8.53 16.11a6 6 0 0 1 6.95 0"/><path d="M12 20 12.01 20"/>'
      }, props)
  }
  export function FiWind(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M9.59 4.59A2 2 0 1 1 11 8H2m10.59 11.41A2 2 0 1 0 14 16H2m15.73-8.27A2.5 2.5 0 1 1 19.5 12H2"/>'
      }, props)
  }
  export function FiXCircle(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12 2A10 10 0 1 0 12 22 10 10 0 1 0 12 2z"/><path d="M15 9 9 15"/><path d="M9 9 15 15"/>'
      }, props)
  }
  export function FiXOctagon(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2z"/><path d="M15 9 9 15"/><path d="M9 9 15 15"/>'
      }, props)
  }
  export function FiXSquare(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="M9 9 15 15"/><path d="M15 9 9 15"/>'
      }, props)
  }
  export function FiX(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M18 6 6 18"/><path d="M6 6 18 18"/>'
      }, props)
  }
  export function FiYoutube(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"/><path d="M9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02z"/>'
      }, props)
  }
  export function FiZapOff(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M12.41 6.75 13 2 10.57 4.92"/><path d="M18.57 12.91 21 10 15.66 10"/><path d="M8 8 3 14 12 14 11 22 16 16"/><path d="M1 1 23 23"/>'
      }, props)
  }
  export function FiZap(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M13 2 3 14 12 14 11 22 21 10 12 10 13 2z"/>'
      }, props)
  }
  export function FiZoomIn(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 3A8 8 0 1 0 11 19 8 8 0 1 0 11 3z"/><path d="M21 21 16.65 16.65"/><path d="M11 8 11 14"/><path d="M8 11 14 11"/>'
      }, props)
  }
  export function FiZoomOut(props) {
      return IconTemplate({
        a: {"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"},
        c: '<path d="M11 3A8 8 0 1 0 11 19 8 8 0 1 0 11 3z"/><path d="M21 21 16.65 16.65"/><path d="M8 11 14 11"/>'
      }, props)
  }