import { render } from 'solid-js/web'
import { Router, Route, useNavigate, useLocation } from '@solidjs/router'
import { ErrorBoundary } from "solid-js"
import { install } from 'resize-observer'
import { Show, createMemo, Suspense, onMount, createSignal, createEffect, lazy } from 'solid-js'
import * as BaseConfig from "./config/base-config"
import 'tw-elements'
import './global.css'
import * as Sentry from '@sentry/browser'
import { Toaster } from 'solid-sonner'
import * as Utils from './utils/utils'
import PrivacyTermsOfUseModal from './components/shared/widgets/PrivacyTermsOfUseModal'
import LoadingPageIndicator from "./components/shared/widgets/loading-page-indicator"
import { MetaProvider } from '@solidjs/meta'
import device from "current-device"
import { RiSystemCloseLine } from "solid-icons/ri"

// import page components
import Sidebar from './components/shared/sidebar/sidebar'
import HeaderBar from './components/shared/header-bar/header-bar'
import Player from './components/shared/player/player'
import ShareModal from './components/shared/modals/ShareModal'
import { PlayerUtils } from './components/shared/player/utils/_index'

const _registerWorkboxServiceWorker = () => {
  // Register the workbox service worker in the dist folder (service-worker.js) that will precache lazy-loaded 
  // solidjs js/css files e.t.c to address the app's page load performance bottlenecks when code-splitting is enabled
  if ('serviceWorker' in navigator) {
    const serviceWorkerPath =
      process.env.NODE_ENV === 'production'
        ? '/public/service-worker.js' // Path (served from django static `public` path on production)
        : new URL('./public/service-worker.js', import.meta.url).pathname; // Path in development
  
    navigator.serviceWorker
      .register(serviceWorkerPath, { scope: '/public/' })
      .then((registration) => {
        // console.log('Service Worker registered:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}

const getLazyLoadComponent = (importFn) => lazy(importFn)

const PageNotFound = getLazyLoadComponent(() => import('./components/pages/error-pages/404-page/404-page'))

const LoginPage = getLazyLoadComponent(() => import('./components/pages/onboarding/login/login'))
const SignUpPage = getLazyLoadComponent(() => import('./components/pages/onboarding/register/register'))
const ForgotPasswordPage = getLazyLoadComponent(() => import('./components/pages/onboarding/forgot-password/forgot-password'))
const VerificationNeededPage = getLazyLoadComponent(() => import('./components/pages/onboarding/verification-needed/verification-needed'))
const VerifyAccountPage = getLazyLoadComponent(() => import('./components/pages/onboarding/verify-account/verify-account'))
const WelcomePage = getLazyLoadComponent(() => import('./components/pages/onboarding/welcome/welcome'))

const HomePage = getLazyLoadComponent(() => import('./components/pages/home/home'))
const DiscoverPage = getLazyLoadComponent(() => import('./components/pages/discover/discover'))
const MorelistingsPage = getLazyLoadComponent(() => import('./components/pages/more-listings/more-listings'))
const PlaylistsPage = getLazyLoadComponent(() => import('./components/pages/playlists/playlists-page'))
const ProfilePage = getLazyLoadComponent(() => import('./components/pages/profile/profile-page'))

const ViewDropPage = getLazyLoadComponent(() => import('./components/pages/nft/view-drop/view-drop'))
const CreateDropPage = getLazyLoadComponent(() => import('./components/pages/nft/create-drop/create-drop'))
const ResellNFTPage = getLazyLoadComponent(() => import('./components/pages/nft/resell-nft/resell-nft'))
const ViewMintedNFTPage = getLazyLoadComponent(() => import('./components/pages/nft/view-minted-nft/view-minted-nft'))
const ViewNFTPage = getLazyLoadComponent(() => import('./components/pages/nft/view-nft/view-nft'))
const RelistAllNFTsPage = getLazyLoadComponent(() => import('./components/pages/nft/relist-all-nfts/relist-all-nfts'))
const RefundAllNFTsPage = getLazyLoadComponent(() => import('./components/pages/nft/refund-all-nfts/refund-all-nfts'))
const ScanNFTTicketPage = getLazyLoadComponent(() => import('./components/pages/nft/scan-ticket/scan-ticket'))
const ViewNFTTicketPage = getLazyLoadComponent(() => import('./components/pages/nft/view-ticket/view-ticket'))

const MusicDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/music/dashboard-music'))
const KYCDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/kyc/dashboard-kyc'))
const BuySellDahboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/buy-sell-jam/dashboard-buy-sell-jam'))
const WalletDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/wallet/dashboard-wallet'))
const PagesDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/pages/dashboard-pages'))
const GalleryDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/gallery/dashboard-gallery'))
const PrivacyDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/privacy/dashboard-privacy'))
const ProfileDashboardPage = getLazyLoadComponent(() => import('./components/pages/dashboard/profile/dashboard-profile'))
const FanmailPage = getLazyLoadComponent(() => import('./components/pages/fanmail/fanmail'))

const TermsPage = getLazyLoadComponent(() => import('./components/pages/agreement/terms'))
const SupportPage = getLazyLoadComponent(() => import('./components/pages/agreement/support'))
const PrivacyPolicyPage = getLazyLoadComponent(() => import('./components/pages/agreement/privacypolicy'))
const ArtistAgreementPage = getLazyLoadComponent(() => import('./components/pages/agreement/artistagreement'))
const CopyrightPage = getLazyLoadComponent(() => import('./components/pages/agreement/copyright'))
const ReportContentPage = getLazyLoadComponent(() => import('./components/pages/legal/report-content/report-content'))

const DesktopAppSumsubVerificationPage = getLazyLoadComponent(() => import('./components/pages/mobile_app_pages/desktop_app_sumsub_verification_page/desktop_app_sumsub_verification_page'))
const MobileAppVisualizerPage = getLazyLoadComponent(() => import('./components/pages/mobile_app_pages/mobile_app_visualizer/mobile_app_visualizer'))
// Discover nouns and subnouns
const NOUNS = ["songs", "artists", "albums", "drops", "map"]
const SUB_NOUNS = [
    "bands",
    "musicians",
    "fans",
    "producers",
    "venues",
    "songwriters",
    "djs",
    "sound-engineers",
    "teachers",
    "labels",
    "looking-for-a-band",
    "down-to-jam",
]
const ALL_DISCOVER_NOUNS_AND_SUBNOUNS = NOUNS.concat(SUB_NOUNS)

Sentry.init({
  dsn: window.SENTRY_DSN,
  // This sets the sample rate to be 50%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: true,
      minReplayDuration: 0,
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      isEmailRequired: true,
    }),
  ],
})

let accessToken = localStorage.getItem('access_token')
let refreshToken = localStorage.getItem('refresh_token')

const setUserLoggedIn = (isLoggedIn) => {
  Utils.setUserLoggedIn(isLoggedIn)
  //Utils.preloadSiteWideData()
}

if (!accessToken || !refreshToken) {
  setUserLoggedIn(false)
} else {
  setUserLoggedIn(true) 
}
// Apple doing Apple things, so we need to check if ResizeObserver is available
if (!window.ResizeObserver) install();

const windowResizeObserver = new ResizeObserver((entries) => {
  computeScreenSizeData()
})
windowResizeObserver.observe(document.body)

// check screen size and calculate default listings count
const computeScreenSizeData = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) && window.innerWidth <= 640
  const isTablet = window.innerWidth <= 1200 && window.innerWidth > 640
  Utils.setBrowserUserAgentIsMobile(isMobile)
  Utils.setBrowserUserAgentIsTablet(isTablet)
  // reset mobile player collapsed status if viewport size increases
  if(!isMobile) {
    PlayerUtils.setMobilePlayerCollapsed(false)
  }

  // compute default listings count
  const listingItemWidth = isMobile 
    ? BaseConfig.listingCardDimensionsMobileScreen.width
    : BaseConfig.listingCardDimensionsStandardScreen.width
  const pageContainer = document.getElementById("singlePageWrapper")
  const columnCount = calculateListingGridColumns(pageContainer)
  BaseConfig.setDefaultColumnCount(columnCount)
  // buffer count to ensure additional results in cases not covered by calculateListingGridColumns function
  const bufferListingsCount = BaseConfig.standardListingsCount
  if(pageContainer) {
    const pageContainerHeight = pageContainer.clientHeight
    const rowCount =  Math.ceil(pageContainerHeight / listingItemWidth)
    BaseConfig.setDefaultRowCount(rowCount)
    const listingsCount = columnCount * rowCount
    BaseConfig.setDefaultListingCount(listingsCount + bufferListingsCount)
  } else {
    BaseConfig.setDefaultListingCount(BaseConfig.standardListingsCount)
  }
}

const calculateListingGridColumns = (pageContainer) => {
  let columnCount = BaseConfig.Config.masonryBreakpointColumns.default
  if(pageContainer) {
    const pageContainerWidth = pageContainer.clientWidth
    if(pageContainerWidth >= 8000) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[8000]
    } else if(pageContainerWidth >= 6000) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[6000]
    } else if(pageContainerWidth >= 5000) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[5000]
    } else if(pageContainerWidth >= 4000) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[4000]
    } else if(pageContainerWidth >= 3300) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[3300]
    } else if(pageContainerWidth >= 2500) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[2500]
    } else if(pageContainerWidth >= 2000) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[2000]
    } else if(pageContainerWidth >= 1500) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[1500]
    } else if(pageContainerWidth >= 1100) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[1100]
    } else if(pageContainerWidth >= 700) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[700]
    } else if(pageContainerWidth >= 500) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[500]
    } else if(pageContainerWidth >= 300) {
      columnCount = BaseConfig.Config.masonryBreakpointColumns[300]
    }
  }
  return columnCount
}

// Define your routes
const routes = [
  {
    path: '/nft/scan-ticket/:token_id',
    component: ScanNFTTicketPage,
  },
  {
    path: '/nft/view-ticket/:token_id',
    component: ViewNFTTicketPage,
  },
  {
    path: '/kyc/desktop-app-sumsub-verification-page',
    component: DesktopAppSumsubVerificationPage,
  },
  {
    path: '/player/mobile-app-viz',
    component: MobileAppVisualizerPage,
  },
  {
    path: '/join',
    component: SignUpPage,
  },
  {
    path: '/not-verified',
    component: VerificationNeededPage,
  },
  {
    path: '/verify',
    component: VerifyAccountPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/password-recovery',
    component: ForgotPasswordPage,
  },
  {
    path: '/welcome',
    component: WelcomePage,
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/listings',
    component: HomePage,
  },
  {
    path: '/more/:title',
    component: MorelistingsPage,
  },
  {
    path: '/top/playlists',
    component: PlaylistsPage,
  },
  {
    path: '/nft/drop/:username/:slug',
    component: ViewDropPage,
  },
  {
    path: '/nft/create-drop-page/:savedDropIndex/:tierIndex',
    component: CreateDropPage,
  },
  {
    path: '/nft/create-drop-page',
    component: CreateDropPage,
  },
  {
    path: '/nft/sell-nft/:token_id/:serial',
    component: ResellNFTPage,
  },
  {
    path: '/nft/view/:token_id/:serial',
    component: ViewMintedNFTPage,
  },
  {
    path: '/nft/view/:token_id',
    component: ViewNFTPage,
  },
  {
    path: '/nft/relist-all/:token_id',
    component: RelistAllNFTsPage,
  },
  {
    path: '/my-account/music',
    component: MusicDashboardPage,
  },
  {
    path: '/my-account/albums',
    component: MusicDashboardPage,
  },
  {
    path: '/my-account/songs',
    component: MusicDashboardPage,
  },
  {
    path: '/my-account/kyc',
    component: KYCDashboardPage,
  },
  {
    path: '/my-account/buy-sell-jam',
    component: BuySellDahboardPage,
  },
  {
    path: '/nft/refund-all',
    component: RefundAllNFTsPage,
  },
  {
    path: '/my-account/wallet',
    component: WalletDashboardPage,
  },
  {
    path: '/my-account/pages',
    component: PagesDashboardPage,
  },
  {
    path: '/my-account/gallery',
    component: GalleryDashboardPage,
  },
  {
    path: '/my-account/settings',
    component: PrivacyDashboardPage,
  },
  {
    path: '/my-account/profile',
    component: ProfileDashboardPage,
  },
  {
    path: '/mail/inbox',
    component: FanmailPage,
  },
  {
    path: '/artists-albums-songs-drops-map/',
    component: DiscoverPage,
  },
  {
    path: '/artists-albums-songs-drops/',
    component: DiscoverPage,
  },
  {
    path: '/artists-albums-songs/',
    component: DiscoverPage,
  },
  {
    path: '/songs',
    component: DiscoverPage,
  },
  {
    path: '/artists',
    component: DiscoverPage,
  },
  {
    path: '/albums',
    component: DiscoverPage,
  },
  {
    path: '/drops',
    component: DiscoverPage,
  },
  {
    path: '/terms',
    component: TermsPage,
  },
  {
    path: '/support',
    component: SupportPage,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/artist-agreement',
    component: ArtistAgreementPage,
  },
  {
    path: '/report-content',
    component: ReportContentPage,
  },
  {
    path: '/copyright',
    component: CopyrightPage,
  },
  {
    path: '/song/:keyword/',
    component: DiscoverPage,
  },
  {
    path: '/song/:keyword',
    component: DiscoverPage,
  },
  {
    path: '/:location/:artists/genre/:genre/inst/:inst/',
    component: DiscoverPage,
  },
  {
    path: '/:location/:artists/genre/:genre/',
    component: DiscoverPage,
  },
  {
    path: '/:location/:artists/inst/:inst/',
    component: DiscoverPage,
  },
  {
    path: '/:artists/genre/:genre/inst/:inst/',
    component: DiscoverPage,
  },
  {
    path: '/:artists/genre/:genre/',
    component: DiscoverPage,
  },
  {
    path: '/:artists/inst/:inst/',
    component: DiscoverPage,
  },
  {
    path: '/:keyword/album/:albumkeyword/',
    component: DiscoverPage,
  },
  {
    path: '/:keyword/playlists/:titleSlug',
    component: ProfilePage,
  },
  {
    path: '/:keyword/:section/:recordId',
    component: function () {
      let path = useLocation()
      let pathname = createMemo(() => path.pathname)
      let profilePathRegex = new RegExp('^/@([^/]+)(?:/([^/]+))?(?:/([^/]+))?/?$')
      let profilePathMatch = pathname().match(profilePathRegex)
      if (profilePathMatch) {
        return ProfilePage
      }
      return PageNotFound
    },
  },
  {
    path: '/:keyword/:section',
    component: function () {
      //this route matches /@Southwork/music and /Montreal,QC,Canada/artists
      // and /@Southwork/music/ and /Montreal,QC,Canada/artists/
      let path = useLocation()
      let pathname = createMemo(() => path.pathname)
      const isDiscoverPage = ALL_DISCOVER_NOUNS_AND_SUBNOUNS.some(keyword => pathname().includes(keyword))
      let profilePathRegex = new RegExp('^/@([^/]+)(?:/([^/]+))?(?:/([^/]+))?/?$')
      let profilePathMatch = pathname().match(profilePathRegex)
      if (profilePathMatch) {
        return ProfilePage
      } else if (isDiscoverPage) {
        return DiscoverPage
      }
      return PageNotFound
    },
  },
  {
    path: '/:keyword/:section/',
    component: function () {
      //this route matches /@Southwork/music/ and /Montreal,QC,Canada/artists/
      let path = useLocation()
      let pathname = createMemo(() => path.pathname)
      const isDiscoverPage = ALL_DISCOVER_NOUNS_AND_SUBNOUNS.some(keyword => pathname().includes(keyword))
      let profilePathRegex = new RegExp('^/@([^/]+)(?:/([^/]+))?/$')
      let profilePathMatch = pathname().match(profilePathRegex)
      if (profilePathMatch) {
        return ProfilePage
      } else if (isDiscoverPage) {
        return DiscoverPage
      }
      return PageNotFound
    },
  },
  {
    path: '/artists-map',
    component: DiscoverPage,
  },
  {
    path: '/:keyword/',
    component: function () {
      //this route matches /@Southwork/ and /artists-albums-songs-drops-map/ (various premutations of artists-albums-songs-drops-map)
      let path = useLocation()
      let pathname = createMemo(() => path.pathname)
      const isDiscoverPage = ALL_DISCOVER_NOUNS_AND_SUBNOUNS.some(keyword => pathname().includes(keyword))
      let profilePathRegex = new RegExp('^/@([^/]+)(?:/([^/]+)(?:/([^/]+))?)?/?$')
      let profilePathMatch = pathname().match(profilePathRegex)
      if (profilePathMatch) {
        return ProfilePage
      } else if (isDiscoverPage) {
        return DiscoverPage
      }
      return PageNotFound
    },
  },
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

function App(props) {
  const navigate = useNavigate()
  const path = useLocation()
  const pathname = createMemo(() => path.pathname)

  const [showAppDownloadPrompt, setShowAppDownloadPrompt] = createSignal(false)
  const [deviceOSText, setDeviceOSText] = createSignal('')
  const [appDownloadUrl, setAppDownloadUrl] = createSignal()
  const localStorageDownloadAppPromptHideKey = 'hideAppDownloadPrompt'
  const urlsToExcludePlayer = [
    '/join', '/login', '/password-recovery', '/verify', '/not-verified', '/player/mobile-app-viz',
    '/kyc/desktop-app-sumsub-verification-page',
  ]

  const checkDeviceType = () => {
    let hideDownloadPrompt = false
    let hideDownloadPromptString = localStorage.getItem(localStorageDownloadAppPromptHideKey)
    if (hideDownloadPromptString && hideDownloadPromptString == '1') {
      hideDownloadPrompt = true
    }

    if(!hideDownloadPrompt
      && (device.android() || device.ios() || device.windows() || device.os == 'macos')) {
      setShowAppDownloadPrompt(true)
      setDeviceOSText(
        device.android() ? 'Android' : device.ios() ? 'iOS' : device.windows() ? 'Windows' : 'macOS'
      )
      setAppDownloadUrl(
        device.android()
          ? 'https://play.google.com/store/apps/details?id=fm.tune.app'
          : device.ios()
            ? 'https://apps.apple.com/us/app/tune-fm/id6472261026'
            : device.windows()
              ? undefined
              : undefined // 'https://apps.apple.com/us/app/tune-fm/id6472261026'
      )
    }

    checkIfShoudldShowAppDownloadPromptOnPage()
  }

  const checkIfShoudldShowAppDownloadPromptOnPage = () => {
    createEffect(() => {
      if(pathname()) {
        let hideAppDownloadPrompt = !showAppDownloadPrompt()
        for(let i = 0; i < Utils.pagesToHideAppDownloadPrompt.length; i++) {
          const path = Utils.pagesToHideAppDownloadPrompt[i]
          if(pathname().includes(path)) {
            hideAppDownloadPrompt = true
            break
          }
        }
        setShowAppDownloadPrompt(!hideAppDownloadPrompt)
      }
    })
  }

  const closeAppDownloadPrompt = (dontShowAgain = false) => {
    setShowAppDownloadPrompt(false)
    if(dontShowAgain) {
      localStorage.setItem(localStorageDownloadAppPromptHideKey, '1')
    }
  }

  onMount(() => {
    Utils.docReady(() => {
      _registerWorkboxServiceWorker()
      // check device type
      checkDeviceType()
      computeScreenSizeData()
    })
  })

  function closeModal(e) {
    e.preventDefault()
    e.stopPropagation()
    const modal = document.getElementById("modalLogin")
    if(modal) {
        modal.classList.remove('show')
        modal.classList.add('dont-show')
      }
  }

  function navigateToLogin(e) {
      closeModal(e)
      navigate('/login')
  }

  return (
    <>
      <div id="bgWrapper">
        <div class="pageParentContainer">
          {/* Sidebar */}
          <Sidebar/>
          {/* Page content */}
          <div class="solidPageContainer">
            {/* Header bar */}
            <HeaderBar/>
            {/* Routes/Pages */}
            <div 
            id="singlePageWrapper"
            class="singlePageWrapper height-fill-available"
            >
              {props.children}
            </div>
          </div>

          {/* MODALS - All use position: fixed hence can be placed here without affecting other UI */}
          {/* Login prompt modal */}
          <div
            id="modalLogin"
            class="modal modalContainer dont-show"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered modalDialog">
              <div class="modal-content modalContent">
                <div class="modal-header modalHeader">
                  <i
                    class="ri-close-line closeIcon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  ></i>
                </div>
                <div class="modal-body modalBody">
                  <p class="modalTitle capitalize">Login Required</p>
                  <p>Please login to enjoy the complete platform.</p>
                </div>
                <div class="modal-footer modalFooter justify-center">
                  <a href="/login">
                    <div
                      onClick={navigateToLogin}
                      class="closeButton"
                      data-bs-dismiss="modal"
                    >
                      Sign In
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/*-- KYC Prompt --*/}
          <div
            id="modalKYCPrompt"
            class="modal modalContainer dont-show"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered modalDialog">
              <div class="modal-content modalContent">
                <div class="modal-header modalHeader">
                  <i
                    class="ri-close-line closeIcon"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>
                <div class="modal-body modalBody">
                  <p class="modalTitle capitalize">KYC Required</p>
                  <p class="modalDescription">
                    Please complete our KYC process to enable these
                    features: Downloading of private keys, buying and
                    minting of collectibles, and sending, buying and selling of
                    JAM tokens
                  </p>
                </div>
                <div class="modal-footer modalFooter">
                  <div class="closeButton" data-bs-dismiss="modal">
                    Not Now
                  </div>
                  <a href="/my-account/kyc">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate('/my-account/kyc')
                      }}
                      class="closeButton"
                      data-bs-dismiss="modal"
                    >
                      Complete KYC
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ShareModal/>
          {/* Toast rendering component */}
          <Toaster 
          position="top-right" 
          richColors={true}
          closeButton={true}
          toastOptions={{ class: 'toastContainer', descriptionClass: 'toastDescription' }}
          />
        </div>
        {/* Bottom and full-screen players */}
        <Show when={
          !urlsToExcludePlayer.some(pathToExclude => useLocation().pathname.includes(pathToExclude))
        }>
          <Player />
        </Show>
      </div>
      {/*-- privacy and terms of use --*/}
      <Show when={Utils.userLoggedIn() && Utils.userProfileData() && !Utils.userProfileData().view.signed_terms_agreement_10_22 && !Utils.userProfileData().view.signed_privacy_policy_agreement_07_22}>
        <PrivacyTermsOfUseModal />
      </Show>
      <div id="darkness" style="display: none">
        <div class="arrow left"></div>
        <div class="arrow right"></div>
        <div id="focus-element"></div>
      </div>
      <Show when={showAppDownloadPrompt() && appDownloadUrl()}>
        <div class="appDownloadPromptWrapper">
          <div class="titleWrapper">
            <span class="title">Download the {deviceOSText()} app</span>
            <RiSystemCloseLine
            class="closeButton"
            onClick={() => {
              closeAppDownloadPrompt(true)
            }} />
          </div>
          <span class="subtitle">Start using the Tune.fm app today for an improved experience</span>
          <a class="downloadButton" href={appDownloadUrl()} target="_blank">
            Download {deviceOSText()} app
          </a>
          <span
          class="dontShowAgain"
          onClick={() => {
            closeAppDownloadPrompt(true)
          }}
          >Don't show again</span>
        </div>
      </Show>
    </>
  )
}

render(
  () => (
    <MetaProvider>
      <Router root={App} preload={true}>
      {routes.map((route) => (
        // Error boundary to catch load errors
        <ErrorBoundary fallback={(err) => <span class="text-white text-xl">Error: {err.message}</span>}>
          {/* Suspense to allow display of loading indicator when route component is lazy loading*/}
          <Suspense
          fallback={
            (
              <div class="w-full height-fill-available">
              <div
                id="mainContainer"
                class="mainContainer flex flex-col gap-3 justify-center items-center"
              >
                <LoadingPageIndicator centered={true} />
                <span class="text-white text-xl">Loading...</span>
              </div>
            </div>
            )
          }
          >
            <Route path={route.path} component={route.component} />
          </Suspense>
        </ErrorBoundary>
      ))}
      </Router>
    </MetaProvider>
  ),
  document.getElementById('solidAppRoot')!
)
