
export default function LoadingPageIndicator (props) {
  const centered = props.centered !== null && props.centered !== undefined ? props.centered : true
  
  return (
    <div id="solid-pages-load-indicator" class="spinner" classList={{ 
      'solid-load-indicator-centered': centered,
      'spinner--large': !props.medium && !props.small,
      'spinner--medium': props.medium,
      'spinner--small': props.small
    }}></div>
  );
}