import axios from 'axios'
import queryString from 'query-string'
import { createSWRStore } from 'swr-store'
import { useSWRStoreSuspenseless } from 'solid-swr-store'
import jwt_decode from 'jwt-decode'
import { Utils } from '../utils/_index'




function handleError(errorResponse, displayErrorMessage) {
  let message = errorResponse?.response?.data?.message || errorResponse?.message || "An error occurred"
  if(errorResponse?.code !== "ERR_CANCELED" && errorResponse?.code !== "ERR_BAD_RESPONSE" && errorResponse?.code !== "ECONNABORTED" && displayErrorMessage){
    // we dont want to display error when we abort a request, or get 500 from server
    Utils.showToast(message, "warning", 3000)
  }   
  return {
    success: false,
    message: message,
    ...errorResponse?.response?.data
  }
}

const handleSuccess = (payload) => {
  return payload.data
}


export async function getRequest({
  url,
  params = {},
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true,
  signal=null,
  allowAPIDataCaching=false,
  invalidateCacheOnPageRefresh=false, // set to true, will remove cache when page is refreshed.
  returnAPICachedData=false, // set to true if you want to immediatedly return a API cached data and not make the API call
  expiryPeriodForAPICachedData=1000 * 60 * 60 * 3, //in seconds
  loadAPICachedData=null,  // this function is called to return API cached data and still go ahead to make the API call
  onSuccess=null,
  onError=null,
  displayErrorMessage=false
}) {
  return new Promise(async (resolve, reject) =>{
  if(invalidateCacheOnPageRefresh){
    Utils.setUrlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh((prev) => [...prev, url])
  }

  if(loadAPICachedData){
    let apiResp = await getStoredAPIdata(url, params)
    if(apiResp){
      apiResp.isCachedData = true 
      resolve(loadAPICachedData(apiResp))
    }
  }

  if(returnAPICachedData){
    let apiData = await getStoredAPIdata(url, params) 
    if(apiData){
      apiData.isCachedData = true
      resolve(onSuccess ? onSuccess(apiData) : apiData)
      return
    }
  } 

  try {
    let authHeaderValue = 'None'
    if (addAuthHeader) {
      authHeaderValue = await getAuthHeaderValue(logoutUserIfNoAuthHeader)
      if (!authHeaderValue && logoutUserIfNoAuthHeader) {
        resolve(null)
        return
      }
    }

    const payload = await axios.get(url, {
      signal : signal, 
      headers: {
        'X-CSRFToken': window.csrfTOKEN,
        Authorization: authHeaderValue,
      },
      params: params,
      paramsSerializer: (params) => {
        return queryString.stringify(params)
      },
    })

    if(allowAPIDataCaching && payload.status === 200 && Utils.isNotEmptyObjectOrArray(payload.data)){
      try{
        let apiCacheKey = getAPICacheKeyFromUrlAndParams(url, params)
        const cache = await caches.open("tune-data-cache-v1");
        let expiryDate = Date.now() + expiryPeriodForAPICachedData
        const responseToCache = new Response(JSON.stringify(payload.data), {
          headers: { 
            'Content-Type': 'application/json',
            'cache-expiration': expiryDate.toString()
          }
        });
        await cache.put(apiCacheKey, responseToCache.clone());
      }catch(e){
        console.log(e)
      }
      
    }
    payload.data.isCachedData = false
    resolve(onSuccess ? onSuccess(payload.data) : handleSuccess(payload))
    return 
    
  } catch (error) {
    resolve(onError ? onError(error) : handleError(error,  displayErrorMessage))
    return
  }
  })  
  
}


export async function postRequest({
  url,
  postData = {},
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true,
  withCreds = true,
  opts = { headers: {} },
  onSuccess=null,
  onError=null,
  displayErrorMessage=false
}) {
  return new Promise(async (resolve, reject) =>{
  let formData = new FormData()
  for (let key of Object.keys(postData)) {
    if (isObject(postData[key])) {
      if (postData[key] instanceof File) {
        formData.append(key, postData[key])
      } else {
        formData.append(key, JSON.stringify(postData[key]))
      }
    } else {
      formData.append(key, postData[key])
    }
  }

  let authHeaderValue = 'None'
  if (addAuthHeader) {
    authHeaderValue = await getAuthHeaderValue(logoutUserIfNoAuthHeader)
    if (!authHeaderValue && logoutUserIfNoAuthHeader) {
      resolve(null)
      return
    }
  }

  const options = {
    ...opts,
    headers: {
      ...opts.headers,
      'X-CSRFToken': window.csrfTOKEN,
      Authorization: authHeaderValue,
    },
    withCredentials: withCreds,
  }

  try {
    const payload = await axios.post(url, formData, options)
    resolve(onSuccess ? onSuccess(payload.data) : handleSuccess(payload))
    return 
    
  } catch (error) {
    resolve(onError ? onError(error) : handleError(error, displayErrorMessage) )
    return 
  }
  })  
}

export async function putRequest({
  url,
  postData,
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true,
  withCreds = true,
  opts = { headers: {} },
  onSuccess=null,
  onError=null,
  displayErrorMessage=false
}) {
  return new Promise(async (resolve, reject) =>{
  let formData = new FormData()
  for (let key of Object.keys(postData)) {
    if (isObject(postData[key])) {
      if (postData[key] instanceof File) {
        formData.append(key, postData[key])
      } else {
        formData.append(key, JSON.stringify(postData[key]))
      }
    } else {
      formData.append(key, postData[key])
    }
  }

  let authHeaderValue = 'None'
  if (addAuthHeader) {
    authHeaderValue = await getAuthHeaderValue(logoutUserIfNoAuthHeader)
    if (!authHeaderValue && logoutUserIfNoAuthHeader) {
      resolve(null)
      return
    }
  }

  const options = {
    ...opts,
    headers: {
      ...opts.headers,
      'X-CSRFToken': window.csrfTOKEN,
      Authorization: authHeaderValue,
    },
    withCredentials: withCreds,
  }

  try {
    const payload = await axios.put(url, formData, options)
    resolve(onSuccess ? onSuccess(payload.data) : handleSuccess(payload))
    return
  } catch (error) {
    resolve(onError ? onError(error) : handleError(error, displayErrorMessage))
    return 
  }
})
}


export async function deleteRequest({
  url,
  postData={},
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true,
  onSuccess=null,
  onError=null,
  displayErrorMessage=false
}) {
  return new Promise(async (resolve, reject) =>{
  let formData = new FormData()
  for (let key of Object.keys(postData)) {
    if (isObject(postData[key])) {
      formData.append(key, JSON.stringify(postData[key]))
    } else {
      formData.append(key, postData[key])
    }
  }

  let authHeaderValue = 'None'
  if (addAuthHeader) {
    authHeaderValue = await getAuthHeaderValue(logoutUserIfNoAuthHeader)
    if (!authHeaderValue && logoutUserIfNoAuthHeader) {
      resolve(null)
      return
    }
  }

  const options = {
    data: formData,
    headers: {
      'X-CSRFToken': window.csrfTOKEN,
      Authorization: authHeaderValue,
    },
    withCredentials: true
  }

  try {
    const payload = await axios.delete(url, options)
    resolve(onSuccess ? onSuccess(payload.data) : handleSuccess(payload))
    return
    
  } catch (error) {
    resolve(onError ? onError(error) : handleError(error, displayErrorMessage))
    return 
  }
})
  
}


export async function sendBeaconPostRequest({
  url,
  postData = {}
}) {
  const data = JSON.stringify(postData); 
  window.navigator.sendBeacon(url, data); 
}


export const getAuthHeaderValue = async (logoutUserIfNoAuthHeader) => {
  // getJWTAccessToken
  const accessToken = await getJWTAccessToken()
  if (accessToken) {
    return `Bearer ${accessToken}`
  } else {
    // if no access token returned logout user
    if (logoutUserIfNoAuthHeader) {
      logoutUser()
    } else {
      return 'None'
    }
  }
}

export const getJWTAccessToken = async (refreshIfExpired = true) => {
  let currentAccessToken = localStorage.getItem('access_token')
  if (currentAccessToken && currentAccessToken != '') {
    if (!isJWTTokenExpired(currentAccessToken)) {
      return currentAccessToken
    } else if (refreshIfExpired) {
      let newAccessToken = await refreshJWTTokens()
      return newAccessToken
    } else {
      return null
    }
  } else {
    return null
  }
}

const refreshJWTTokens = async () => {
  let refreshToken = getJWTRefreshToken()
  if (refreshToken) {
    try {
      const response = await axios.post('/api/v1/auth/token-refresh', {
        refresh: refreshToken,
      })
      let data = response.data
      localStorage.setItem('access_token', data.access)
      localStorage.setItem('refresh_token', data.refresh)
      return data.access
    } catch (error) {
      // token refresh error
      return null
    }
  } else {
    // refresh token is null
    return null
  }
}

const getJWTRefreshToken = () => {
  let refreshToken = localStorage.getItem('refresh_token')
  if (refreshToken && refreshToken != '' && !isJWTTokenExpired(refreshToken)) {
    return refreshToken
  } else {
    return null
  }
}

export const isJWTTokenExpired = (accessToken) => {
  try {
    const decodedToken = jwt_decode(accessToken)
    return decodedToken.exp < Date.now() / 1000
  } catch (err) {
    return true // Token is invalid or expired
  }
}

export const logoutUser = ({ passNextUrlParam = true } = {}) => {
  // Check if user is logged in, if not return
  /*
  if (!localStorage.getItem('access_token')) {
    return
  }
  else if (window.location.pathname.includes('login') || window.location.pathname.includes('join')) { 
    return
  }
  */

  // clear API Cached Data.
  if(Utils.userLoggedIn()){
    Utils.clearAllCachedAPIdata()
  }

  //Before user logs out call for process of listens
  if (!localStorage.getItem('access_token')) {
    return
  }
  
  let logoutUrl = '/logout'
  const refreshToken = getJWTRefreshToken()
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  if (refreshToken) {
    logoutUrl += `?refresh_token=${refreshToken}`
  }
  if (passNextUrlParam) {
    const nextUrl = window.location.pathname
    logoutUrl = logoutUrl + `${refreshToken ? '&' : '?'}next=${nextUrl}`
  }
  window.location.href = logoutUrl
}

// Cached requests - Stale While Revalidate

export const getApi = createSWRStore<JSON, [string]>({
  key: (getInput: string) => getInput,
  get: async (getInput: string) => {
    let getInputJSON = JSON.parse(getInput)

    let authHeaderValue = 'None'
    if (getInputJSON.addAuthHeader) {
      authHeaderValue = await getAuthHeaderValue(
        getInputJSON.logoutUserIfNoAuthHeader
      )
      if (!authHeaderValue && getInputJSON.logoutUserIfNoAuthHeader) {
        return
      }
    }
    const payload = await axios.get(getInputJSON.url, {
      headers: {
        'X-CSRFToken': window.csrfTOKEN,
        Authorization: authHeaderValue,
      },
      params: getInputJSON.params,
    })
    return payload.data
  },
  revalidateOnFocus: false,
  revalidateOnNetwork: true,
  // maxRetryCount: 2,
})

export const postApi = createSWRStore<JSON, [string]>({
  key: (getInput: string) => getInput,
  get: async (getInput: string) => {
    let getInputJSON = JSON.parse(getInput)

    let formData = new FormData()
    for (let key of Object.keys(getInputJSON.params)) {
      formData.append(key, getInputJSON.params[key])
    }

    let authHeaderValue = 'None'
    if (getInputJSON.addAuthHeader) {
      authHeaderValue = await getAuthHeaderValue(
        getInputJSON.logoutUserIfNoAuthHeader
      )
      if (!authHeaderValue && getInputJSON.logoutUserIfNoAuthHeader) {
        return
      }
    }
    const options = {
      headers: {
        'X-CSRFToken': window.csrfTOKEN,
        Authorization: authHeaderValue,
      },
    }
    const payload = await axios.post(getInputJSON.url, formData, options)
    return payload.data
  },
  revalidateOnFocus: false,
  revalidateOnNetwork: true,
  // maxRetryCount: 2,
})

export async function postRequestCached(
  url,
  postData,
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true
) {
  const data = useSWRStoreSuspenseless(
    postApi,
    () => [
      JSON.stringify({
        url: url,
        params: postData,
        addAuthHeader: addAuthHeader,
        logoutUserIfNoAuthHeader: logoutUserIfNoAuthHeader,
      }),
    ],
    {
      suspense: false,
    }
  )
  return await data().data
}

export async function getRequestCached(
  url,
  postData,
  addAuthHeader = true,
  logoutUserIfNoAuthHeader = true
) {
  const data = useSWRStoreSuspenseless(
    getApi,
    () => [
      JSON.stringify({
        url: url,
        params: postData,
        addAuthHeader: addAuthHeader,
        logoutUserIfNoAuthHeader: logoutUserIfNoAuthHeader,
      }),
    ],
    {
      suspense: false,
    }
  )

  return await data().data
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const getDropById = async (id) => {
  // If has dropPassword cookie, set it as query param
  let dropPassword = getCookie('dropPassword')
  let url = `/api/v1/drops/${id}`
  if (dropPassword) {
    url += `?password=${dropPassword}`
  }
  return await getRequest({url, addAuthHeader:true, logoutUserIfNoAuthHeader:false, displayErrorMessage: true})
}

export const getNftByTokenId = async (token_id) => {
  return await getRequest({url:`/api/v1/nfts?token_id=${token_id}`, addAuthHeader:true, logoutUserIfNoAuthHeader:false})
}

export const getMintedNftById = async (id) => {
  return await getRequest({url:`/api/v1/minted-nfts?token=${id}`, addAuthHeader: true, logoutUserIfNoAuthHeader:false})
}

export const getDropStatusById = async (id) => {
  let url = '/api/v1/nft/drop-status'
  let data = {
    id: id,
  }
  return await getRequest({url, params:data, displayErrorMessage: true})
}

export const postBid = async (amount, auction_id) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return {}
  } else {
    let data = {
      amount: amount,
      auction: auction_id,
    }
    return await postRequest({url:'/api/v1/bids', postData:data})

  }

}

export const getOwnedNftsByProfileKeyword = async (profile_keyword) => {
  return await getRequest({
    url:`/api/v1/user-nfts?profile=${profile_keyword}`,
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:false,
    onError: (error)=>{
      return []
    }
  })
}

export const getCreatedDropsByProfileKeyword = async (profile_keyword) => {
  return await getRequest({
    url:`/api/v1/user-drops?profile=${profile_keyword}`,
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:false,
    onError: (error) => {
      return []
    }
  })
}

export const getMyWalletDetails = async () => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   return ({ "hedera_enabled": true, "hedera_account_id": "0.0.49139691", "token_balance": 100000000000, "starter_tokens": true, "unpaid_tokens": 0, "listens": [], "assets": [{ "assetName": "HBAR", "balance": 0, "symbol": "ℏ" }, { "tokenId": "0.0.30830510", "assetName": "JAM", "balance": 1000.0, "symbol": "J", "type": "FUNGIBLE_COMMON" }], "show_send_receive": false });
  // }
  return await getRequest({url:'/api/v1/wallet/wallet',
    allowAPIDataCaching: true, 
    returnAPICachedData: true, 
    expiryPeriodForAPICachedData:1000 * 30, //cache for 30 secs
    onError: (error) => {} })
  
}

export const getJamRate = async () => {
  return await getRequest({
    url:'/api/v1/trade-jam/price', 
    addAuthHeader:false,
    allowAPIDataCaching: true, 
    returnAPICachedData: true, 
    onError: (error) => {
      return {}
    }
  })
}

export const getNftFees = async (nft_type, nft_supply) => {
  return await getRequest({
    url:`/api/v1/nft/fees?nft_type=${nft_type}&supply=${nft_supply}`,
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:false,
    onError: (error) => {
      return {}
    }
  })
}

export const postBuyNft = async (listing_id, nft_id, displayErrorMessage = true) => {
  return await postRequest({url:'/api/v1/nft/buy-nft', postData:{
    listing: listing_id,
    nft: nft_id,
  },  displayErrorMessage: displayErrorMessage})
}

export const postDelistNft = async (listing_id, nft_id) => {
  return await postRequest({url:'/api/v1/nft/delist-nft', postData:{
    listing: listing_id,
    nft: nft_id,
  }})
}

export const postClaimAllNfts = async (nft_id) => {
  return await postRequest({
    url: `/api/v1/nft/claim-all-minted-nfts`,
    postData:{
      nft_id: nft_id,
    },
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:true
})
}

export const postClaimNft = async (mintednft_id) => {
  return await postRequest({
    url:`/api/v1/nft/claim-minted-nft`,
    postData:{
      mintednft_id: mintednft_id,
    },
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:true
})
}

export const postAssociateNft = async (nft_id) => {
  return await postRequest({
    url:`/api/v1/nft/associate-nft`,
    postData:{
      nft_id: nft_id,
    },
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:true
})
}

export const getBidsByAuctionId = async (id) => {
  return await getRequest({url:`/api/v1/bids?auction_id=${id}`, addAuthHeader:true, logoutUserIfNoAuthHeader:false,
    onError: (error) => {
      return []
    }
  })
}

export const getJamUsdPrice = async () => {
  let url = '/api/v1/trade-jam/price'
  return await getRequest({
    url, 
    allowAPIDataCaching: true, 
    returnAPICachedData: true, 
    onSuccess: (response) => {
      if (response && response.jam_price) {
        return response.jam_price
      }
    }, onError: (error) => {
      return null
    }
  })

}

export const submitSignupNotification = async (drop_id, email) => {
  return await postRequest({
    url:`/api/v1/nft/drop-notification-signup`,
    postData:{
      drop: drop_id,
      email: email,
    },
    addAuthHeader:true,
    logoutUserIfNoAuthHeader:false,
    displayErrorMessage: true
})
}

export const getAPICacheKeyFromUrlAndParams = (url, params) => {
  let cache_url_key = url
  if(Object.keys(params).length){
    cache_url_key = url + "?" + queryString.stringify(params)
  }
  return cache_url_key 

}

export const getStoredAPIdata = async (url, params) => {
  try{
    let apiCacheKey = getAPICacheKeyFromUrlAndParams(url, params)
    const cache = await caches.open("tune-data-cache-v1");
    const cachedResponse = await cache.match(apiCacheKey);
    if(cachedResponse){
      const expirationDate = Number(cachedResponse.headers.get('cache-expiration'));
      if (expirationDate && Date.now() > expirationDate) {
          // Cached response is expired, delete it
          await cache.delete(apiCacheKey);
          return null
      }
      return await cachedResponse.json()
    }
  }catch(e){
    console.log(e)
  }
  return null

}


export const deleteStoredAPIdata = async (url, params={}) => {
  try{
    let apiCacheKey = getAPICacheKeyFromUrlAndParams(url, params)
    const cache = await caches.open("tune-data-cache-v1");
    const keys = await cache.keys();

    for (let request of keys) {
      if (request.url.includes(apiCacheKey)) { // Check if the key contains the partial string
        await cache.delete(request);
      }
    }
  }catch(e){
    console.log(e)
  }
}



function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}
