export default class HlsAdapter {
    audioElement: HTMLAudioElement;

    constructor() {
        this.audioElement = this.createAudioElement();
    }

    createAudioElement() {
        let audioElement = document.querySelector('#hlsAudioElement');
        if (!audioElement) {
            audioElement = document.createElement('audio');
            audioElement.id = 'hlsAudioElement';
            document.body.appendChild(audioElement);
        }
        return audioElement as HTMLAudioElement;
    }

    unload() {
        this.audioElement.src = '';
        this.audioElement.remove();
    }

    duration() {
        return this.audioElement.duration;
    }

    playing() {
        return !this.audioElement.paused;
    }

    seek(timeInSeconds = null) {
        if (timeInSeconds !== null) {
            this.audioElement.currentTime = timeInSeconds;
            return timeInSeconds;
        }
        return this.audioElement.currentTime;
    }

    play() {
        this.audioElement.play();
    }

    pause() {
        this.audioElement.pause();
    }

    volume(volume = null) {
        if (volume !== null) {
            this.audioElement.volume = volume;
            return volume;
        }
        return this.audioElement.volume;
    }

    type() {
        return 'hlsjs';
    }
}
