import createLocalStore from "@solid-primitives/local-store"
import { APIService } from '../../../../services/_index'
import { setSongToPlay, _playOrPause, _playSongsOnQueue, _skip, _seek, _shuffleUnshuffleActivePlayQueue } from './play-functions'
import { _addAlbumToPlaylistQueue, _addPlaylistToPlaylistQueue, _addProfileSongsToPlaylistQueue, 
    _addSongToPlaylistQueue, lazyLoadAdditionalEntitySongs
} from './playlist-functions'
import { PlayerUtils, EntityTypes } from './_index'
import { Config } from '../../../../config/base-config'
import { repeatOn, shuffleOn } from "./player-utils"
import { filterUrlToNavigateSubscriber } from "../../../../utils/filters"
import * as Utils from '../../../../utils/utils'

const [store, setStore] = createLocalStore()


// PLAYER CONTROLS
// play/pause song from player controls
export const playPauseSong = () => {
    _playOrPause()
}

// skip to next or previous
export const skip = (direction) => {
    _skip({direction: direction})
    if (window.gtag) {
        gtag('event', 'skip_song', {
            'event_category': 'Player',
            'event_label': direction
        });
    }
}

// tracker seek
export const seek = (seconds) => {
    _seek(seconds)
}

// shuffle/unshuffle active queue
export const toggleShuffle = () => {
    // toggle will stay on/off until untoggled
    // so new plays for albums/playlists/profiles will first be shuffled if shuffle is set to true
    PlayerUtils.setShuffleOn(!shuffleOn())
    _shuffleUnshuffleActivePlayQueue()
}

// Repeat 
// If theres an active play queue i.e playlist, album or profile, the queue will play through and then begin playing again. 
// If there's no active play queue, the current song will play in a loop
export const toggleRepeat = () => {
    PlayerUtils.setRepeatOn(!repeatOn())
}

// PLAY SONG
// Handles sitewide play button click funtionality
export const playItem = async ({entityType, entityId = undefined, entityJSON = undefined, 
    isFromPlayQueueDropup = false, addToBottom=true, playImmediately = true, songPlayStartSeconds = 0
}) => {
    const runAsynchronously = true
    const allowSongLazyLoad = true
    // addToBottom - On clicking play for albums,playlist and profiles should add song to bottom of playlist
    // clear queues
    clearQueues()
    // preload player data
    Utils.preloadPlayerData(!runAsynchronously)

    // play
    if(entityType === EntityTypes.Song) {
        if(!isFromPlayQueueDropup) {
            // if only playing one song, set the user's play queue to play next
            if(PlayerUtils.playQueue().length > 0) {
                const clikedSong = entityJSON.entity || entityJSON
                let playQueueSongs = PlayerUtils.playQueue()
                let songs = playQueueSongs.toReversed()
                // push cliked song to first on list
                songs.unshift(clikedSong)
                _setActiveQueueAndPlay({
                    songs: songs, 
                    playImmediately: playImmediately, 
                    songPlayStartSeconds: songPlayStartSeconds
                })
                // update itemPlayingDetails to play queue
                PlayerUtils.itemPlayingDetails.id = null
                PlayerUtils.itemPlayingDetails.type = EntityTypes.PlayQueue
            } else {
                await setSongToPlay({
                    songJSON: entityJSON, 
                    playImmediately: playImmediately, 
                    songPlayStartSeconds: songPlayStartSeconds
                })
                PlayerUtils.itemPlayingDetails.id = null
                PlayerUtils.itemPlayingDetails.type = null
            }
        } else {
            // if playing song is from play queue dropup, use function below to ensure succeeding songs on queue play afterwards       
            _playSongOnPlayQueue({
                songId: entityId, 
                playImmediately: playImmediately, 
                songPlayStartSeconds: songPlayStartSeconds
            })
            PlayerUtils.itemPlayingDetails.id = null
            PlayerUtils.itemPlayingDetails.type = null
        }
    } else {
        let entitySongs = []
        let includesAllEntitySongs = false
        if(entityType == EntityTypes.Album || entityType == EntityTypes.Profile || entityType == EntityTypes.Playlist) {
            entitySongs = entityJSON.entity
                ? entityJSON.entity.songs
                : entityJSON.songs
            includesAllEntitySongs = entityJSON.entity
                ? entityJSON.entity.all_songs_included
                : entityJSON.all_songs_included
            if(!entitySongs) entitySongs = []
            entitySongs = normalizeSongsToPlay(entitySongs)
        }

        if(entityType === EntityTypes.Album) {
            await _addAlbumToPlaylistQueue({
                albumId: entityId, entitySongs: entitySongs, includesAllEntitySongs: includesAllEntitySongs, 
                addToBottom: addToBottom, runAsynchronously: runAsynchronously, allowSongLazyLoad: allowSongLazyLoad
            })
            if(!runAsynchronously) await PlayerUtils.getPlayQueue()
            let playQueueSongs = PlayerUtils.playQueue()
            _setActiveQueueAndPlay({
                songs: playQueueSongs.toReversed(), 
                playImmediately: playImmediately, 
                songPlayStartSeconds: songPlayStartSeconds
            }) // play queue songs play from bottom to top
        }

        if(entityType === EntityTypes.Profile) {
            await _addProfileSongsToPlaylistQueue({
                profileId: entityId, 
                entitySongs: entitySongs, 
                includesAllEntitySongs: includesAllEntitySongs, 
                addToBottom: addToBottom, 
                runAsynchronously: runAsynchronously, 
                allowSongLazyLoad: allowSongLazyLoad
            })
            if(!runAsynchronously) await PlayerUtils.getPlayQueue()
            let playQueueSongs = PlayerUtils.playQueue()
            _setActiveQueueAndPlay({
                songs: playQueueSongs.toReversed(), 
                playImmediately: playImmediately, 
                songPlayStartSeconds: songPlayStartSeconds
            }) // play queue songs play from bottom to top
        }

        if(entityType === EntityTypes.Playlist) {
            await _addPlaylistToPlaylistQueue({
                playlistId: entityId, entitySongs: entitySongs, includesAllEntitySongs: includesAllEntitySongs, 
                addToBottom: addToBottom, runAsynchronously: runAsynchronously, allowSongLazyLoad: allowSongLazyLoad
            })
            if(!runAsynchronously) await PlayerUtils.getPlayQueue()
            let playQueueSongs = PlayerUtils.playQueue()
            // play queue songs play from bottom to top
            _setActiveQueueAndPlay({
                songs: playQueueSongs.toReversed(), 
                playImmediately: playImmediately, 
                songPlayStartSeconds: songPlayStartSeconds
            })
        }

        if(entityType === EntityTypes.Radio) {
            const radio_songs = await PlayerUtils.getPlayableRadioSongs()
            _setActiveQueueAndPlay({
                songs: radio_songs, 
                playImmediately: playImmediately, 
                songPlayStartSeconds: songPlayStartSeconds
            })
        }

        // update itemPlayingDetails
        PlayerUtils.itemPlayingDetails.id = entityId
        PlayerUtils.itemPlayingDetails.type = entityType
    }

    return Promise.resolve()
}

// PLAY SONG ON AN ENTITY LIST E.G SONG ON A PROFILE/ALBUM/PLAYLIST
export const playSongOnList = async ({clickedSongObject, listEntityType, listEntityId, listEntityJSON}) => {
    const runAsynchronously = true
    // clear queues
    clearQueues()
    // preload player data
    Utils.preloadPlayerData(!runAsynchronously)

    let songsOnList = []
    let clickedSongId
    let clickedSongNormalized = clickedSongObject.entity || clickedSongObject
    if(clickedSongObject) {
        clickedSongId = clickedSongNormalized.id
    }

    let listEntitySongs = []
    let includesAllEntitySongs = false
    if(listEntityType == EntityTypes.Album 
        || listEntityType == EntityTypes.Profile
        || listEntityType == EntityTypes.Playlist) {
        listEntitySongs = listEntityJSON.entity ? listEntityJSON.entity.songs : listEntityJSON.songs
        includesAllEntitySongs = listEntityJSON.entity
            ? listEntityJSON.entity.all_songs_included
            : listEntityJSON.all_songs_included
        if(!listEntitySongs) listEntitySongs = []
        listEntitySongs = normalizeSongsToPlay(listEntitySongs)
    }

    let allSongsFetched = includesAllEntitySongs
    // if there's only one song on the list entity, set the user's play queue to play next
    if(
        listEntitySongs.length <= 1
        && includesAllEntitySongs 
        && PlayerUtils.playQueue().length > 0 
        && listEntityType != EntityTypes.PlayQueue
    ) {
        let playQueueSongs = PlayerUtils.playQueue()
        songsOnList = playQueueSongs.toReversed()
        if(clickedSongNormalized) songsOnList.unshift(clickedSongNormalized)
        PlayerUtils.itemPlayingDetails.id = null
        PlayerUtils.itemPlayingDetails.type = EntityTypes.PlayQueue
    } else {
        if(listEntityType == EntityTypes.Album) {
            const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableAlbumSongs(
                listEntityId, listEntitySongs, includesAllEntitySongs, runAsynchronously
            )
            songsOnList = songs
            allSongsFetched = fetchedAllSongs
        } else if(listEntityType == EntityTypes.Profile) {
            const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableProfileSongs(
                listEntityId, listEntitySongs, includesAllEntitySongs, runAsynchronously
            )
            songsOnList = songs
            allSongsFetched = fetchedAllSongs
        } else if(listEntityType == EntityTypes.Playlist) {
            const {fetchedAllSongs, songs} = await PlayerUtils.getPlayablePlaylistSongs(
                listEntityId, listEntitySongs, includesAllEntitySongs, runAsynchronously
            )
            songsOnList = songs
            allSongsFetched = fetchedAllSongs
        }
        PlayerUtils.itemPlayingDetails.id = listEntityId
        PlayerUtils.itemPlayingDetails.type = listEntityType
    }

    if(songsOnList.length > 0) {
        if(!clickedSongNormalized) {
            clickedSongNormalized = songsOnList[0]
            clickedSongId = clickedSongNormalized.id
        }

        // find index of clicked song on the list
        let clickedSongIndexOnList
        for(let i = 0; i < songsOnList.length; i++) {
            if(songsOnList[i].id == clickedSongId) {
                clickedSongIndexOnList = i
                break
            }
        }
        // Ensure clicked song is actually on songsOnList (since allSongsFetched may be = false)
        if(!clickedSongIndexOnList && !allSongsFetched && PlayerUtils.itemPlayingDetails.type == listEntityType) {
            songsOnList.push(clickedSongNormalized)
            clickedSongIndexOnList = songsOnList.length - 1
        }

        _setActiveQueueAndPlay({
            songs: songsOnList, 
            songIndex: clickedSongIndexOnList
        })

        // lazy load more songs
        if(!allSongsFetched && PlayerUtils.itemPlayingDetails.type == listEntityType) {
            lazyLoadAdditionalEntitySongs({
                entityType: PlayerUtils.itemPlayingDetails.type,
                entityId: PlayerUtils.itemPlayingDetails.id
            })
        }
    } else if(clickedSongId) {
        await playItem({
            entityType: EntityTypes.Song, 
            entityId: clickedSongId, 
            entityJSON: clickedSongObject
        })
    }

    return Promise.resolve()
}

export const _playSongOnPlayQueue  = ({songId = undefined, playImmediately = true, songPlayStartSeconds = 0}) =>  {
    PlayerUtils.itemPlayingDetails.id = null
    PlayerUtils.itemPlayingDetails.type = EntityTypes.PlayQueue
    let playQueue = PlayerUtils.playQueue()
     // find index of song on playlist dropup queue
    let songIndex
    if(songId) {
        songIndex = 0
        for(let  i = 0; i < playQueue.length; i++) {
            let song = playQueue[i]
            if(song.id == songId) {
                songIndex = i
                break
            }
        }
    }
    _setActiveQueueAndPlay({
        songIndex: songIndex,
        songs: playQueue, 
        playImmediately: playImmediately, 
        songPlayStartSeconds: songPlayStartSeconds
    })
}

// Set list of songs we want to play to PLAYER queue and play (Not to api-managed play queue)
export const _setActiveQueueAndPlay = ({songs, songIndex = undefined, playImmediately = true, songPlayStartSeconds = 0}) => {
    PlayerUtils.setUnshuffledPlayerQueue(songs)
    PlayerUtils.setActivePlayerQueue(songs)

    _playSongsOnQueue({
        startIndex: songIndex, 
        playImmediately: playImmediately, 
        songPlayStartSeconds: songPlayStartSeconds
    })
}

// ADD TO PLAYLIST
// Handles sitewide add to playlist functionality
export const addItemToPlayQueue = async ({entityType, entityId}) => {
    if(entityType === EntityTypes.Song) {
        await _addSongToPlaylistQueue({songId: entityId})
        PlayerUtils.getPlayQueue()
    }

    if(entityType === EntityTypes.Album) {
        await _addAlbumToPlaylistQueue({
            albumId: entityId, 
            entitySongs: []
        })
        PlayerUtils.getPlayQueue()
    }

    if(entityType === EntityTypes.Profile) {
        await _addProfileSongsToPlaylistQueue({
            profileId: entityId, 
            entitySongs: []
        })
        PlayerUtils.getPlayQueue()
    }

    if(entityType === EntityTypes.Playlist) {
        await _addPlaylistToPlaylistQueue({playlistId: entityId, entitySongs: []})
        PlayerUtils.getPlayQueue()
    }

    Utils.showToast('Added to play queue', 'success', 3000)
}

export const addItemToPlaylist = async ({entityType, entityId}) => {
    PlayerUtils.setAddToPlaylistEntityId(entityId)
    PlayerUtils.setAddToPlaylistEntityType(entityType)
    PlayerUtils.setAddToPlaylistDropUpOpen(true)
}

export const addItemToPlaylistFunction = async ({playlistName, isCreatingANewPlaylist = false}) => {
    // creates new playlist or updates existing playlist
    const entityType = PlayerUtils.addToPlaylistEntityType()
    const entityId = PlayerUtils.addToPlaylistEntityId()
    let songsToSave = []
    let success = false
    
    if(entityType === EntityTypes.Song) {
      songsToSave = [{id: entityId}]
    }

    if(entityType === EntityTypes.Album) {
        const {fetchedAllSongs, songs} = await PlayerUtils.getPlayableAlbumSongs(entityId, [], false, false)
        songsToSave = songs
    }

    if(entityType === EntityTypes.Profile) {
        const {fetchedAllSongs, songs}  = await PlayerUtils.getPlayableProfileSongs(entityId, [], false, false)
        songsToSave = songs
    }

    if(entityType === EntityTypes.Playlist) {
        const {fetchedAllSongs, songs} = await PlayerUtils.getPlayablePlaylistSongs(entityId, [], false, false)
        songsToSave = songs
    }

    let songIds = []
    for (let i = 0; i < songsToSave.length; i++) {
        var songId = songsToSave[i].id
        songIds.push(songId)
    }
    if(songIds.length > 0) {
        const data = {
            name: playlistName,
            song_ids: JSON.stringify(songIds),
        }

        let response = await APIService.postRequest({url:'/api/v1/playlist/queue/save-playlist-queue-as-playlist', postData:data})
        if(response && response?.success) {
            Utils.showToast(
                `Playlist ${isCreatingANewPlaylist ? 'created' : 'updated'} successfully`, 
                "success", 
                3000
            )
            success = true
            // delete all cached data related to profile cache
            await Utils.clearCachedAPIdataForSpecificUrls(Utils.urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh())
            filterUrlToNavigateSubscriber.next({ url: `/@${response.profile_keyword}/playlists/${response.playlist_keyword}` })
         
        } else {
            Utils.showToast(response.message, "danger", 3000)
        }
    }
    return success
}


// LIKE/FAN
// Handles sitewide add to like/fan functionality
export const likeUnlikeItem = async ({entityType, entityId, fanned}) => {
    let response = await APIService.postRequest({url:'/api/v1/music/actions/like-item', postData:{
        target: entityType,
        id: entityId,
        unfan: fanned ? 't' : 'f'
    }})
    if (window.gtag) {
        if (fanned) {
            gtag('event', 'unlike', {
                'event_category': 'Player',
                'event_label': entityType,
                'entity_id': entityId,
            });
        }
        else {
            gtag('event', 'like', {
                'event_category': 'Player',
                'event_label': entityType,
                'entity_id': entityId
            });
        }
    }
    if(response && response?.success) {
        // delete all cached data related to profile cache
        Utils.clearCachedAPIdataForSpecificUrls(Utils.urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh()) 
        return true
    } else {
        return false
    }
}

// REVIEW ITEM
// Handles sitewide review functionality
export const reviewItem = async ({entityType, entityId, title, artistKeyword}) => {
    const reviewEntity = JSON.stringify({
        elementId: entityId,
        elementType: entityType,
        elementTitle: title,
        elementKeyword: artistKeyword,
    })

    setStore(
        Config.REVIEW_PAGE_FILTERS,
        reviewEntity
    )
    filterUrlToNavigateSubscriber.next({url : '/@' + artistKeyword + '/#reviews', state: reviewEntity})
    
}

const normalizeSongsToPlay = (songsToPlay) => {
    let normalizedList = []
    for (let i = 0; i < songsToPlay.length; i++) {
      const songToPlay = songsToPlay[i]
      const songObject = songToPlay.entity || songToPlay
      normalizedList.push(songObject)
    }
    return normalizedList
}

const clearQueues = () => {
    PlayerUtils.setUnshuffledPlayerQueue([])
    //PlayerUtils.setActivePlayerQueue([])
    //PlayerUtils.setActivePlayerQueueIndex(0)
}