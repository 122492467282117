import "./collapsed-player.css"
import { PlayerUtils } from '../../utils/_index'

export default () => {
  return (
    <div
    class="collapsedPlayerContainer">
      <div 
      class="songImageContainer"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        PlayerUtils.setMobilePlayerCollapsed(false)
      }}
      >
        <img
        class="songImage"
        src={PlayerUtils.songPlayingObject() ? PlayerUtils.formatSongImagePath(PlayerUtils.songPlayingObject().artwork): null}
        classList={{
          'rotate': PlayerUtils.songPlayerInstance() && !PlayerUtils.playerLoadingSong(),
          'pauseRotation': PlayerUtils.songPlayerInstance() && PlayerUtils.songPaused()
        }}
        />
      </div>
    </div>
  )
}
