import { createSignal, createMemo, onMount, createEffect } from "solid-js"
import { useLocation } from '@solidjs/router'
import { SidebarBody } from "./sidebar-body"
import { Utils } from "../../../utils/_index"
import "./sidebar.css"

export default () => {
  let sideBarRef
  const urlsToExcludeSidebar = [
    '/join', '/login', '/password-recovery', '/verify', '/not-verified', '/player/mobile-app-viz',
    '/kyc/desktop-app-sumsub-verification-page',
  ]
  const routerLocation = useLocation()
  const pathname = createMemo(() => routerLocation.pathname)
  const [lastPathName, setLastPathName] = createSignal(pathname())

  const manipulateSideBarDisplayOnPathChange = () => {
    // Hide  sidebar on some pages e.g. onboarding
    if(sideBarRef) {
      Utils.setSidebarDivRef(sideBarRef)
      // MOBILE WEB
      // Hide sidebar on mobile web. It should be always hidden on path change
      if(Utils.browserUserAgentIsMobile()) {
        Utils.hideSidebar()
      } 
      // DESKTOP WEB
      else {
        if(urlsToExcludeSidebar.some(path => pathname().includes(path))) {
          Utils.hideSidebar()
        } else {
          Utils.showSidebar()
        }
      }
    }
  }

  createEffect(() => {
    // Monitor pathname changes
    if(pathname() != lastPathName()) {
      setLastPathName(pathname())
      manipulateSideBarDisplayOnPathChange()
    }
  }, [pathname()])

  onMount(() => {
    Utils.docReady(() => {
      manipulateSideBarDisplayOnPathChange()
    })
  })

  return (
    <div ref={sideBarRef} id="sidebarParentContainer" class="sidebarParentContainer">
      <SidebarBody/>
    </div>
  )
}
