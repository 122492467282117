import {
  createSignal,
  createEffect,
  createMemo,
  onMount,
  onCleanup,
  Show,
  For,
} from 'solid-js'
import { createMutable } from 'solid-js/store'
import { Icon } from 'solid-heroicons'
import { folderOpen, home, folder, chevronDown } from 'solid-heroicons/outline' // Hero Icons

import { FiDisc, FiUsers } from 'solid-icons/fi' // Feather Icons
import {
  IoMusicalNoteOutline,
  IoDiamondOutline,
  IoRadioOutline,
  IoWalletOutline,
  IoCloudUploadOutline
} from 'solid-icons/io' // IonIcons
import {
  RiUserFacesUser6Line,
  RiDesignEditCircleLine,
  RiMediaPlayListLine,
  RiUserFacesEmotionHappyLine,
  RiMediaDiscLine,
  RiMapMapPin2Line,
  RiDeviceRssLine,
  RiMapMapLine,
  RiMapMapPin3Line,
  RiMapEarthLine,
  RiMediaMusic2Line,
  RiCommunicationMessage3Line,
  RiSystemRefreshLine,
  RiMediaGalleryLine,
  RiDocumentFileUserLine,
  RiMediaImageLine,
  RiSystemSettingsLine,
  RiSystemCloseCircleLine,
  RiSystemShieldCheckLine,
  RiUserFacesUserVoiceLine,
  RiArrowsArrowLeftRightLine,
} from 'solid-icons/ri' // Remix Icons
import { SidebarHeader } from './sidebar-header'
import { createPathMatcher, navigateToMyLibrary, setLastTimeOfMouseMovement} from '../../../utils/utils'
import createLocalStore from '@solid-primitives/local-store'
import { APIService } from '../../../services/_index'
import { Filters, Utils } from '../../../utils/_index'
import { Config } from '../../../config/base-config'
import * as ProfileUtils from '../../pages/profile/profile-utils'
import guitarIcon from '../../../assets/icons/guitar-icon.png'
import { PlayerActions, PlayerUtils, EntityTypes } from '../player/utils/_index'
import { useLocation, useNavigate } from '@solidjs/router'

export const SidebarBody = () => {
  const navigate = useNavigate()
  const routerLocation = useLocation()
  const pathname = createMemo(() => routerLocation.pathname)
  const [store, setStore] = createLocalStore()
  const selectedFilters = createMutable({
    artists: false,
    songs: false,
    albums: false,
    map: false,
    selectedMyLibraryTab: null,
    selectedProfileLibraryTab: null,
  })
  const [startingRadioPlay, setStartingRadioPlay] = createSignal(false)
  const [showProfileSidebar, setShowProfileSidebar] = createSignal(false)
  const [showSettingsSidebar, setShowSettingsSidebar] = createSignal(false)
  const [profileSidebarIsMainProfile, setProfileSidebarIsMainProfile] =
    createSignal(false)
  const [selectedProfile, setSelectedProfile] = createSignal()
  const [profileData, setProfileData] = createSignal(null)
  const [instrumentsAndGenresLocations, setInstrumentsAndGenresLocations] =
    createSignal()
  const [instrumentSearchResults, setInstrumentSearchResults] = createSignal([])
  const [genreSearchResults, setGenreSearchResults] = createSignal([])
  const [locationSearchResults, setLocationSearchResults] = createSignal([])
  let locationAutoCompleteRef, genreAutoCompleteRef, instrumentAutoCompleteRef
  const [mapNouns, setMapNouns] = createSignal([])
  const [selectedMapNouns, setSelectedMapNouns] = createSignal("")
  const [slug, setSlug] = createSignal('')

  document.addEventListener("mousemove", function(){
    setLastTimeOfMouseMovement(new Date())
  })

  const checkIfRouteMatches = (path: () => string) => {
    const matcher = createMemo(() => createPathMatcher(path()))
    return createMemo(() => matcher()(Utils.currentPath()))
  }

  const initFiltersLocalStorage = () => {
    // init ranking/time
    if (!store[Config.RANKING_TIME_FILTERS]) {
      setStore(
        Config.RANKING_TIME_FILTERS,
        JSON.stringify({ ranking: 'hottest', time: 'month' })
      ) // default values
    }

    // init location/genre/instrument store
    if (!store[Config.LOC_INSTR_GENRE_FILTERS]) {
      setStore(
        Config.LOC_INSTR_GENRE_FILTERS,
        JSON.stringify({
          locations: [],
          genres: [],
          instruments: [],
        })
      )
    }

    // init url filters storee
    setStore(Config.URL_FILTERS, JSON.stringify({}))
  }

  const subsribeToMapFiltersObservable = () => {
    Filters.mapFiltersObservable.subscribe({
      next(counts) {
        let mapNouns = []
        Object.keys(counts).forEach((key) => {
          let noun = {
            name: key,
            count: counts[key],
          }
          mapNouns.push(noun)
        })
        setMapNouns(mapNouns)
      },
      error(err) {
        console.error('something wrong occurred: ' + err)
      },
      complete() {
        // console.log('done')
      },
    })
  }

  const subsribeUrlToNavigateFiltersObservable = () => {
    Filters.filterUrlToNavigateObservable.subscribe({
      next(urlObj) {
        const url = urlObj.url
        const state = urlObj.state
        navigate(url, { replace: routerLocation.pathname == url, resolve: routerLocation.pathname != url, state: state })
      },
      error(err) {
        console.error('something wrong occurred: ' + err)
      },
      complete() {
        // console.log('done')
      },
    })
  }
  let walletCheckInterval
  
  const getUser = async () => {
    if(Utils.userLoading()){
      return
    }
    Utils.setUserLoading(true)
    await APIService.getRequest({
      url:'/api/v1/accounts/user',
      allowAPIDataCaching: true, 
      returnAPICachedData: true, 
      expiryPeriodForAPICachedData:1000 * 30, // cache for 30secs
      onSuccess: async (response) => {
        if(response){
          if(response?.success){
            setProfileData(response)
            Utils.setUserProfileData(response)
            Utils.setProfileName(response.view.name)
          }
          Utils.setUserLoading(false)
                
          //check if user has wallet
          if(response.success && !response.view.has_wallet) {
            const jwtAccessToken = await APIService.getJWTAccessToken()
            // We'll pass access token as a query parameter when initiating the websocket connection
            if (jwtAccessToken) {
              const walletWSUrl = `${Utils.socketHost()}/ws/wallet/?access_token=${jwtAccessToken}`
              const socket = new WebSocket(walletWSUrl)
              
              socket.onopen = () => {
                socket.send(JSON.stringify({
                  "get_wallet": true
                }))
              }

              socket.onmessage = async function (e) {
                const message = JSON.parse(e.data)
                if(message.success) {
                    localStorage.setItem('access_token', message.access_token)
                    localStorage.setItem('refresh_token', message.refresh_token)
                    PlayerUtils.setJamBalance({jam_price: message.jam_price, jam_per_minute: message.jam_per_minute, hbar_price: message.hbar_price})
                    PlayerUtils.setJamBalance(message.jam_balance)
                    PlayerUtils.setPendingJamAmount(message.unpaid_tokens)
                    setProfileData({...profileData(), view: {...profileData().view, has_wallet: true}})
                    Utils.setUserProfileData({...profileData(), view: {...profileData().view, has_wallet: true}})
                    await new Promise(resolve => setTimeout(resolve, 5000)) 
                    await Utils.getWalletDataAndTokenData()
                    Utils.showToast("Your wallet is created", "success", 3000)
                }
              }
            }
          } else{
            // Create an async function to use await for getting wallet and token data
            const fetchWalletAndTokenData = async () => {
              await Utils.getWalletDataAndTokenData()
            }
      
            // Call the function once before setting up the interval
            await fetchWalletAndTokenData() 
          }
        }
      }, onError: (error) => {
        // added to override the default error handling
      }
    })
    Utils.setUserLoading(false)
  }

  const profileClick = (profile, isMainProfile) => {
    setShowSettingsSidebar(false)
    if(!profile) {
      setShowProfileSidebar(false)
      setSelectedProfile()
      return
    }
    setShowProfileSidebar(true)
    setSelectedProfile(profile)
    setProfileSidebarIsMainProfile(isMainProfile)
  }

  const settingsClick = (profile) => {
    setShowProfileSidebar(false)
    setSelectedProfile(profile)
    setShowSettingsSidebar(true)
  }

  const searchLocation = (searchInput) => {
    Filters.filterSearchInputs.location = searchInput
    searchInput = searchInput.trim()
    if (searchInput.length > 0 && instrumentsAndGenresLocations()) {
      Filters.setLocationAutocompleteOpen(true)
      const locations = instrumentsAndGenresLocations().locations
      let suggestions = locations.filter((n) =>
        n.toLowerCase().startsWith(searchInput)
      )
      setLocationSearchResults(suggestions)
    } else {
      Filters.selectLocation('')
    }
  }

  const searchGenre = (searchInput) => {
    Filters.filterSearchInputs.genre = searchInput
    searchInput = searchInput.trim().toLowerCase()
    if (searchInput.length > 0) {
      Filters.setGenreAutocompleteOpen(true)

      let genres = instrumentsAndGenresLocations().genres
      let suggestions = genres.filter((n) =>
        n.toLowerCase().startsWith(searchInput)
      )
      setGenreSearchResults(suggestions)
    } else {
      Filters.selectGenre('')
    }
  }

  const searchInstrument = (searchInput) => {
    Filters.filterSearchInputs.instrument = searchInput
    searchInput = searchInput.trim().toLowerCase()
    if (searchInput.length > 0) {
      Filters.setInstrumentAutocompleteOpen(true)

      let genres = instrumentsAndGenresLocations().instruments
      let suggestions = genres.filter((n) =>
        n.toLowerCase().startsWith(searchInput)
      )
      setInstrumentSearchResults(suggestions)
    } else {
      Filters.selectInstrument('')
    }
  }

  // Dismiss Autocomplete Popups on outside click
  window.addEventListener('mouseup', (event) => {
    if (locationAutoCompleteRef)
      if (!locationAutoCompleteRef.contains(event.target)) {
        Filters.setLocationAutocompleteOpen(false)
      }

    if (genreAutoCompleteRef)
      if (!genreAutoCompleteRef.contains(event.target)) {
        Filters.setGenreAutocompleteOpen(false)
      }

    if (instrumentAutoCompleteRef)
      if (!instrumentAutoCompleteRef.contains(event.target)) {
        Filters.setInstrumentAutocompleteOpen(false)
      }
  })

  const getUserBySlug = async (slug) => {
    await APIService.getRequest({
      url:'/api/v1/accounts/user-by-slug',
      params:{ slug: slug },
      addAuthHeader:false,
      onSuccess:(profile)=>{
        if(profile.profile_is_private) {
          profileClick(null, false)
          return
        }

        if (profile.view) {
          if (
            profileData() &&
            profileData().view.keyword === profile.view.keyword
          ) {
            profileClick(profile.view, true)
          } else {
            profileClick(profile.view, false)
          }
        }
      }
  })
    
  }

  const hideShowSidebarSections = async () => {
    let currentPath = pathname()
    // if is profile page fetch profile
    if (currentPath.indexOf('my-account') != -1) {
      if (!profileData() && !Utils.userLoading()) await getUser()
      if(profileData()) settingsClick(profileData().view)
    } else if (currentPath.indexOf('/@') != -1) {
      let pathArray = currentPath.split('/')
      if (pathArray[1].substring(0, 1) == '@') {
        const userKeyword = pathArray[1].substring(1)
        // fetch only when slug changes
        if (slug() !== userKeyword) {
          setSlug(userKeyword)
          getUserBySlug(userKeyword)
        }
      } else {
        setShowSettingsSidebar(true)
      }
    } else {
      setShowProfileSidebar(false)
      setShowSettingsSidebar(false)
    }
  }

  const checkSelectedFilters = () => {
    // check if albums, artists, songs selected
    if (Utils.currentPath().includes('artists')) {
      selectedFilters.artists = true
    } else {
      selectedFilters.artists = false
    }

    if (Utils.currentPath().includes('albums')) {
      selectedFilters.albums = true
    } else {
      selectedFilters.albums = false
    }

    if (Utils.currentPath().includes('songs')) {
      selectedFilters.songs = true
    } else {
      selectedFilters.songs = false
    }

    // check if map selected
    if (Utils.currentPath().includes('map')) {
      selectedFilters.map = true
    } else {
      selectedFilters.map = false
    }
  }

  const listenForURLChanges = async () => {
    createEffect(() => {
      if(pathname()) {
        Utils.setCurrentPath(pathname())
        checkSelectedFilters()
      }
      
      if(pathname() || profileData()) {
        hideShowSidebarSections()
      }
    })
  }

  const startRadio = async () => {
    setStartingRadioPlay(true)
    await PlayerActions.playItem({entityType: EntityTypes.Radio})
    setStartingRadioPlay(false)
  }

  const getAllUrlFilters= async () => {
    const response  = await Filters.getUrlFilters()
    setInstrumentsAndGenresLocations(response)
  }

  createEffect(() => {
    if (Utils.userLoggedIn() && !profileData()) {
      getUser()
    }
  })

  onMount(async () => {
    initFiltersLocalStorage()
    // init map filter noun updates observer
    Filters.initMapFiltersObservable()
    subsribeToMapFiltersObservable()

    // init map url observer
    Filters.initFilterUrlToNavigateObservable()
    subsribeUrlToNavigateFiltersObservable()

    // init search observer
    Filters.initSearchTextObservable()
    ProfileUtils.initProfileSectionSelectionObservable()
    getAllUrlFilters()

    Utils.docReady(() => {
      listenForURLChanges()

    })
    await Utils.getAllSaucerSwapTokens()
  })

  onCleanup(() => {
    clearInterval(walletCheckInterval)
  });

  return (
    <>
      {/* Sidebar Header */}
      <SidebarHeader profileData={profileData} />

      {/* Sidebar navigation list */}
      <ul class="parentSidebarList">
        {/* Home */}
        <li class="relative">
          <a
            href={Utils.userLoggedIn() ? '/' : '/home'}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(Utils.userLoggedIn() ? '/' : '/home')
              Utils.closeSidebarMobile()
            }}
            class="sidebarListParentItem"
            data-mdb-ripple="true"
            data-mdb-ripple-color="primary"
            classList={{
              routerLinkActiveChildItem:
                (Utils.userLoggedIn() && Utils.currentPath() == '/') ||
                (!Utils.userLoggedIn() && Utils.currentPath() == '/home'),
            }}
          >
            <Icon path={home} class="text-white w-10 h-10 mr-3" />
            <span>Home</span>
          </a>
        </li>

        {/* Discover Drop Down */}
        <li class="relative mt-2" id="discoverList">
          <a
            class="sidebarListParentItem"
            data-mdb-ripple="true"
            data-mdb-ripple-color="primary"
            data-bs-toggle="collapse"
            data-bs-target="#collapseDiscoverList"
            aria-expanded="true"
            aria-controls="collapseDiscoverList"
          >
            <RiMapEarthLine class="text-white w-10 h-10 mr-3" />
            <span>Discover</span>
            <Icon
              path={chevronDown}
              aria-hidden="true"
              data-prefix="fas"
              class="w-5 h-5 ml-auto text-gray-200"
            />
          </a>

          <ul
            class="relative accordion-collapse collapse"
            classList={{
              show: !(showProfileSidebar() || showSettingsSidebar()),
            }}
            id="collapseDiscoverList"
            aria-labelledby="discoverList"
            data-bs-parent="#sidebarParentContainer"
          >
            <li class="relative">
              <div
                class="startRadioButton"
                data-bs-toggle={!Utils.userLoggedIn() ? 'modal' : ''}
                data-bs-target={!Utils.userLoggedIn() ? '#modalLogin' : '#'}
                onClick={() => {
                  if (Utils.userLoggedIn()) {
                    if (
                      PlayerUtils.songPlayingObject() &&
                      PlayerUtils.itemPlayingDetails.type == EntityTypes.Radio
                    ) {
                      PlayerActions.playPauseSong()
                    } else {
                      startRadio()
                      Utils.closeSidebarMobile()
                    }
                  }
                }}
              >
                <Show
                  when={startingRadioPlay()}
                  fallback={
                    <Show
                      when={
                        !PlayerUtils.songPaused() &&
                        PlayerUtils.songPlayingObject() &&
                        PlayerUtils.itemPlayingDetails.type == EntityTypes.Radio
                      }
                      fallback={<IoRadioOutline />}
                    >
                      <i class="ri-pause-fill"></i>
                    </Show>
                  }
                >
                  <div class="spinner spinner--small m-0"></div>
                </Show>
                <span class="shrink-0">
                  {!PlayerUtils.songPaused() &&
                  PlayerUtils.songPlayingObject() &&
                  PlayerUtils.itemPlayingDetails.type == EntityTypes.Radio
                    ? 'Playing Radio'
                    : 'Start Radio'}
                </span>
              </div>
            </li>
            <li class="relative">
              <a
                href='/drops'
                class="sidebarListChildItem"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/drops')
                  Utils.closeSidebarMobile()
                }}
                classList={{
                  routerLinkActiveChildItem: Boolean(
                    checkIfRouteMatches(() => '/drops')()
                  ),
                }}
              >
                <IoDiamondOutline class="sidebarListChildIcon" />
                <span>Collectibles</span>
              </a>
            </li>
            <Show when={Utils.userLoggedIn()}>
              <li class="relative">
                <a
                  href='/more/Favorites Feed'
                  class="sidebarListChildItem"
                  classList={{
                    routerLinkActiveChildItem:
                      Utils.currentPath() == '/more/Favorites%20Feed',
                  }}
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="primary"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    Utils.closeSidebarMobile()
                    navigate('/more/Favorites Feed')
                  }}
                >
                  <RiDeviceRssLine class="sidebarListChildIcon" />
                  <span>Feed</span>
                </a>
              </li>
            </Show>
            <li class="relative">
              <a
                href='/artists'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  Filters.setNounFilter('artists')
                  Filters.clearSearchFilterInputs()
                  Utils.closeSidebarMobile()
                }}
                class="sidebarListChildItem"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
                classList={{
                  routerLinkActiveChildItem: selectedFilters.artists && !selectedFilters.map,
                }}
              >
                <FiUsers class="sidebarListChildIcon" />
                <span>Artists</span>
              </a>
            </li>
            <li class="relative">
              <a
                href='/songs'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  Filters.setNounFilter('songs')
                  Filters.clearSearchFilterInputs()
                  Utils.closeSidebarMobile()
                }}
                class="sidebarListChildItem"
                data-mdb-ripple-color="primary"
                classList={{
                  routerLinkActiveChildItem: selectedFilters.songs,
                }}
              >
                <IoMusicalNoteOutline class="sidebarListChildIcon" />
                <span>Songs</span>
              </a>
            </li>
            <li class="relative">
              <a
                href='/albums'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  Filters.setNounFilter('albums')
                  Filters.clearSearchFilterInputs()
                  Utils.closeSidebarMobile()
                }}
                class="sidebarListChildItem"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
                classList={{
                  routerLinkActiveChildItem: selectedFilters.albums,
                }}
              >
                <FiDisc class="sidebarListChildIcon" />
                <span>Albums</span>
              </a>
            </li>
            <div class="relative">
              <a
                href='/top/playlists'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate('/top/playlists')
                  Utils.closeSidebarMobile()
                }}
                class="sidebarListChildItem"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
                classList={{
                  routerLinkActiveChildItem: Boolean(
                    checkIfRouteMatches(() => '/top/playlists')()
                  ),
                }}
              >
                <RiMediaPlayListLine class="sidebarListChildIcon" />
                <span>Playlists</span>
              </a>
            </div>

            {/* Map */}
            <li class="relative">
              <a
                href='/artists-map'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  Filters.setNounFilter('map')
                  Utils.closeSidebarMobile()
                }}
                class="sidebarListChildItem"
                data-mdb-ripple="true"
                data-mdb-ripple-color="primary"
                classList={{
                  routerLinkActiveChildItem: selectedFilters.map,
                }}
              >
                {/* <Show when={selectedFilters.map} fallback={<></>}>
                  <div class="selectedSidebarFilter" />
                </Show> */}
                <RiMapMapLine class="sidebarListChildIcon" />
                <span>Map</span>
              </a>

              {/* Map Tags */}
              <div class="sidebarTagsWrapper">
                <Show when={selectedFilters.map} fallback={<></>}>
                  <For each={mapNouns()}>
                    {(result, i) => (
                      <span
                        onClick={() => {
                          Filters.setMapFilter(result.name)
                          Utils.closeSidebarMobile()
                          Utils.setPageTitle(result.name)
                          setSelectedMapNouns(result.name)
                        }}
                        class="sidebarTags"
                        classList={{
                          selected:
                            mapNouns()[i()].name == Utils.pageTitle().toLowerCase(),
                        }}
                      >
                        {result.name}
                      </span>
                    )}
                  </For>
                </Show>
              </div>
            </li>

            {/* Location search */}
            <li class="relative mt-5">
              <div class="sidebarListChildItem sidebarInputParent">
                <RiMapMapPin3Line class="sidebarListChildIcon mb-4" />
                <div class="sidebarInputs">
                  <div>
                    <input
                      type="text"
                      placeholder="Location, country, state..."
                      autocomplete="false"
                      value={Filters.filterSearchInputs.location}
                      onInput={(e) => {
                        searchLocation(e.target.value.toLowerCase())
                      }}
                      onClick={(e) =>
                        searchLocation(e.target.value.toLowerCase())
                      }
                    />
                    <Show
                      when={
                        Filters.filterSearchInputs.location &&
                        Filters.filterSearchInputs.location != ''
                      }
                      fallback={<></>}
                    >
                      <RiSystemCloseCircleLine
                        onClick={() =>
                          Filters.searchFilterSelection(
                            Filters.selectLocation,
                            ''
                          )
                        }
                        class="sidebarInputsCloseIcon"
                      />
                    </Show>
                  </div>
                </div>
              </div>

              {/* Location autocomplete */}
              <Show
                when={Filters.locationAutocompleteOpen()}
                fallback={<></>}
              >
                <div
                  ref={locationAutoCompleteRef}
                  class="absolute z-50 w-full"
                >
                  <div class="flex justify-center flex-wrap w-full">
                    <div class="bg-zinc-800 w-full mx-6 rounded-lg pl-6 pr-3 pb-6 pt-2">
                      <ul class="h-60 overflow-y-scroll divide-y-8 divide-white">
                        <For each={locationSearchResults()}>
                          {(result, i) => (
                            <li
                              onClick={() => {
                                Filters.searchFilterSelection(
                                  Filters.selectLocation,
                                  result
                                )
                                Utils.closeSidebarMobile()
                              }}
                              class="relative cursor-pointer border-b-2 border-white"
                            >
                              <span class="text-white text-base leading-10">
                                {result}
                              </span>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </div>
                </div>
              </Show>

              {/* Tags */}
              <div class="sidebarTagsWrapper">
                <Show
                  when={instrumentsAndGenresLocations()}
                  fallback={<></>}
                >
                  <For
                    each={instrumentsAndGenresLocations().locations.slice(
                      0,
                      5
                    )}
                  >
                    {(item, i) => (
                      <span
                        onClick={() => {
                          Filters.searchFilterSelection(
                            Filters.selectLocation,
                            item
                          )
                          Utils.closeSidebarMobile()
                        }}
                        class="sidebarTags"
                        classList={{
                          selected:
                            item == Filters.filterSearchInputs.location,
                        }}
                      >
                        {item}
                      </span>
                    )}
                  </For>
                </Show>
              </div>
            </li>

            {/* Genre search */}
            <li class="relative mt-3">
              <div class="sidebarListChildItem sidebarInputParent">
                <RiMediaMusic2Line class="sidebarListChildIcon mb-4" />
                <div class="sidebarInputs">
                  <div>
                    <input
                      type="text"
                      placeholder="Genre, mood, speed, type..."
                      autocomplete="false"
                      value={Filters.filterSearchInputs.genre}
                      onInput={(e) => {
                        searchGenre(e.target.value.toLowerCase())
                      }}
                      onClick={(e) =>
                        searchGenre(e.target.value.toLowerCase())
                      }
                    />
                    <Show
                      when={
                        Filters.filterSearchInputs.genre &&
                        Filters.filterSearchInputs.genre != ''
                      }
                      fallback={<></>}
                    >
                      <RiSystemCloseCircleLine
                        onClick={() =>
                          Filters.searchFilterSelection(
                            Filters.selectGenre,
                            ''
                          )
                        }
                        class="sidebarInputsCloseIcon"
                      />
                    </Show>
                  </div>
                </div>
              </div>

              {/* Genre autocomplete */}
              <Show when={Filters.genreAutocompleteOpen()} fallback={<></>}>
                <div
                  ref={genreAutoCompleteRef}
                  class="absolute z-50 w-full"
                >
                  <div class="flex justify-center flex-wrap w-full">
                    <div class="bg-zinc-800 w-full mx-6 rounded-lg pl-6 pr-3 pb-6 pt-2">
                      <ul class="h-60 overflow-y-scroll divide-y-8 divide-white">
                        <For each={genreSearchResults()}>
                          {(result, i) => (
                            <li
                              onClick={() => {
                                Filters.searchFilterSelection(
                                  Filters.selectGenre,
                                  result
                                )
                                Utils.closeSidebarMobile()
                              }}
                              class="relative cursor-pointer border-b-2 border-white"
                            >
                              <span class="text-white text-base leading-10">
                                {result}
                              </span>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </div>
                </div>
              </Show>

              {/* Tags */}
              <div class="sidebarTagsWrapper">
                <Show
                  when={instrumentsAndGenresLocations()}
                  fallback={<></>}
                >
                  <For
                    each={instrumentsAndGenresLocations().genres.slice(
                      0,
                      5
                    )}
                  >
                    {(item, i) => (
                      <span
                        onClick={() => {
                          Filters.searchFilterSelection(
                            Filters.selectGenre,
                            item
                          )
                          Utils.closeSidebarMobile()
                        }}
                        class="sidebarTags"
                        classList={{
                          selected:
                            item == Filters.filterSearchInputs.genre,
                        }}
                      >
                        {item}
                      </span>
                    )}
                  </For>
                </Show>
              </div>
            </li>

            {/* Instrument search */}
            <li class="relative mt-3">
              <div class="sidebarListChildItem sidebarInputParent">
                <img
                  class="sidebarListChildIcon w-[1.6rem] h-[1.6rem] mb-4"
                  src={guitarIcon}
                />
                <div class="sidebarInputs">
                  <div>
                    <input
                      type="text"
                      placeholder="Instrument, production..."
                      autocomplete="false"
                      value={Filters.filterSearchInputs.instrument}
                      onInput={(e) => {
                        searchInstrument(e.target.value.toLowerCase())
                      }}
                      onClick={(e) => {
                        searchInstrument(e.target.value.toLowerCase())
                      }}
                    />
                    <Show
                      when={
                        Filters.filterSearchInputs.instrument &&
                        Filters.filterSearchInputs.instrument != ''
                      }
                      fallback={<></>}
                    >
                      <RiSystemCloseCircleLine
                        onClick={() =>
                          Filters.searchFilterSelection(
                            Filters.selectInstrument,
                            ''
                          )
                        }
                        class="sidebarInputsCloseIcon"
                      />
                    </Show>
                  </div>
                </div>
              </div>

              {/* Instrument autocomplete */}
              <Show
                when={Filters.instrumentAutocompleteOpen()}
                fallback={<></>}
              >
                <div
                  ref={instrumentAutoCompleteRef}
                  class="absolute z-50 w-full"
                >
                  <div class="flex justify-center flex-wrap w-full">
                    <div class="bg-zinc-800 w-full mx-6 rounded-lg pl-6 pr-3 pb-6 pt-2">
                      <ul class="h-60 overflow-y-scroll">
                        <For each={instrumentSearchResults()}>
                          {(result, i) => (
                            <li
                              onClick={() => {
                                Filters.searchFilterSelection(
                                  Filters.selectInstrument,
                                  result
                                )
                                Utils.closeSidebarMobile()
                              }}
                              class="relative cursor-pointer border-b-2 border-white"
                            >
                              <span class="text-white text-base leading-10">
                                {result}
                              </span>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </div>
                </div>
              </Show>

              {/* Tags */}
              <div class="sidebarTagsWrapper">
                <Show
                  when={instrumentsAndGenresLocations()}
                  fallback={<></>}
                >
                  <For
                    each={instrumentsAndGenresLocations().instruments.slice(
                      0,
                      5
                    )}
                  >
                    {(item, i) => (
                      <span
                        onClick={() => {
                          Filters.searchFilterSelection(
                            Filters.selectInstrument,
                            item
                          )
                          Utils.closeSidebarMobile()
                        }}
                        class="sidebarTags"
                        classList={{
                          selected:
                            item == Filters.filterSearchInputs.instrument,
                        }}
                      >
                        {item}
                      </span>
                    )}
                  </For>
                </Show>
              </div>
            </li>
          </ul>
        </li>

        {/* Profile/Fanmail sidebar options */}
        <Show when={selectedProfile()} fallback={<></>}>
          <li class="relative mt-4" id="profileSidebarList">
            <Show
              when={showProfileSidebar() || showSettingsSidebar()}
              fallback={<></>}
            >
              <hr class="my-2" />
            </Show>

            {/* profile options header */}

            {/* Settings menu */}
            <Show when={showSettingsSidebar()} fallback={<></>}>
              <div class="pb-0 px-6">
                <div
                  class="sidebarListParentItemProfile pt-4 pl-6"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="primary"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseProfileSidebarList"
                  aria-expanded="true"
                  aria-controls="collapseProfileSidebarList"
                >
                  <div class="flex justify-between items-center gap-3">
                    <img
                      src={selectedProfile().img_path}
                      class="sidebarProfilePhoto"
                      alt="Avatar"
                    />
                    <div class="flex flex-col justify-start items-center gap-2">
                      <p class="text-lg font-semibold text-white text-ellipsis mb-0">
                        {' '}
                        {selectedProfile().short_name}
                      </p>
                      <div class="flex justify-center items-center gap-3 text-white">
                        <i class="ri-settings-line text-base"></i>
                        <span class="text-xs">ACCOUNT</span>
                      </div>
                    </div>

                    <Icon
                      path={chevronDown}
                      aria-hidden="true"
                      data-prefix="fas"
                      class="w-5 h-5 text-gray-200"
                    />
                  </div>
                </div>
              </div>

              <ul
                class="relative accordion-collapse collapse mt-6"
                classList={{
                  show: showSettingsSidebar(),
                }}
                id="collapseProfileSidebarList"
                aria-labelledby="profileSidebarList"
                data-bs-parent="#sidebarParentContainer"
              >
                {/* Profile */}
                <li class="relative">
                  <a
                    href='/my-account/profile'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/profile`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/profile`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiDesignEditCircleLine class="sidebarListChildIcon" />
                    <span>Edit Profile</span>
                  </a>
                </li>
                {/* My music */}
                <li class="relative">
                  <a
                    href='/my-account/music'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/music`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/music`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <IoCloudUploadOutline class="sidebarListChildIcon" />
                    <span>My Music</span>
                  </a>
                </li>
                {/* Wallet */}
                <li class="relative">
                  <a
                    href='/my-account/wallet'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/wallet`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/wallet`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <IoWalletOutline class="sidebarListChildIcon" />
                    <span>Wallet</span>
                  </a>
                </li>
                {/* Buy/Sell JAM */}
                <li class="relative">
                  <a
                    href='/my-account/buy-sell-jam'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate('/my-account/buy-sell-jam')
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(
                          () => '/my-account/buy-sell-jam'
                        )()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiArrowsArrowLeftRightLine class="sidebarListChildIcon" />
                    <span>Buy/Sell JAM</span>
                  </a>
                </li>
                {/* Security */}
                <li class="relative">
                  <a
                    href='/my-account/kyc'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/kyc`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/kyc`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiSystemShieldCheckLine class="sidebarListChildIcon" />
                    <span>Security</span>
                  </a>
                </li>
                {/* Gallery */}
                <li class="relative">
                  <a
                    href='/my-account/gallery'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/gallery`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/gallery`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiMediaGalleryLine class="sidebarListChildIcon" />
                    <span>Gallery</span>
                  </a>
                </li>
                {/* Pages */}
                <li class="relative">
                  <a
                    href='/my-account/pages'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/pages`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/pages`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiDocumentFileUserLine class="sidebarListChildIcon" />
                    <span>Pages</span>
                  </a>
                </li>
                {/* Settings */}
                <li class="relative">
                  <a
                    href='/my-account/settings'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      navigate(`/my-account/settings`)
                      Utils.closeSidebarMobile()
                    }}
                    class="sidebarListChildItem"
                    classList={{
                      routerLinkActiveChildItem: Boolean(
                        checkIfRouteMatches(() => `/my-account/settings`)()
                      ),
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiSystemSettingsLine class="sidebarListChildIcon" />
                    <span>Settings</span>
                  </a>
                </li>
              </ul>
            </Show>

            {/* Selected profile */}
            <Show when={showProfileSidebar()} fallback={<></>}>
              <div class="pb-0 px-6">
                <a
                  href={`/@${selectedProfile().keyword}/#music`}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    Utils.closeSidebarMobile()
                    navigate(`/@${selectedProfile().keyword}/#music`)
                  }}
                  class="sidebarListParentItemProfile pt-4 pl-6"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="primary"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseProfileSidebarList"
                  aria-expanded="true"
                  aria-controls="collapseProfileSidebarList"
                >
                  <div class="flex items-center justify-between gap=3 mb-4">
                    <img
                      src={selectedProfile().img_path}
                      class="sidebarProfilePhoto"
                      alt="Avatar"
                    />

                    <span class="text-lg font-semibold text-white text-ellipsis">
                      {selectedProfile().short_name}
                    </span>

                    <Icon
                      path={chevronDown}
                      aria-hidden="true"
                      data-prefix="fas"
                      class="w-5 h-5 text-gray-200"
                    />
                  </div>
                </a>
              </div>

              <ul
                class="relative accordion-collapse collapse"
                classList={{
                  show: showProfileSidebar(),
                }}
                id="collapseProfileSidebarList"
                aria-labelledby="profileSidebarList"
                data-bs-parent="#sidebarParentContainer"
              >
                <Show
                  when={selectedProfile().tabs.music_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#music`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'music',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#music`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <IoMusicalNoteOutline class="sidebarListChildIcon" />
                      <span>Music</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.drops_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#drops`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'drops',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#drops`,
                      }}
                    >
                      <IoDiamondOutline class="sidebarListChildIcon" />
                      <span>Collectible Drops</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={ProfileUtils.profileSectionsVisiblity.ownedCollectibles}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/nfts`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'nfts',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/nfts`,
                      }}
                    >
                      <IoDiamondOutline class="sidebarListChildIcon" />
                      <span>Owned Collectibles</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.about_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#about`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'about',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#about`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <RiUserFacesUser6Line class="sidebarListChildIcon" />
                      <span>About</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.reviews_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#reviews`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'reviews',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#reviews`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <RiCommunicationMessage3Line class="sidebarListChildIcon" />
                      <span>Reviews</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.playlists_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#playlists`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'playlists',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#playlists`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <RiMediaPlayListLine class="sidebarListChildIcon" />
                      <span>Playlists</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.gallery_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#gallery`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'gallery',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#gallery`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <RiMediaImageLine class="sidebarListChildIcon" />
                      <span>Gallery</span>
                    </div>
                  </li>
                </Show>
                <Show
                  when={selectedProfile().tabs.activity_tab}
                  fallback={<></>}
                >
                  <li class="relative">
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/#activity`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'activity',
                          }
                        )
                        Utils.closeSidebarMobile()
                      }}
                      class="sidebarListChildItem"
                      classList={{
                        routerLinkActiveChildItem:
                          Utils.currentPath() ==
                          `/@${selectedProfile().keyword}/#activity`,
                      }}
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                    >
                      <RiSystemRefreshLine class="sidebarListChildIcon" />
                      <span>Activity</span>
                    </div>
                  </li>
                </Show>

                {/* Profile Library navigation */}
                <Show
                  when={!selectedProfile().tabs.fandlib_empty}
                  fallback={<></>}
                >
                  <li
                    class="relative"
                    id="profileLibrarySelection"
                    classList={{
                      hidden: profileSidebarIsMainProfile(),
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Utils.replaceState(
                          `/@${selectedProfile().keyword}/library/${
                            selectedProfile().tabs.default_fand_lib
                          }`
                        )
                        ProfileUtils.profileSectionSelectionSubscriber.next(
                          {
                            section: 'library',
                            record_id:
                              selectedProfile().tabs.default_fand_lib,
                          }
                        )
                      }}
                      class="sidebarListChildItem"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="primary"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseProfileLibrarySelection"
                      aria-expanded="false"
                      aria-controls="collapseProfileLibrarySelection"
                    >
                      <Icon
                        path={folderOpen}
                        class="sidebarListChildIcon"
                      />
                      <span>Library</span>
                      <Icon
                        path={chevronDown}
                        aria-hidden="true"
                        data-prefix="fas"
                        class="w-5 h-5 ml-auto"
                      />
                    </div>

                    <ul
                      class="relative accordion-collapse collapse"
                      id="collapseProfileLibrarySelection"
                      aria-labelledby="profileLibrarySelection"
                    >
                      <Show
                        when={selectedProfile().tabs.artists_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/artists`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'artists',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/artists`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <FiUsers class="sidebarListChildIcon" />
                            <span>Artists</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.albums_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/albums`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'albums',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/albums`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <FiDisc class="sidebarListChildIcon" />
                            <span>Albums</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.songs_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/songs`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'songs',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/songs`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <IoMusicalNoteOutline class="sidebarListChildIcon" />
                            <span>Songs</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.bands_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/bands`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'bands',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/bands`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <RiUserFacesUserVoiceLine class="sidebarListChildIcon" />
                            <span>Bands</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.fans_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/fans`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'fans',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/fans`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <RiUserFacesEmotionHappyLine class="sidebarListChildIcon" />
                            <span>Fans</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.labels_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/labels`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'labels',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/labels`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <RiMediaDiscLine class="sidebarListChildIcon" />
                            <span>Labels</span>
                          </div>
                        </li>
                      </Show>
                      <Show
                        when={selectedProfile().tabs.venues_tab}
                        fallback={<></>}
                      >
                        <li class="relative">
                          <div
                            onClick={() => {
                              Utils.replaceState(
                                `/@${
                                  selectedProfile().keyword
                                }/library/venues`
                              )
                              ProfileUtils.profileSectionSelectionSubscriber.next(
                                {
                                  section: 'library',
                                  record_id: 'venues',
                                }
                              )
                              Utils.closeSidebarMobile()
                            }}
                            classList={{
                              routerLinkActiveChildItem:
                                Utils.currentPath() ==
                                `/@${
                                  selectedProfile().keyword
                                }/library/venues`,
                            }}
                            class="sidebarListChildItem sidebarListChildItem2"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="primary"
                          >
                            <RiMapMapPin2Line class="sidebarListChildIcon" />
                            <span>Venues</span>
                          </div>
                        </li>
                      </Show>
                    </ul>
                  </li>
                </Show>
              </ul>
            </Show>
          </li>
        </Show>

        {/* My Library Drop Down */}
        <Show
          when={
            Utils.userLoggedIn() &&
            profileData() &&
            !profileData().view.tabs.fandlib_empty
          }
          fallback={<></>}
        >
          <li class="relative mt-2" id="myLibraryList">
            <div 
              class="sidebarListParentItem"
              data-bs-toggle="collapse"
              data-bs-target="#collapseMyLibraryList"
              aria-expanded="false"
              aria-controls="collapseMyLibraryList"
            >
              <Icon path={folder} class="text-white w-10 h-10 mr-3" />
              <span
                class="text-white"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigateToMyLibrary(
                    profileData().view.keyword,
                    profileData().view.tabs.default_fand_lib
                  )
                  Utils.closeSidebarMobile()
                }}
              >
                My Library
              </span>
              <Icon
                path={chevronDown}
                aria-hidden="true"
                data-prefix="fas"
                class="w-5 h-5 ml-auto text-gray-200"
              />
            </div>
            <ul
              class="relative accordion-collapse collapse"
              classList={{
                show: profileSidebarIsMainProfile(),
              }}
              id="collapseMyLibraryList"
              aria-labelledby="myLibraryList"
              data-bs-parent="#sidebarParentContainer"
            >
              <Show
                when={profileData().view.tabs.artists_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'artists'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/artists`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <FiUsers class="sidebarListChildIcon" />
                    <span>Artists</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.albums_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'albums'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/albums`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <FiDisc class="sidebarListChildIcon" />
                    <span>Albums</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.songs_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'songs'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/songs`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <IoMusicalNoteOutline class="sidebarListChildIcon" />
                    <span>Songs</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.bands_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'bands'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/bands`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiUserFacesUserVoiceLine class="sidebarListChildIcon" />
                    <span>Bands</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.fans_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'fans'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/fans`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiUserFacesEmotionHappyLine class="sidebarListChildIcon" />
                    <span>Fans</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.labels_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'labels'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/labels`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiMediaDiscLine class="sidebarListChildIcon" />
                    <span>Labels</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.venues_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'venues'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/venues`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiMapMapPin2Line class="sidebarListChildIcon" />
                    <span>Venues</span>
                  </div>
                </li>
              </Show>
              <Show
                when={profileData().view.tabs.playlists_tab}
                fallback={<></>}
              >
                <li class="relative">
                  <div
                    onClick={() => {
                      navigateToMyLibrary(
                        profileData().view.keyword,
                        'playlists'
                      )
                      Utils.closeSidebarMobile()
                    }}
                    classList={{
                      routerLinkActiveChildItem:
                        Utils.currentPath() ==
                        `/@${profileData().view.keyword}/library/playlists`,
                    }}
                    class="sidebarListChildItem"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="primary"
                  >
                    <RiMapMapPin2Line class="sidebarListChildIcon" />
                    <span>Playlists</span>
                  </div>
                </li>
              </Show>
            </ul>
          </li>
        </Show>
      </ul>
      {/* End Sidebar navigation list  */}
    </>
  )
}
