import { createSignal } from 'solid-js'
import { getWalletTransactions } from "../../utils";
 
export const [nextPageGt, setNextPageGt] = createSignal("0")
export const [offset, setOffset] = createSignal(0)
export const [count, setCount] = createSignal(20)
export const [txIds, setTxIds] = createSignal<string>();
export const [transactions, setTransactions] = createSignal<Transaction[]>();
export const [listensAndVideoPlays, setListensAndVideoPlays] = createSignal<ListenOrVideoPlay[]>();
export const [parsedTransactions, setParsedTransactions] = createSignal<ParsedTransaction[]>();
export const [tableData, setTableData] = createSignal<ParsedTransaction[]>();
// lengthOfPreviousTableData below used to track which transactions have been rendered before infinite scroll
export const [lengthOfPreviousTableData, setLengthOfPreviousTableData] = createSignal(0)
export const [loadingInfiniteScroll, setLoadingInfiniteScroll] = createSignal(false)
export const [showLoadMore, setShowLoadMore] = createSignal(false)
export const [transactionTypes, setTransactionTypes] = createSignal([])
export const [selectedTransactionType, setSelectedTransactionType] = createSignal('all')

export const getListens = async (accountId, requestTxType, requestTxOffset) => {
    setLoadingInfiniteScroll(true)

    let transactionsData = JSON.parse(localStorage.getItem('transactionsData')) 
    // track length of already rendered transactions to ensure they do not animate again on appending more results
    setLengthOfPreviousTableData(tableData() ? tableData().length : 0)
 
    if (transactionsData && transactionsData.nextPageGt !== nextPageGt()) {
        // use cached data
        setTableData(transactionsData.transactions);
        
        // set next page as value from &timestamp=gt:1676423793.979027130"
        setNextPageGt(transactionsData.nextPageGt)

        // cache data to local storage
        localStorage.setItem("transactionsData", JSON.stringify({transactions : transactionsData.transactions, nextPageGt: nextPageGt()}))

        setShowLoadMore(true)
    }   else {
        let response = await getWalletTransactions(accountId, nextPageGt(), requestTxType, requestTxOffset, count())
        // verify that transactionType global has not changed since request was initiated, 
        // so that we do not render the data if it has
        if(response && response?.success
            && requestTxType == selectedTransactionType() 
            && requestTxOffset == offset()) {
            await processTransactionDatawithListens(response, true, requestTxType)
        }
    }
    if(requestTxType == selectedTransactionType()) setLoadingInfiniteScroll(false)
}

const processTransactionDatawithListens = async (response, updateNextPageGt = true, resultsTransactionType)=>{   
    // Append transactions to existing transactions
    if (response.transactions.length > 0 && resultsTransactionType == selectedTransactionType()) {
        setTableData((prev) => {
            prev = prev ? prev : []
            let ids = response.transactions.map(x => x.transaction_id) 
            let transactionData = [
                ...prev.filter(x => !ids.includes(x.transaction_id)),// romove ids that already exit to resolve duplicates
                ...response.transactions,
              ] 
            return transactionData 
          })

        let combinedTransactions = [...tableData()] 
          
        if(updateNextPageGt){
            // set next page as value from &timestamp=gt:1676423793.979027130"
            setNextPageGt(combinedTransactions[combinedTransactions.length - 1].timestamp)
        }
        // cache data to local storage
        localStorage.setItem("transactionsData", JSON.stringify({transactions : combinedTransactions, nextPageGt: nextPageGt()}))
       
        setShowLoadMore(true)
    } else {
        setShowLoadMore(false)
    } 
    
}

export const getTransactionForRecentListen = async (accountId) => {
    const resultsTransactionType = selectedTransactionType()
    let response = await getWalletTransactions(accountId, "0", resultsTransactionType, 0, count())
    if(response && response?.success){
        await processTransactionDatawithListens(response, false, resultsTransactionType)
    }
}