import { Observable } from 'rxjs'
import { createMutable } from 'solid-js/store'

export let profileSectionSelectionObservable
export let profileSectionSelectionSubscriber

export const initProfileSectionSelectionObservable = () => {
    // handles profile selection events, sends these to subsriber on profile-page.tsx
    profileSectionSelectionObservable = new Observable((subscriber) => {
        profileSectionSelectionSubscriber = subscriber
    })
}

// handles visibility for some sidebar profile sections
export const profileSectionsVisiblity = createMutable({
    ownedCollectibles: false
})