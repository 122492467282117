import { onMount, onCleanup, createSignal, Show, createEffect } from "solid-js"
import "./visualizer.css"
import { PlayerUtils } from '../../utils/_index'
import { getHowler } from '../../utils/play-functions'
import { Utils } from "../../../../../utils/_index"
import butterchurn from 'butterchurn'
import { IoShuffleOutline } from "solid-icons/io"; // IonIcons

export default () => {
  let visualizerContainerRef
  let canvasRef
  let visualizer
  let _animationFrameRequest

  let audioContext: AudioContext
  let bufferSourceNode: AudioBufferSourceNode
  let delayNode: DelayNode, gainNode: GainNode, Howler
  const [currentVisualizerSongId, setCurrentVisualizerSongId] = createSignal()
  const [bufferNodePaused, setBufferNodePaused] = createSignal(true)

  const [vizWidth, setVizWidth] = createSignal(0)
  const [vizHeight, setVizHeight] = createSignal(0)
  const [presetsArray, setPresetsArray] = createSignal([])
  const [currentPresetIndex, setCurrentPresetIndex] = createSignal(0)
  const [isVisualizerRendering, setIsVisualizerRendering] = createSignal(false)
  const [presetLoaded, setPresetLoaded] = createSignal(false)
  const [shuffleVisualizerOn, setShuffleVisualizerOn] = createSignal(false)
  const [visualizerFullScreen, setVisualizerFullScreen] = createSignal(false)

  // Listen for when audio buffer data is loaded
  createEffect(() => {
    if(PlayerUtils.audioBuffer() && PlayerUtils.songPlayingObject() 
      && PlayerUtils.songPlayingObject().id != currentVisualizerSongId()) {
        const songChanged = currentVisualizerSongId() && PlayerUtils.songPlayingObject().id != currentVisualizerSongId()
        setCurrentVisualizerSongId(PlayerUtils.songPlayingObject().id)
        setTimeout(() =>{
          if(!songChanged) {
            initAudioAnalyzer()
            setBufferNodePaused(false)
            listenForPlayerEvents()
          } else {
            stopAudioAnalyzer()
            // reinitialize visualizer with new song's audio context
            initVisualizer(false)
            setBufferNodePaused(false)
          }
        }, 500)
    }
  }, [PlayerUtils.audioBuffer()])

  const listenForPlayerEvents = () => {
    // Listen for player play/pause events
    createEffect(() => {
      if(PlayerUtils.songPaused() != bufferNodePaused() && PlayerUtils.songPlayingObject() && PlayerUtils.songPlayingObject().id == currentVisualizerSongId()) {
        if(PlayerUtils.songPaused()) {
          setBufferNodePaused(true)
          stopAudioAnalyzer()
        } else {
          setBufferNodePaused(false)
          initAudioAnalyzer()
        }
      }
    }, [PlayerUtils.songPaused()])

    // Listen for player seek events
    createEffect(() => {
      if(PlayerUtils.visualizerSeekPosition()) {
        handlePlayerSeek()
      }
    }, [PlayerUtils.visualizerSeekPosition()])
  }

  

  const handlePlayerSeek = () => {
    // timeout to allow seek to complete
    setTimeout(() => {
      if(PlayerUtils.songPlayerInstance().playing()) {
        // stop audio analyzer
        stopAudioAnalyzer()
        // restart audio analyzer at current song position
        initAudioAnalyzer()
      } else {
        handlePlayerSeek()
      }
    }, 1500)
  }

  const stopAudioAnalyzer = () => {
    // Stop audio playback
    visualizer.disconnectAudio(delayNode)
    bufferSourceNode.stop()
  }

  const initAudioAnalyzer = (connectVisualizer = true) => {
    if(PlayerUtils.audioBuffer()) {
      if(bufferSourceNode && bufferSourceNode.numberOfOutputs !== 0) {
        bufferSourceNode.disconnect()
      }
      // Create a new AudioBufferSourceNode and connect it to the destination
      bufferSourceNode = audioContext.createBufferSource()
      bufferSourceNode.buffer = PlayerUtils.audioBuffer()
      
      // Create delay node
      if(delayNode && delayNode.numberOfOutputs !== 0) {
        delayNode.disconnect()
      }
      delayNode = audioContext.createDelay()
      delayNode.delayTime.value = 0.0

      // Create a GainNode for controlling volume
      // if(gainNode && gainNode.numberOfOutputs !== 0) {
      //   gainNode.disconnect()
      // }
      // gainNode = audioContext.createGain()
      // // Set gain to 0 to mute the audio on bufferSourceNode
      // gainNode.gain.value = 0

      // Connect the nodes in series: sourceNode -> gainNode -> delayNode -> audio context destination
      bufferSourceNode.connect(delayNode)
      // gainNode.connect(delayNode)
      // delayNode.connect(audioContext.destination) //commented so as not to connect audio to speakers
      
      if(connectVisualizer) {
        connectVisualizerToAudioAnalyzer()
      }
    }
  }

  const connectVisualizerToAudioAnalyzer = () => {
    // get audioNode from audio source or microphone
    visualizer.connectAudio(delayNode)
            
    // Start visualizer/bufferSourceNode from current song position
    bufferSourceNode.start(0, PlayerUtils.songProgress())
  }

  const initVisualizer = (isFirstLoad = true) => {
    Howler = getHowler()
    audioContext = Howler.ctx || new (window.AudioContext || window.webkitAudioContext)()

    canvasRef.width = visualizerContainerRef.clientWidth
    canvasRef.height = visualizerContainerRef.clientHeight

    visualizer = butterchurn.createVisualizer(audioContext, canvasRef, {
      width: visualizerContainerRef.clientWidth,
      height: visualizerContainerRef.clientHeight,
      pixelRatio: window.devicePixelRatio || 1,
      textureRatio: 1,
      meshWidth: 48,
      meshHeight: 36,
      fps: 2
    })

    // render visualizer
    renderVisualizer()

    // load initial visualizer preset
    if(!isFirstLoad && presetsArray().length > 0) {
      const selectedPreset = presetsArray()[currentPresetIndex()]
      loadPreset(selectedPreset)
    } else {
      loadNextPreset(isFirstLoad)
    }

    // show song title animation
    if(PlayerUtils.songPlayingObject()) {
      visualizer.launchSongTitleAnim(PlayerUtils.songPlayingObject().title)
    }

    // init audio analyzer
    initAudioAnalyzer()
  }

  const renderVisualizer = () => {
    setIsVisualizerRendering(true)
    if (PlayerUtils.songPlayerInstance() && PlayerUtils.songPlayerInstance().playing()) {
      visualizer.render()
    }
    _animationFrameRequest = window.requestAnimationFrame(renderVisualizer)
  }

  const pause = () => {
    if (_animationFrameRequest) {
      window.cancelAnimationFrame(_animationFrameRequest)
      _animationFrameRequest = null
    }
  }

  const loadNextPreset = (isFirstLoad = false) => {
    if(isFirstLoad && presetLoaded()) {
      return
    }

    if(visualizer) {
      if (presetsArray().length > 0 && currentPresetIndex() < presetsArray().length - 1) {
        // fetch next in array
        if(!isFirstLoad) setCurrentPresetIndex(currentPresetIndex() + 1)
        let preset = presetsArray()[currentPresetIndex()]
        loadPreset(preset)
      }
    } else {
      setTimeout(() =>{
        loadNextPreset()
      }, 500)
    }
  }

  const loadPreviousPreset = () => {
    if (visualizer && presetsArray().length > 1 && currentPresetIndex() > 0) {
      // fetch next in array
      setCurrentPresetIndex(currentPresetIndex() -  1)
      const preset = presetsArray()[currentPresetIndex()]
      loadPreset(preset)
    }
  }

  const loadPreset = (preset) =>  {
    pause()
    // load preset
    let blendTime = 2
    visualizer.loadPreset(preset.item, blendTime)
    resizeVisualizer()
    renderVisualizer()
    setPresetLoaded(true)
  }

  const resizeVisualizer = () => {
    if (visualizer) {
      const width = visualizerContainerRef.clientWidth
      const height = visualizerContainerRef.clientHeight

      canvasRef.width = width
      canvasRef.height = height

      if (vizWidth() !== width || vizHeight() !== height) {
        visualizer.setRendererSize(width, height)
        setVizWidth(width)
        setVizHeight(height)
      }
    }
  }

  const shuffleVisualizer = () => {
    setShuffleVisualizerOn(true)
    shufflePresets()
  }

  const shufflePresets = () => {
    if (shuffleVisualizerOn()) {
      // const preset = randomPreset()
      // Ensure only unique presets are shown and only repeats when all unique presets have been shown
      if(presetsArray().length > 0) {
        if (currentPresetIndex() < presetsArray().length - 1) {
          setCurrentPresetIndex(currentPresetIndex() +  1)
        } else {
          setCurrentPresetIndex(0)
        }

        const preset = presetsArray()[currentPresetIndex()]
        loadPreset(preset)

        setTimeout(() => {
          shufflePresets()
        }, 10000) // shuffle every ten seconds
      }
    }
  }

  const unShuffleVisualizer = () => {
    setShuffleVisualizerOn(false)
  }

  const openFullscreen = () => {
    if (visualizerContainerRef.requestFullscreen) {
      visualizerContainerRef.requestFullscreen()
    } else if (visualizerContainerRef.webkitRequestFullscreen) { /* Safari */
      visualizerContainerRef.webkitRequestFullscreen()
    } else if (visualizerContainerRef.msRequestFullscreen) { /* IE11 */
      visualizerContainerRef.msRequestFullscreen()
    }
  }

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen()
    }
  }

  onMount(() => {
    setPresetsArray(Utils.getAllVisualizerPresetsAsArray())
    
    Utils.docReady(()=> {
      initVisualizer()

      const windowResizeObserver = new ResizeObserver(entries => {
        resizeVisualizer()
      })
      windowResizeObserver.observe(document.body)

      let mo = new MutationObserver(() => {
        PlayerUtils.resizePage(!PlayerUtils.visualizerOpen())
      })
      mo.observe(visualizerContainerRef, { subtree: true, childList: true })
    })
  })

  onCleanup(() => {
    pause()
  })

  return (
    <div ref={visualizerContainerRef} class="visualizerContainer">

      <canvas ref={canvasRef}/>

      <div class="visualizerControls">

        <Show when={shuffleVisualizerOn()} fallback={
          <>
            <Show when={currentPresetIndex() > 0}>
            <div
            class="visualizerControlButton"
            onClick={() => {
              if(PlayerUtils.songPlayerInstance()) {
                loadPreviousPreset()
              }
            }}
            >
              <i class="ri-arrow-left-s-line"/>
            </div>
              
            </Show>
            <div
            class="visualizerControlButton"
            onClick={() => {
              if(PlayerUtils.songPlayerInstance()) {
                loadNextPreset()
              }
            }}
            >
              <i class="ri-arrow-right-s-line"/>
            </div>

            <div
            class="visualizerControlButton"
            onClick={() => shuffleVisualizer()}
            >
              <IoShuffleOutline/>
            </div>
          </>
        }>
          <div
          class="visualizerControlButton !bg-white"
          onClick={() => unShuffleVisualizer()}
          >
            <IoShuffleOutline class="!text-[#ff0057]"/>
          </div>
        </Show>

        <Show when={!visualizerFullScreen()} fallback={
          <div
          class="visualizerControlButton"
          onClick={() => {
            closeFullscreen()
            setVisualizerFullScreen(false)
          }}
          >
            <i class="ri-fullscreen-exit-line"/>
          </div>
        }>
          <div
          class="visualizerControlButton"
          onClick={() => {
            openFullscreen()
            setVisualizerFullScreen(true)
          }}
          >
            <i class="ri-fullscreen-line"/>
          </div>
        </Show>

        <div
        class="visualizerControlButton"
        onClick={() => {
          if(PlayerUtils.songPlayerInstance()) {
            PlayerUtils.setVisualizerOpen(false)
            PlayerUtils.resizePage(true)
          }
        }}
        >
          <i class="ri-close-line"/>
        </div>
      </div>
    </div>
  )
}
