export const get_file_type = (file) => {
    if (file.type !== ''){
      return file.type;
    } else {
      /* Match for a period and three or four characters at the end of the string */
      var extension = file.name.match(/\.....?$/g);
      if (extension.length !== 0){
        return extension[0].substring(1);
      } else {
        return 'UNKNOWN TYPE'; }
    }
}


  export const get_file_size = (file) => {
    /* Given a file, return its size in MB as a string */
    return (file.size / Math.pow(10, 6)).toFixed(2) + 'MB';
  }