import {
  Hbar,
  TokenAssociateTransaction,
  TransferTransaction,
} from '@hashgraph/sdk'
import { HEDERA_MIRRORNODE, HASHPACK_NETWORK } from '../../../../utils/Constants'
import { APIService } from '../../../../services/_index'
import { CID } from 'multiformats/cid'
import { getDataFromLocalStorageWithExpiry, saveToLocalStorageWithExpiry } from '../../../../utils/utils'
const HEDERA_NODE = HEDERA_MIRRORNODE

// This function uses Hedera Mirror Node API to get the details of the account
export const getHederaAccountDetails = async (accountId: string) => {
  const response = await fetch(
    `https://${HEDERA_NODE}.mirrornode.hedera.com/api/v1/accounts/${accountId}`
  )
  const data = await response.json()

  const HBAR = {
    token_id: 'Hedera',
    balance: data.balance.balance,
    name: 'HBAR',
    type: 'FUNGIBLE_COMMON',
    symbol: 'HBAR',
    decimals: 8,
  }
  let { tokens } = data.balance
  // filter WHBAR from tokens
  //tokens = tokens.filter(x=> x.token_id !== "0.0.1456986")
  tokens = [HBAR, ...tokens]
  data.balance.tokens = tokens

  return data
}

// This function uses Hedera Mirror Node API to get all token details
export const getTokenDetails = async (tokenId: string) => {

  if (tokenId == 'Hedera') {
    return {
      token_id: 'Hedera',
      name: 'Hedera',
      type: 'FUNGIBLE_COMMON',
      symbol: 'HBAR',
      decimals: 8,
    }
  } else if (tokenId === '0.0.1456986') {
    let data = getDataFromLocalStorageWithExpiry('token-0.0.1456986')
    if(data){
      return data
    }
    const res = await fetch(
      `https://mainnet-public.mirrornode.hedera.com/api/v1/tokens/0.0.1456986`
    )
    data = await res.json()
    data.name = 'WHBAR'
    if (res.status === 200) {
      saveToLocalStorageWithExpiry('token-0.0.1456986', data, 1000*60*60*24)
    }
    return data
    
  }
  let data = getDataFromLocalStorageWithExpiry(`token-${tokenId}`)
  if(data){
    return data
  }
  const response = await fetch(
    `https://${HEDERA_NODE}.mirrornode.hedera.com/api/v1/tokens/${tokenId}`
  )
  data = await response.json()
  if (response.status === 200) {
    saveToLocalStorageWithExpiry(`token-${tokenId}`, data, 1000*60*60*24) 
  }
  return data
}

// This function get all wallet transactions for an account from django backend
export const getWalletTransactions = async (
  accountId: string,
  gt: string,
  type: string,
  offset,
  count
): Promise<ParsedTransactionLogData> => {
  const data = {
    account_id: accountId,
    start_timestamp: gt,
    type: type,
    offset: offset,
    count: count
  }
  return await APIService.postRequest({
    url:'/api/v1/wallet/wallet-transactions',
    postData:data
  })
}

export const transferFromTune = async (
  to,
  serial_or_amount,
  token,
  memo,
  twofa,
  password,
  isNFT
) => {
  let data = {
    to_account: to,
    password: password,
    memo: memo,
    token_id: token,
    twofa: twofa ? twofa : null,
  }

  if (isNFT) {
    data['serial_number'] = serial_or_amount
  } else {
    data['amount'] = serial_or_amount
  }
  return await APIService.postRequest({url:'/api/v1/wallet/send-token', postData:data,  displayErrorMessage : true})
}

export const transferFromHashpack = async (
  hashconnect,
  topic,
  from,
  to,
  amount,
  token,
  decimals: number
) => {
  const value: number = amount * 10 ** decimals
  let provider = hashconnect.getProvider(
    HASHPACK_NETWORK,
    topic,
    from
  )
  let signer = hashconnect.getSigner(provider)
  let transaction
  if (token === 'Hedera') {
    transaction = await new TransferTransaction()
      .addHbarTransfer(from, Hbar.fromTinybars(-value))
      .addHbarTransfer(to, Hbar.fromTinybars(value))
      .freezeWithSigner(signer)
  } else {
    transaction = await new TransferTransaction()
      .addTokenTransfer(token, from, -value)
      .addTokenTransfer(token, to, value)
      .freezeWithSigner(signer)
  }
  let transactionData = {
    topic: topic,
    byteArray: transaction.toBytes(),
    metadata: {
      accountToSign: from,
      returnTransaction: false,
    },
  }
  await hashconnect.sendTransaction(topic, transactionData)
}

export const associateTokenToHashpack = async (
  hashconnect,
  topic,
  from,
  tokenId
) => {
  let provider = hashconnect.getProvider(
    HASHPACK_NETWORK,
    topic,
    from
  )
  let signer = hashconnect.getSigner(provider)
  let transaction = await new TokenAssociateTransaction()
    .setAccountId(from)
    .setTokenIds([tokenId])
    .freezeWithSigner(signer)
  let res = await transaction.executeWithSigner(signer)
 
}

export const getIpfsData = async (ipfsHash: string) => {
  if (!ipfsHash) {
    return null
  }
  const url = `https://${ipfsHash}.ipfs.nftstorage.link`
  const response = await fetch(url)
  const data = await response.json()
  return data
}

const stripIpfsHash = (ipfsHash: string) => {
  const prefixes = ['ipfs://', 'https://ipfs.io', 'https://nftstorage.link'];
  const prefix = prefixes.find(p => ipfsHash.startsWith(p));
  const strippedHash = prefix ? ipfsHash.substring(prefix.length) : ipfsHash;
  // Convert hash to CID
  try {
    const cid = CID.parse(strippedHash).toV1().toString();
    return cid;
  } catch (error) {
    console.error("Error converting IPFS hash to CID v1:", error);
    return null;
  }
}

export const convertIpfsHashToCloudflare = (ipfsHash: string) => {
  // Check if actually is IPFS hash
  const strippedHash = stripIpfsHash(ipfsHash)
  if (!strippedHash.startsWith('Qm') && !strippedHash.startsWith('baf')) {
    // It is not an IPFS hash, return the original hash
    return ipfsHash
  }
  return `https://${strippedHash}.ipfs.nftstorage.link`
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

// This function uses Hedera Mirror Node API to get all NFT details
export const getHederaAccountNftsDetails = async (
  accountId: string,
  pageTokenId: string,
  pageSerial: string
) => {
  let url = `https://${HEDERA_NODE}.mirrornode.hedera.com/api/v1/accounts/${accountId}/nfts?limit=5`
  if (pageTokenId != '0' && pageSerial != '0') {
    url += `&token.id=lte:${pageTokenId}&serialnumber=lt:${pageSerial}`
  }
  const response = await fetch(url)
  const data = await response.json()
  const nfts = data.nfts
  // Loop through all the NFTs and get the metadata
  for (let nft of nfts) {
    // We have the base64 encoded metadata now, decode it and retrieve the metadata
    if (nft.metadata !== null && nft.metadata.length < 1000) {
      await delay(500)
      const metadata = Buffer.from(nft.metadata, 'base64').toString('utf8')
      // If the metadata is IPFS URL, fetch the data from IPFS and add it to the NFT object
      nft.metadata = await getIpfsData(stripIpfsHash(metadata))
    }
  }
  // return both nfts and is there more data to fetch as bool
  return [nfts, data.links.next !== null]
}

export const getBackendNftsDetails = async (tokenIds: string[]) => {
  let url = '/api/v1/nft/token-details'
  let data = {
    token_ids: tokenIds.join(','),
  }
  return await APIService.getRequest({url, params:data})
}

export const calculateJamUsdPrice = (jamRate, amount) => {
  const value =
    Math.round(((amount / 100000000) * jamRate + Number.EPSILON) * 100) / 100
  return value
}

export const getArrayUserNFTSerialNumbersPerToken = async (
  token_id,
  account_id
) => {
  let response = await APIService.getRequest({
    url:'/api/v1/nft/account-id-nft-serial-numbers',
    params:{
      token_id: token_id,
      account_id: account_id,
    }
  })
  if (response?.success) {
    return response.serial_numbers
  } else {
    return []
  } 
}

export const checkTokenIsNFT = (token_id, tokenData) => {
  if (tokenData[token_id] && (tokenData[token_id].type === 'NON_FUNGIBLE_UNIQUE')) {
    return true
  }
  return false
}

export const TokenImages = {
  '0.0.456858': 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
  '0.0.731861': 'https://www.saucerswap.finance/images/tokens/sauce.svg',
  '0.0.1460200':
    'https://s2.coinmarketcap.com/static/img/coins/64x64/23069.png',
  '0.0.968069': 'https://s2.coinmarketcap.com/static/img/coins/64x64/21019.png',
  '0.0.624505': 'https://s2.coinmarketcap.com/static/img/coins/64x64/2110.png',
  '0.0.751086':
    'https://static.wixstatic.com/media/71f077_67020b457e5b45199e3a9eedc443cf97~mv2.png/v1/fill/w_51,h_58,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/shibar_center_page.png',
  '0.0.209368': 'https://s2.coinmarketcap.com/static/img/coins/64x64/12968.png',
  '0.0.786931': 'https://s2.coinmarketcap.com/static/img/coins/64x64/24790.png',
  '0.0.834116': 'https://s2.coinmarketcap.com/static/img/coins/64x64/23185.png',
  '0.0.859814':
    'https://img.api.cryptorank.io/coins/150x150.calaxy1666266991808.png',
}

