import { type } from 'os';
import videojs from 'video.js';

export default class VideoJSAdapter {
    private player: any;
    private elementId: string;
    audioElement: HTMLAudioElement;

    constructor(playImmediately = true) {
        this.audioElement = this.createAudioElement();
        this.player = videojs(this.audioElement, {
            autoplay: playImmediately,
            audioOnlyMode: true,
            controls: false,
            preload: 'metadata',
            withCredentials: true,
        });
    }

    createAudioElement() {
        let audioElement = document.querySelector('#videojsAudioElement');
        if (!audioElement) {
            audioElement = document.createElement('audio');
            audioElement.id = 'videojsAudioElement';
            document.body.appendChild(audioElement);
        }
        return audioElement as HTMLAudioElement;
    }

    setSrc(url: string, type: string = 'audio/mp3') {
        this.player.src({ type: type, src: url });
    }

    play() {
        return this.player.play();
    }

    pause() {
        this.player.pause();
    }

    on(event: string, handler: () => void) {
        this.player.on(event, handler);
    }

    off(event: string, handler: () => void) {
        this.player.off(event, handler);
    }

    duration() {
        return this.player.duration();
    }

    unload() {
        this.player.dispose();
        const elem = document.getElementById(this.elementId);
        if (elem && elem.parentNode) {
            elem.parentNode.removeChild(elem);
        }
    }

    seek(timeInSeconds = null) {
        if (timeInSeconds !== null) {
            this.player.currentTime(timeInSeconds);
            return timeInSeconds;
        }
        return this.player.currentTime();
    }

    volume(volume = null) {
        if (volume !== null) {
            this.player.volume(volume);
            return volume;
        }
        return this.player.volume();
    }

    mute(shouldMute) {
        this.player.muted(shouldMute);
    }

    isMuted() {
        return this.player.muted()
    }

    type() {
        return 'videojs';
    }

    playing() {
        return !this.player.paused();
    }
}