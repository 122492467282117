// gradients src - https://uigradients.com/
export let listingCardsColorArray = [
    // OLD GRADIENTS
    //
    // { name: 'Sin City Red', bgGradient: 'linear-gradient(to right, #93291E, #ED213A)'},
    // { name: 'Moonlit Asteroid - Bluish', bgGradient: 'linear-gradient(to right, #2C5364, #203A43, #0F2027)'},
    // { name: 'Frost - Bluish', bgGradient: 'linear-gradient(to right, #004e92, #000428)'},
    // { name: 'Vine - Greenish', bgGradient: 'linear-gradient(to right, #001510, #00bf8f)'},
    // { name: 'Pinot Noir - Bluish', bgGradient: 'linear-gradient(to right, #182848, #4b6cb7)'},
    // { name: 'Burning Orange', bgGradient: 'linear-gradient(to right, #FF4B2B, #FF416C)'},
    // // { name: 'Terminal - Greenish', bgGradient: 'linear-gradient(to right, #0f9b0f, #000000)'},
    // { name: '50 Shades of Grey', bgGradient: 'linear-gradient(to right, #bdc3c7, #2c3e50)'},
    // { name: 'Royal - Bluish', bgGradient: 'linear-gradient(to right, #141e30, #243b55)'},
    // { name: 'Shifty - Greenish', bgGradient: 'linear-gradient(to right, #636363, #a2ab58)'},
    // { name: 'Pure Lust - Reddish', bgGradient: 'linear-gradient(to right, #333333, #dd1818)'},
    // { name: 'Lawrencium - Bluish', bgGradient: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)'},
    // { name: '80\'s Purple', bgGradient: 'linear-gradient(to right, #41295a, #2f0743)'},
    // { name: 'Mauve - Purplish', bgGradient: 'linear-gradient(to right, #42275a, #734b6d)'},
    // { name: 'Night Hawk - Bluish', bgGradient: 'linear-gradient(to right, #2980b9, #2c3e50)'},
    // { name: 'Netflix - Reddish', bgGradient: 'linear-gradient(to right, #8e0e00, #1f1c18)'},
    // { name: 'Namn - Maroonish', bgGradient: 'linear-gradient(to right, #a73737, #7a2828)'},
    // { name: 'Midnight City - Greyish', bgGradient: 'linear-gradient(to right, #232526, #414345)'},
    // { name: 'Mirage', bgGradient: 'linear-gradient(to right, #16222a, #3a6073)'},
    //
    // END OLD GRADIENTS
    //
    // NEW GRADIENTS
    //
    { name: 'Blush', bgGradient: 'linear-gradient(to right, #b24592, #f15f79)'},
    { name: 'Back to the future', bgGradient: 'linear-gradient(to right, #c02425, #f0cb35)'},
    { name: 'Green and blue', bgGradient: 'linear-gradient(to right, #c2e59c, #64b3f4)'},
    { name: 'Back to earth', bgGradient: 'linear-gradient(to right, #00c9ff, #92fe9d)'},
    { name: 'Clear sky', bgGradient: 'linear-gradient(to right, #005c97, #363795)'},
    { name: 'timber', bgGradient: 'linear-gradient(to right, #fc00ff, #00dbde)'},
    { name: 'fresh turboscent', bgGradient: 'linear-gradient(to right, #f1f2b5, #135058)'},
    { name: 'virgin america', bgGradient: 'linear-gradient(to right, #7b4397, #dc2430)'},
    { name: 'turquoise flow', bgGradient: 'linear-gradient(to right, #136a8a, #267871)'},
    { name: 'instagram', bgGradient: 'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)'},
    { name: 'atlas', bgGradient: 'linear-gradient(to right, #feac5e, #c779d0, #4bc0c8)'},
    { name: 'endless river', bgGradient: 'linear-gradient(to right, #43cea2, #185a9d)'},
    { name: 'predawn', bgGradient: 'linear-gradient(to right, #ffa17f, #00223e)'},
    { name: 'purple bliss', bgGradient: 'linear-gradient(to right, #360033, #0b8793)'},
    { name: 'crazy orange I', bgGradient: 'linear-gradient(to right, #d38312, #a83279)'},
    { name: 'between the clouds', bgGradient: 'linear-gradient(to right, #73c8a9, #373b44)'},
    { name: 'shore', bgGradient: 'linear-gradient(to right, #70e1f5, #ffd194)'},
    { name: 'cheer up emo kid', bgGradient: 'linear-gradient(to right, #556270, #ff6b6b)'},
    { name: 'amethyst', bgGradient: 'linear-gradient(to right, #9d50bb, #6e48aa)'},
    { name: 'man of steel', bgGradient: 'linear-gradient(to right, #780206, #061161)'},
    { name: 'neon life', bgGradient: 'linear-gradient(to right, #b3ffab, #12fff7)'},
    { name: 'starfall', bgGradient: 'linear-gradient(to right, #f0c27b, #4b1248)'},
    { name: 'dance to forget', bgGradient: 'linear-gradient(to right, #ff4e50, #f9d423)'},
    { name: 'virgin', bgGradient: 'linear-gradient(to right, #c9ffbf, #ffafbd)'},
    { name: 'dirty fog', bgGradient: 'linear-gradient(to right, #b993d6, #8ca6db)'},
    { name: 'candy', bgGradient: 'linear-gradient(to right, #d3959b, #bfe6ba)'},
    { name: 'autumn', bgGradient: 'linear-gradient(to right, #dad299, #b0dab9)'},
    { name: 'nelson', bgGradient: 'linear-gradient(to right, #f2709c, #ff9472)'},
    { name: 'kyoto', bgGradient: 'linear-gradient(to right, #c21500, #ffc500)'},
    { name: 'sirius tamed', bgGradient: 'linear-gradient(to right, #efefbb, #d4d3dd)'},
    { name: 'blurry beach', bgGradient: 'linear-gradient(to right, #d53369, #cbad6d)'},
    { name: 'day tripper', bgGradient: 'linear-gradient(to right, #f857a6, #ff5858)'},
    { name: 'miaka', bgGradient: 'linear-gradient(to right, #fc354c, #0abfbc)'},
    { name: 'influenza', bgGradient: 'linear-gradient(to right, #c04848, #480048)'},
    { name: 'calm darya', bgGradient: 'linear-gradient(to right, #5f2c82, #49a09d)'},
    { name: 'moonrise', bgGradient: 'linear-gradient(to right, #dae2f8, #d6a4a4)'},
    { name: 'peach', bgGradient: 'linear-gradient(to right, #ed4264, #ffedbc)'},
    { name: 'dracula', bgGradient: 'linear-gradient(to right, #dc2424, #4a569d)'},
    { name: 'opa', bgGradient: 'linear-gradient(to right, #3d7eaa, #ffe47a)'},
    { name: 'sea blizz', bgGradient: 'linear-gradient(to right, #1cd8d2, #93edc7)'},
    { name: 'shroom haze', bgGradient: 'linear-gradient(to right, #5c258d, #4389a2)'},
    { name: 'moss', bgGradient: 'linear-gradient(to right, #134e5e, #71b280)'},
    { name: 'bora bora', bgGradient: 'linear-gradient(to right, #2bc0e4, #eaecc6)'},
    { name: 'venice blue', bgGradient: 'linear-gradient(to right, #085078, #85d8ce)'},
    { name: 'electric violet', bgGradient: 'linear-gradient(to right, #4776e6, #8e54e9)'},
    { name: 'kashmir', bgGradient: 'linear-gradient(to right, #614385, #516395)'},
    { name: 'pinky', bgGradient: 'linear-gradient(to right, #dd5e89, #f7bb97)'},
    { name: 'stripe', bgGradient: 'linear-gradient(to right, #1fa2ff, #12d8fa, #a6ffcb)'},
    { name: 'aqua marine', bgGradient: 'linear-gradient(to right, #1a2980, #26d0ce)'},
    { name: 'bloody mary', bgGradient: 'linear-gradient(to left, #ff512f, #dd2476)'},
    { name: 'rose water', bgGradient: 'linear-gradient(to left, #e55d87, #5fc3e4)'},
    { name: 'horizon', bgGradient: 'linear-gradient(to left, #003973, #e5e5be)'},
    { name: 'monte carlo', bgGradient: 'linear-gradient(to left, #cc95c0, #dbd4b4, #7aa1d2)'},
    { name: 'emerald water', bgGradient: 'linear-gradient(to left, #348f50, #56b4d3)'},
    { name: 'harmonic energy', bgGradient: 'linear-gradient(to left, #16a085, #f4d03f)'},
    { name: 'noon to dusk', bgGradient: 'linear-gradient(to left, #ff6e7f, #bfe9ff)'},
    { name: 'hazel', bgGradient: 'linear-gradient(to left, #77a1d3, #79cbca, #e684ae)'},
    { name: 'sea blue', bgGradient: 'linear-gradient(to left, #2b5876, #4e4376)'},
    { name: 'purple love', bgGradient: 'linear-gradient(to left, #cc2b5e, #753a88)'},
    { name: 'sel', bgGradient: 'linear-gradient(to left, #00467f, #a5cc82)'},
    { name: 'anamnisar', bgGradient: 'linear-gradient(to left, #9796f0, #fbc7d4)'},
    { name: 'windy', bgGradient: 'linear-gradient(to left, #acb6e5, #86fde8)'},
    { name: 'megatron', bgGradient: 'linear-gradient(to left, #c6ffdd, #fbd786, #f7797d)'},
    { name: 'JShine', bgGradient: 'linear-gradient(to left, #12c2e9, #c471ed, #f64f59)'},
    { name: 'evening sunshine', bgGradient: 'linear-gradient(to left, #b92b27, #1565c0)'},
    { name: 'cool sky', bgGradient: 'linear-gradient(to left, #2980b9, #6dd5fa, #ffffff)'},
    { name: 'memariani', bgGradient: 'linear-gradient(to left, #aa4b6b, #6b6b83, #3b8d99)'},
    { name: 'azur lane', bgGradient: 'linear-gradient(to left, #7f7fd5, #86a8e7, #91eae4)'},
    { name: 'metapolis', bgGradient: 'linear-gradient(to left, #659999, #f4791f)'},
    { name: 'kyoo pal', bgGradient: 'linear-gradient(to left, #dd3e54, #6be585)'},
    { name: 'kye meh', bgGradient: 'linear-gradient(to left, #8360c3, #2ebf91)'},
    { name: 'by design', bgGradient: 'linear-gradient(to left, #009fff, #ec2f4b)'},
    { name: 'red sunset', bgGradient: 'linear-gradient(to left, #355c7d, #6c5b7b, #c06c84)'},
    { name: 'pun yeta', bgGradient: 'linear-gradient(to left, #108dc7, #ef8e38)'},
    { name: 'sublime light', bgGradient: 'linear-gradient(to left, #fc5c7d, #6a82fb)'},
    { name: 'digital water', bgGradient: 'linear-gradient(to left, #74ebd5, #acb6e5)'},
    { name: 'velvet sun', bgGradient: 'linear-gradient(to left, #e1eec3, #f05053)'},
    { name: 'summer', bgGradient: 'linear-gradient(to left, #22c1c3, #fdbb2d)'},
    { name: 'relay', bgGradient: 'linear-gradient(to left, #3a1c71, #d76d77, #ffaf7b)'},
    { name: 'mello', bgGradient: 'linear-gradient(to left, #c0392b, #8e44ad)'},
    { name: 'mini', bgGradient: 'linear-gradient(to left, #30e8bf, #ff8235)'},
    { name: 'vice city', bgGradient: 'linear-gradient(to left, #3494e6, #ec6ead)'},
    { name: 'mild', bgGradient: 'linear-gradient(to left, #67b26f, #4ca2cd)'},
    { name: 'ibiza sunset', bgGradient: 'linear-gradient(to left, #ee0979, #ff6a00)'},
    { name: 'azure pop', bgGradient: 'linear-gradient(to left, #ef32d9, #89fffd)'},
    { name: 'dania', bgGradient: 'linear-gradient(to left, #be93c5, #7bc6cc)'},
    { name: 'grapefruit sunset', bgGradient: 'linear-gradient(to left, #e96443, #904e95)'}
]