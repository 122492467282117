import { onMount, Show, createSignal } from "solid-js"
import "./bottom-player.css"
import { PlayerUtils } from '../../utils/_index'
import PlayQueueDropup from "../play-queue-dropup/play-queue-dropup"
import AddToPlaylistDropup from "../add-to-playlist-dropup/add-to-playlist-dropup"
import PlayerControls from "../controls/player-controls/player-controls"
import ShuffleControl from "../controls/shuffle-control/shuffle-control"
import RepeatControl from "../controls/repeat-control/repeat-control"
import VisualizerControl from "../controls/visualizer-control/visualizer-control"
import VolumeControl from "../controls/volume-control/volume-control"
import WalletDropup from "../wallet-dropup/wallet-dropup"
import { IoWalletOutline } from "solid-icons/io"; // IonIcons
import { APIService } from '../../../../../services/_index'
import { Utils } from "../../../../../utils/_index"

export default () => {

  const [wallet, setWallet] = createSignal()

  const getWallet = async () => {
    let response = await APIService.getMyWalletDetails()
    if (response?.success){
      setWallet(response)
      PlayerUtils.setHederaAccountId(wallet().hedera_account_id)
      PlayerUtils.setPendingJamAmount(wallet().unpaid_tokens)
      PlayerUtils.getJamBalance()
    }
  }

  onMount(() => {
    Utils.docReady(() => {
      if(Utils.userLoggedIn()) {
        getWallet()
      }
    })
    PlayerUtils.getPlayQueue()
  })

  return (
    <div
    class="bottomPlayerContainer">

      {/* Player Controls */}
      <PlayerControls/>

      {/* Play queue Dropup */}
      <div class="playlistDropupSection">
        <div class="playlistDropupSectionInner" onClick={() => PlayerUtils.setPlayQueueDropUpOpen(!PlayerUtils.playQueueDropUpOpen())}>
          <i class="ri-play-list-line playlistIcon"></i>
          <span class="playlistCounterText">{PlayerUtils.playQueue().length}</span>
        </div>
        <Show when={PlayerUtils.playQueueDropUpOpen()}>
          <PlayQueueDropup/>
        </Show>
      </div>

      {/* Right Section */}
      <div class="playerRightSection">

        {/* Secondary Controls */}
          <div class="secondaryControls">
            <Show when={PlayerUtils.songPlayerInstance()}>
              {/* Visualizer */}
              <VisualizerControl/>

              {/* Shuffle */}
              <ShuffleControl/>

              {/* Repeat */}
              <RepeatControl/>
            </Show>

            {/* Volume Controls */}
            <VolumeControl showOnMobile={false}/>
          </div>

        {/* Wallet Dropup */}
        <Show when={Utils.userLoggedIn()}>
        <div class="walletDropupSection">
          <div class="walletDropupSectionInner" onClick={() => PlayerUtils.setWalletDropUpOpen(!PlayerUtils.walletDropUpOpen())}>
            <Show when={PlayerUtils.jamBalance() && PlayerUtils.jamRate()} fallback={
              <div class="spinner spinner--small"></div>
            }>
              <div class="walletBalanceWrapper">
                <span class="walletBalanceText">Ɉ {Utils.formatJamAmount(PlayerUtils.jamBalance(), 4)}</span>
                <span class="walletBalanceText text-gray-500">$ {Utils.jamToUSDAmount(PlayerUtils.jamBalance(), PlayerUtils.jamRate().jam_price, 4)}</span>
              </div>
            </Show>

            <IoWalletOutline/>
          </div>
          <Show when={PlayerUtils.walletDropUpOpen()}>
            <WalletDropup wallet={wallet}/>
          </Show>
        </div>
        </Show>

        {/* Save to playlist dropup */}
        <div class="addToPlaylistDropupWrapper">
          <Show when={PlayerUtils.addToPlaylistDropUpOpen()}>
            <AddToPlaylistDropup/>
          </Show>
        </div>
      </div>
    </div>
  )
}
