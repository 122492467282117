import axios from 'axios'
import { Utils } from "../../../../utils/_index"
import {Howl, Howler} from 'howler'
import { PlayerActions, PlayerUtils, EntityTypes } from './_index'
import { Config } from "../../../../config/base-config"
import Hls from "hls.js"
import HlsAdapter from "./HlsAdapter"
import VideoJSAdapter from "./VideoJSAdapter"
import { Parser } from 'm3u8-parser'
import { APIService } from '../../../../services/_index'
import { i } from 'vite/dist/node/types.d-aGj9QkWt'

let stepTimer


export const _playOrPause = ()=> {
    if(PlayerUtils.songPlayerInstance()) {
        if(PlayerUtils.songPlayerInstance().playing()) {
            PlayerUtils.songPlayerInstance().pause()
        } else {
            PlayerUtils.songPlayerInstance().play()
        }
    } else {
        // if no song player instance play songs on play queue
        if(PlayerUtils.playQueue().length > 0) {
            PlayerActions._playSongOnPlayQueue({})
        } else {
            // if no songs on play queue start radio
            PlayerActions.playItem({entityType: EntityTypes.Radio})
        }
    }
}

export const _playSongsOnQueue = ({startIndex = undefined, playImmediately = true, songPlayStartSeconds = 0}) => {
    // queue could be derived from a playlist, album, or profile

    // shuffle  first depending on whether shuffleOn is set true or false; and no specific play startIndex has been set
    if(startIndex == undefined) {
        _shuffleUnshuffleActivePlayQueue()
        startIndex = 0
    }

    PlayerUtils.setActivePlayerQueueIndex(startIndex)
    if(PlayerUtils.activePlayerQueue().length > 0) {
        const song = PlayerUtils.activePlayerQueue()[startIndex]
        setSongToPlay({
            songJSON: song, 
            playImmediately: playImmediately, 
            songPlayStartSeconds: songPlayStartSeconds
        })
    }
}

export const _skip = ({direction}) => {
    // Get the next track based on the direction of the track
    let playNextOrPrevious = false

    if (direction === 'next') {
        PlayerUtils.setActivePlayerQueueIndex(PlayerUtils.activePlayerQueueIndex() + 1)
        if (PlayerUtils.activePlayerQueueIndex() >= PlayerUtils.activePlayerQueue().length) {
            PlayerUtils.setActivePlayerQueueIndex(PlayerUtils.activePlayerQueue().length - 1)
            playNextOrPrevious = false
        } else {
            playNextOrPrevious = true
        }

        if(!playNextOrPrevious && PlayerUtils.repeatOn()) {
           // repeat play queue
           PlayerUtils.setActivePlayerQueueIndex(0)
           playNextOrPrevious = true
        }
    } else {
        // Restart playback if previous button clicked and current song position is more than 5 seconds
        if(PlayerUtils.songPlayerInstance()) {
            const seekPosition = Math.round(PlayerUtils.songPlayerInstance().seek() || 0)
            if(seekPosition > 5) {
                _restartSongPlayback()
                return
            }
        }
        
        PlayerUtils.setActivePlayerQueueIndex(PlayerUtils.activePlayerQueueIndex() - 1)

        if(PlayerUtils.activePlayerQueueIndex() < 0) {
            PlayerUtils.setActivePlayerQueueIndex(0)
            playNextOrPrevious = false
        } else {
            playNextOrPrevious = true
        }
    }

    if(playNextOrPrevious) {
        const song = PlayerUtils.activePlayerQueue()[PlayerUtils.activePlayerQueueIndex()]
        setSongToPlay({songJSON: song})
    } else {
        if(PlayerUtils.itemPlayingDetails.type == EntityTypes.Radio) {
            // fetch more songs for radio
            PlayerActions.playItem({entityType: EntityTypes.Radio})
            return
        }
        // if no song currently playing on player, set pause to true
        else if(PlayerUtils.songPlayerInstance() && !PlayerUtils.songPlayerInstance().playing()) {
            PlayerUtils.setSongPaused(true)
        }
    }
}

const _restartSongPlayback = () => {
    _seek(0)
}

export const _seek = (seconds) => {
    if(PlayerUtils.songPlayerInstance()) {
        _removeTrackerTransitions()
        PlayerUtils.songPlayerInstance().seek(seconds)
    }
}

const _onSeekHandler = async () => {
    PlayerUtils.setSeekPerformed(true)
    const seekPosition = Math.round(PlayerUtils.songPlayerInstance().seek() || 0)
    let slideData = {
        "slide_start": PlayerUtils.songProgress(),
        "slide_end":  seekPosition
    }
    if(!PlayerUtils.songDataFromWebSocket()){
        //if data is from websock, recording slide not required
        await PlayerUtils.recordSlide(PlayerUtils.uniqueSongListenId(), slideData)
    }
    PlayerUtils.setSongDataFromWebSocket(false)
    PlayerUtils.setVisualizerSeekPosition(seekPosition)
    PlayerUtils.setSongProgress(seekPosition)
    _revertToOriginalTrackerTransitions()
}

const _removeTrackerTransitions = () => {
    // remove existing step transitions for smooth dragging/seek
    const trackerRef = document.getElementById('songTracker')
    const dragIconRef = document.getElementById('songTrackerDragHandle')
    if(trackerRef) {
        trackerRef.style.setProperty('transition', 'none')
    }
    if(dragIconRef) {
        dragIconRef.style.setProperty('transition', 'none')
    }
}

const _revertToOriginalTrackerTransitions = () => {
    // revert back to original transitions used for step
    const trackerRef = document.getElementById('songTracker')
    const dragIconRef = document.getElementById('songTrackerDragHandle')
    if(trackerRef) {
        trackerRef.style.removeProperty('transition')
    }
    if(dragIconRef) {
        dragIconRef.style.removeProperty('transition')
    }
}

const _step = (isFirstFunctionCall = true) => {
    if (stepTimer) clearTimeout(stepTimer)
    // refresh jam balance and jam per minute rate 
    // (commented out as initially called on main play functions: playItem and playSongOnList)
    // if(isFirstFunctionCall) PlayerUtils.getJamBalance()
    if(PlayerUtils.songPlayerInstance() && PlayerUtils.songPlayerInstance().playing()) {
        PlayerUtils.setSongProgress(Math.round(PlayerUtils.songPlayerInstance().seek() || 0))
        const seekTime = PlayerUtils.songPlayerInstance().seek() || 0
       
        if(PlayerUtils.seekPerformed()) {
            PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlaySeconds() + 0.1) // +0.1 sec since step timer is 100ms
        } else {
            PlayerUtils.setSongPlaySeconds(seekTime)
        }
        PlayerUtils.setLastRecordedStepTime(seekTime)
        
        let actualBalance = Utils.formatJamAmount(
            PlayerUtils.jamBalance() || PlayerUtils.songPlayingObject().wallet_balance
        ) 
        let unpaidListensBalance =Utils.formatJamAmount(PlayerUtils.pendingJamAmount() || 0)
        let apparentBalance = actualBalance -unpaidListensBalance
        
        if(Utils.userLoggedIn()) {
            let played_jam = PlayerUtils._convertSecondsToJam(
                PlayerUtils.songPlaySeconds(), 
                PlayerUtils.jamRate().jam_per_minute || PlayerUtils.songPlayingObject().jam_per_minute
            )
           
            if (
                (played_jam + 2) > apparentBalance 
                && !PlayerUtils.songPlayingObject().is_part_owner 
                && PlayerUtils.songPlayingObject().download_type != 'free' 
                && PlayerUtils.songPlayingObject().first_stream_options == 'paid'
            ) {
                if (unpaidListensBalance > 0) {
                    Utils.showToast(
                        `Sorry, you have run out JAM and have unpaid listens or video plays of Ɉ${unpaidListensBalance}. Please purchase more JAM tokens to continue listening.`,
                        "info",
                        10000
                    )
                } else{
                    Utils.showToast(
                        "Sorry, you have run out JAM, please purchase JAM tokens to continue listening.",
                        "info",
                        10000
                    )
                }
                _destroyExistingPlayerInstance() 
            }
            
            
        }
        
         //monitor time used playing
        let currentTime = new Date()  
        // Add 20min to  the last mouse movement time
        let timeAfterLastMovement = new Date(Utils.lastTimeOfMouseMovement().getTime() + 20 * 60 * 1000);
        if(currentTime > timeAfterLastMovement){
            PlayerUtils.songPlayerInstance()?.pause()
            Utils.showToast(
                `Song pause, it appears you stop interacting with the app`,
                "info",
                10000
            )
        }
    }

    if(PlayerUtils.songPlayerInstance()) {
        // timer set here (rather than inside if statement above) 
        // since if seek happens, audio load is async and .playing() may be false hence step won't continue
        stepTimer = setTimeout(() => {
            _step(false)
        }, 100)
    }
}

export const _shuffleUnshuffleActivePlayQueue = () => {
    if(PlayerUtils.shuffleOn()) {
        const songs = _shuffle(PlayerUtils.unshuffledPlayerQueue())
        PlayerUtils.setActivePlayerQueue(songs)
    } else {
        PlayerUtils.setActivePlayerQueue(PlayerUtils.unshuffledPlayerQueue())
    }
}

const _destroyExistingPlayerInstance = () => {
    if(PlayerUtils.songPlayerInstance()) {
        let listen_id =  PlayerUtils.uniqueSongListenId()
      
        const songId =  PlayerUtils.songPlayingObject().id
        const paidSong =  PlayerUtils.songPlayingObject().first_stream_options === "paid"   
        const secondsPlayed =  PlayerUtils.songPlaySeconds() 

        _recordSongListen(listen_id, songId, paidSong, secondsPlayed)

        try {
            PlayerUtils.songPlayerInstance().unload();
        } catch (error) {
            console.warn("Error unloading song player instance:", error);
        }

        try {
            Howler.unload();
        } catch (error) {
            console.warn("Error unloading Howler:", error);
        }
        // clear signals
        PlayerUtils.setSongPlayerInstance()
        PlayerUtils.setSongPlayingObject()

        PlayerUtils.setAudioBuffer()
        PlayerUtils.setAudioFrequencyAnalyzer()
        PlayerUtils.setAudioGainNode()

        PlayerUtils.setSongPaused(false)
        PlayerUtils.setSongDuration(0)
        PlayerUtils.setSongProgress(0)
    }
}

const _recordSongListen = (listenId: number, songId: number, paidSong: boolean, secondsPlayed: number) => {
    // record listen
    PlayerUtils.recordSongListen(listenId, songId, paidSong, secondsPlayed)
    // reset 
    PlayerUtils.setSongPlaySeconds(0)
    PlayerUtils.setSeekPerformed(false)
}


export const setSongToPlay = async ({songJSON, playImmediately = true, songPlayStartSeconds = 0}) => {
    const song = songJSON?.entity || songJSON
    
    if (song.gate_denied) {
        Utils.showToast("This song is collectible-gated. You need to own the collectible to listen to it.", "info", 10000)
        return Promise.reject()
    }
   
    if (song.no_wallet) {
        Utils.showToast("Please logout and login again to have your wallet created", "info", 10000)
        return Promise.reject()
    }
    
    if (!song.path) {
        Utils.showToast("Sorry, we are unable to play this song at this time", "info", 10000)
        return Promise.reject()
    }
    
    _initHowler(song, playImmediately, songPlayStartSeconds)
    PlayerUtils.setSongPlayingObject(song)
    PlayerUtils._removeCurrentSongFromPlayQueue()
    return Promise.resolve()
}


const _initHowlerForMp3 = (songUrl, playImmediately = true, songPlayStartSeconds = 0) => {
    let songPlayerInstance = new Howl({
        src: [songUrl],
        html5: true,
        autoplay: playImmediately,
        loop: false,
        volume: PlayerUtils.currentVolume(),
        onplay: async () => {
            if (PlayerUtils.songPaused()){
                await PlayerUtils.recordListenResumptionAfterPause(PlayerUtils.uniqueSongListenId())
            }
            PlayerUtils.setSongPaused(false)
            PlayerUtils.setSongListenPosted(false)
            // Start updating the progress of the track.
            _step()
            // send play data for caching periodically via websocket
            PlayerUtils.sendPlayerStateDataToServerViaWebSocket()

            _registerMediaSessionActionHandlers()
        },
        onload: () => {
            PlayerUtils.setPlayerLoadingSong(false)
            // Set song duration
            PlayerUtils.setSongDuration(Math.round(PlayerUtils.songPlayerInstance().duration()))
            // Ensure playback starts from value set for songPlayStartSeconds. By default playback starts at 0 seconds
            if(songPlayStartSeconds && songPlayStartSeconds > 0) {
                songPlayerInstance.seek(songPlayStartSeconds) 
            }
            PlayerUtils.setSongPaused(!playImmediately)
            // Fetch audio buffer for use on visualizer
            getAudioBuffer(songUrl)
        },
        onunlock: () => {

        },
        onend: () => {
            // set song progress to full duration
            PlayerUtils.setSongProgress(Math.round(PlayerUtils.songPlayerInstance().duration()))

            // setTimeout on _step() means function might have be called just after song end, hence fix play time
            if(!PlayerUtils.seekPerformed()) {
                // if no seek performed during play, set play seconds to full song duration
                PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlayerInstance().duration())
            } else {
                // else use calculated seconds from _step() function
                // get difference between last recorded step time and song duration
                const difference = PlayerUtils.songPlayerInstance().duration()  - PlayerUtils.lastRecordedStepTime()
                PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlaySeconds() + difference)
            }
            
            let listen_id =  PlayerUtils.uniqueSongListenId()

            // record song listen
            const songId =  PlayerUtils.songPlayingObject().id
            const paidSong =  PlayerUtils.songPlayingObject().first_stream_options === "paid"   
            const secondsPlayed =  PlayerUtils.songPlaySeconds() 

            _recordSongListen(listen_id, songId, paidSong, secondsPlayed)

            if(PlayerUtils.activePlayerQueue().length > 0) {
                // Skip to next
                _skip({direction: 'next'})
            
            } else {
                if(PlayerUtils.repeatOn()) {
                    // repeat song
                    PlayerUtils.songPlayerInstance().play()
                } else {
                    PlayerUtils.setSongPaused(true)
                    // clear signals
                    PlayerUtils.setSongPlayerInstance()
                    PlayerUtils.setSongPlayingObject()
                }
            }
        },
        onpause: async () => {
            PlayerUtils.setSongPaused(true)
            await PlayerUtils.recordListenPause(PlayerUtils.uniqueSongListenId())
        },
        onstop: () => {
            // playback has stopped and seek has been set to 0
            PlayerUtils.setSongProgress(
                Math.round(PlayerUtils.songPlayerInstance() ? PlayerUtils.songPlayerInstance().seek() || 0 : 0)
            )
            let listen_id =  PlayerUtils.uniqueSongListenId()
            // record song listen
            const songId =  PlayerUtils.songPlayingObject().id
            const paidSong =  PlayerUtils.songPlayingObject().first_stream_options === "paid"   
            const secondsPlayed =  PlayerUtils.songPlaySeconds() 

            _recordSongListen(listen_id, songId, paidSong, secondsPlayed) 
        },
        onseek: () => {
            _onSeekHandler()
        },
        onmute: () => {
            // Fires when the sound has been muted/unmuted.
            if(PlayerUtils.songPlayerInstance().mute()) {
                PlayerUtils.setAudioMuted(true)
            } else {
                PlayerUtils.setAudioMuted(false)
            }
        }
    })
    PlayerUtils.setSongPlayerInstance(songPlayerInstance)
}

const _initHls = (songUrl) => {
    const hlsAdapter = new HlsAdapter();
    const hls = new Hls();
    hls.loadSource(songUrl);
    hls.attachMedia(hlsAdapter.audioElement);

    // volume
    hlsAdapter.audioElement.volume = PlayerUtils.currentVolume();

    hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
        hlsAdapter.audioElement.play();
        PlayerUtils.setSongDuration(Math.round(data.details.totalduration));
    });

    hlsAdapter.audioElement.onplay = async () => {
        // Equivalent to Howler's onplay
        PlayerUtils.setSongListenPosted(false)
        PlayerUtils.setPlayerLoadingSong(false);
        if (PlayerUtils.songPaused()){
            await PlayerUtils.recordListenResumptionAfterPause(PlayerUtils.uniqueSongListenId())
        }
        PlayerUtils.setSongPaused(false);
        _step();
        _registerMediaSessionActionHandlers();
    };

    hlsAdapter.audioElement.onended = () => {
        // set song progress to full duration
        PlayerUtils.setSongProgress(Math.round(PlayerUtils.songPlayerInstance().duration()))

        // setTimeout on _step() means function might have be called just after song end, hence fix play time
        if (!PlayerUtils.seekPerformed()) {
            // if no seek performed during play, set play seconds to full song duration
            PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlayerInstance().duration())
        } else {
            // else use calculated seconds from _step() function
            // get difference between last recorded step time and song duration
            const difference = PlayerUtils.songPlayerInstance().duration() - PlayerUtils.lastRecordedStepTime()
            PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlaySeconds() + difference)
        }

        let listen_id =  PlayerUtils.uniqueSongListenId()
        // record song listen
        const songId =  PlayerUtils.songPlayingObject().id
        const paidSong =  PlayerUtils.songPlayingObject().first_stream_options === "paid"   
        const secondsPlayed =  PlayerUtils.songPlaySeconds() 

        _recordSongListen(listen_id, songId, paidSong, secondsPlayed)

        if (PlayerUtils.activePlayerQueue().length > 0) {
            // Skip to next
            _skip({direction: 'next'})
        } else {
            if (PlayerUtils.repeatOn()) {
                // repeat song
                PlayerUtils.songPlayerInstance().play()
            } else {
                PlayerUtils.setSongPaused(true)
                // clear signals
                PlayerUtils.setSongPlayerInstance()
                PlayerUtils.setSongPlayingObject()
            }
        }
    }
    
    hlsAdapter.audioElement.onpause = async () => {
        // Similar to Howler's onpause
        PlayerUtils.setSongPaused(true);
        await PlayerUtils.recordListenPause(PlayerUtils.uniqueSongListenId())
    };

    hlsAdapter.audioElement.onseeked = () => {
        // Similar to Howler's onseek
        _onSeekHandler();
    };

    hlsAdapter.audioElement.onvolumechange = () => {
        // Check for muted state and adjust the player utils accordingly
        PlayerUtils.setAudioMuted(hlsAdapter.audioElement.muted);
    };

    PlayerUtils.setSongPlayerInstance(hlsAdapter); 
}

const _initVideoJS = (songUrl, playImmediately = true, songPlayStartSeconds = 0) => {
    // Destroy any existing player instance
    _destroyExistingPlayerInstance();

    const videoJSAdapter = new VideoJSAdapter(playImmediately);

    // Set source
    videoJSAdapter.setSrc(songUrl, 'application/x-mpegURL');

    // Set volume
    videoJSAdapter.volume(PlayerUtils.currentVolume());

    // Attach event listeners
    videoJSAdapter.on('loadedmetadata', () => {
        // Ensure playback starts from value set for songPlayStartSeconds. By default playback starts at 0 seconds
        if(songPlayStartSeconds && songPlayStartSeconds > 0) {
            videoJSAdapter.seek(songPlayStartSeconds)
        }
        PlayerUtils.setPlayerLoadingSong(false)
        PlayerUtils.setSongPaused(!playImmediately)
        PlayerUtils.setSongDuration(Math.round(videoJSAdapter.duration()));
        if(playImmediately) {
            // Start updating the progress of the track.
            _step()
        }
        _registerMediaSessionActionHandlers();
        getAudioBuffer(songUrl)
    });

    videoJSAdapter.on('play', async () => {
        PlayerUtils.setSongListenPosted(false);
        if (PlayerUtils.songPaused()){
            await PlayerUtils.recordListenResumptionAfterPause(PlayerUtils.uniqueSongListenId())
        } 
        PlayerUtils.setSongPaused(false);
        // Start updating the progress of the track.
        _step();
        // send play data for caching periodically via websocket
        PlayerUtils.sendPlayerStateDataToServerViaWebSocket()
        _registerMediaSessionActionHandlers();
    });

    videoJSAdapter.on('ended', () => {
        // Similar logic to before...
        PlayerUtils.setSongProgress(Math.round(videoJSAdapter.duration()));

        if (!PlayerUtils.seekPerformed()) {
            PlayerUtils.setSongPlaySeconds(videoJSAdapter.duration());
        } else {
            const difference = videoJSAdapter.duration() - PlayerUtils.lastRecordedStepTime();
            PlayerUtils.setSongPlaySeconds(PlayerUtils.songPlaySeconds() + difference);
        }
        
        let listen_id =  PlayerUtils.uniqueSongListenId()
        // record song listen
        const songId =  PlayerUtils.songPlayingObject().id
        const paidSong =  PlayerUtils.songPlayingObject().first_stream_options === "paid"   
        const secondsPlayed =  PlayerUtils.songPlaySeconds() 

        _recordSongListen(listen_id, songId, paidSong, secondsPlayed)

        if (PlayerUtils.activePlayerQueue().length > 0) {
            _skip({direction: 'next'});
        } else {
            if (PlayerUtils.repeatOn()) {
                videoJSAdapter.play();
            } else {
                PlayerUtils.setSongPaused(true);
                // clear signals
                PlayerUtils.setSongPlayerInstance()
                PlayerUtils.setSongPlayingObject()
            }
        }
    });

    videoJSAdapter.on('pause', async () => {
        PlayerUtils.setSongPaused(true);
        await PlayerUtils.recordListenPause(PlayerUtils.uniqueSongListenId())
    });

    videoJSAdapter.on('seeked', () => {
        _onSeekHandler()
    });

    videoJSAdapter.on('volumechange', () => {
        if(videoJSAdapter.isMuted()) {
            PlayerUtils.setAudioMuted(true)
        } else {
            PlayerUtils.setAudioMuted(false)
        }
    })

    videoJSAdapter.on('error', () => {
        Utils.showToast('Unable to play song at this time, please try again later', 'warning', 3000)
        videoJSAdapter.pause() 
        PlayerUtils.setPlayerLoadingSong(false) 
    })

    PlayerUtils.setSongPlayerInstance(videoJSAdapter);
}

const _initHowler = async (song, playImmediately = true, songPlayStartSeconds = 0) => {
    // DESTROY ANY CURRENT PLAY INSTANCE
    _destroyExistingPlayerInstance()

     //get unique id for listen
    if(!PlayerUtils.listenAlreadyInitiated()){
        // this is to prevent double initiating of song listen when data is pull from websock with a listen_id
        PlayerUtils.initiateSongListen(song) 
    }
    PlayerUtils.setListenAlreadyInitiated(false)
    
    PlayerUtils.setPlayerLoadingSong(true)
    PlayerUtils.setSongPaused(false)
    let songUrl = song.path
    // Only use HLS if songurl ends with .m3u8
    if(songUrl.endsWith('.mp3')) {
        _initHowlerForMp3(songUrl, playImmediately, songPlayStartSeconds)
    } else {
        _initVideoJS(songUrl, playImmediately, songPlayStartSeconds)  
    }
}

const _registerMediaSessionActionHandlers = () => {
    // These actions let a web app receive notifications when the user engages a device's built-in physical or onscreen 
    // media controls, such as play, stop, or seek buttons.
    const skipTime = 10

    if ('mediaSession' in navigator && PlayerUtils.songPlayingObject()) {
        navigator.mediaSession.metadata = new MediaMetadata({
            title:  PlayerUtils.songPlayingObject().title,
            artist: PlayerUtils.songPlayingObject().artistName,
            artwork: [
              { src: `${Config.BASE_URL}${PlayerUtils.formatSongImagePath(PlayerUtils.songPlayingObject().artwork)}` },
            ]
          })

        navigator.mediaSession.setActionHandler('previoustrack', () => {
            _skip({direction: 'previous'})
        })
    
        navigator.mediaSession.setActionHandler('nexttrack', () => {
            _skip({direction: 'next'})
        })
    
        navigator.mediaSession.setActionHandler('play', () => {
            _playOrPause()
        })
    
        navigator.mediaSession.setActionHandler('pause', () => {
            _playOrPause()
        })
    
        navigator.mediaSession.setActionHandler('stop', () => {
            if(PlayerUtils.songPlayerInstance()) {
                PlayerUtils.songPlayerInstance().stop()
            }
        })

        navigator.mediaSession.setActionHandler('seekbackward', () => {
            if(PlayerUtils.songPlayerInstance()) {
                const seekToTime = PlayerUtils.songProgress() - skipTime
                _seek(seekToTime >= 0 ? seekToTime : 0)
            }
        })

        navigator.mediaSession.setActionHandler('seekforward', () => {
            if(PlayerUtils.songPlayerInstance()) {
                const seekToTime = PlayerUtils.songProgress() + skipTime
                _seek(seekToTime <= PlayerUtils.songDuration() ? seekToTime : PlayerUtils.songDuration())
            }
        })

        navigator.mediaSession.setActionHandler('seekto', (evt) => {
            if(PlayerUtils.songPlayerInstance()) {
               _seek(Math.round(evt.seekTime))
            }
        })
    }
}

export const _shuffle = (songs) => {
    // Song Shuffler
    // build a stock index
    let stock_index = []
    for (let x = 0; x < songs.length; x++) {
        stock_index.push(x)
    }
    // shuffle
    let s = stock_index
    let k, n = s.length
    let t = []
    while (t.length < n) {
        k = Math.floor(Math.random() * n)
        if (t.indexOf(s[k]) < 0) {
            t.push(s[k])
        }
    }
    // compare the two indexes - If any elements line up with the stock index, then swap with one of the adjacent elements
    for (let x = 1; x < s.length; x++) {
        if (t[x-1] === s[x-1]) {
            t[x-1], t[x] = t[x], t[x-1]
        }
    }

    // compile the track list
    let tracks = []
    t.forEach(x => tracks.push(songs[x]))

    return tracks
}


export const getHowler = () => {
    return Howler;
}

export const getAudioBuffer = async (songUrl) => {
    try {
        let bufferData
        
        // Fetch buffer the standard way if is basic mp3 file
        if(songUrl.endsWith('.mp3')) {
            const response = await axios.get(songUrl, {
                responseType: 'arraybuffer'
            })
            bufferData = response.data
        } 
        // Parse .m3u8 master playlist url
        else if(songUrl.endsWith('.m3u8')) {
            const mediaPlaylistUrl = await _getMediaPlaylistUrlFromM3U8MasterPlaylist(songUrl)
            if(mediaPlaylistUrl) {
                bufferData = await _getAudioBufferFromM3U8MediaPlaylistUrl(mediaPlaylistUrl)
            }
        }

        if(bufferData) {
            const context = new (window.AudioContext || window.webkitAudioContext)()
            const audioBuffer: AudioBuffer  = await context.decodeAudioData(bufferData)
            // const channelData: Float32Array = audioBuffer.getChannelData(0);
            // // audioBuffer can have multiple channels, each channel is Float32Array
            // console.log('channelData', channelData)
            PlayerUtils.setAudioBuffer(audioBuffer)
        }
    } catch (error) {
        console.error('Error fetching buffer:', error)
    }
}


const _getMediaPlaylistUrlFromM3U8MasterPlaylist = async (m3u8MasterUrl) => {
    try {
      const response = await axios.get(m3u8MasterUrl)
      const m3u8MasterContent = response.data
      
      // Parse the m3u8 master playlist to find variant streams
      const m3u8Parser = new Parser()
      m3u8Parser.push(m3u8MasterContent)
      m3u8Parser.end()
      
      const playlists = m3u8Parser.manifest.playlists
      
      if (!playlists || playlists.length == 0) {
        console.error('No variant playlists found in master playlist')
      }
  
      // Choose the first playlist. Can also filter based on bitrate, etc
      const mediaPlaylistUrl = new URL(playlists[0].uri, m3u8MasterUrl).href
      
      return mediaPlaylistUrl
    } catch (error) {
      console.error("Error fetching master playlist:", error)
      return null
    }
}

const _getAudioBufferFromM3U8MediaPlaylistUrl = async (m3u8MediaUrl) => {
    try {
      // Fetch m3u8 playlist
      const playlistResponse = await axios.get(m3u8MediaUrl)
      const m3u8MediaContent = playlistResponse.data
            
      // Parse the m3u8 file to extract segment URLs
      const m3u8Parser = new Parser()
      m3u8Parser.push(m3u8MediaContent)
      m3u8Parser.end()

      const segments = m3u8Parser.manifest.segments
      if (!segments || segments.length == 0) {
        console.error('No segments found in media playlist')
      }
  
      // Fetch the buffers for all segments
      const segmentBuffers = await Promise.all(
        segments.map(async (segment) => {
          const segmentUrl = new URL(segment.uri, m3u8MediaUrl).href;
          const response = await axios.get(segmentUrl, { responseType: 'arraybuffer' })
          return response.data
        })
      )
  
      // Concatenate all segment buffers into one audio buffer
      const totalLength = segmentBuffers.reduce((accumulator, buffer) => accumulator + buffer.byteLength, 0)
      const concatenatedBuffer = new Uint8Array(totalLength)
      
      let offset = 0
      for(const buffer of segmentBuffers) {
        concatenatedBuffer.set(new Uint8Array(buffer), offset)
        offset += buffer.byteLength
      }
  
      return concatenatedBuffer.buffer
    } catch (error) {
      console.error("Error fetching buffer:", error)
      return null
    }
}