import { Show, createSignal, createEffect, onMount, For } from "solid-js"
import "./add-to-playlist-dropup.css"
import { PlayerActions, PlayerUtils } from '../../utils/_index'
import LoadingPageIndicator from "../../../widgets/loading-page-indicator"
import { APIService } from '../../../../../services/_index'
import { Utils } from "../../../../../utils/_index"
import { SolidBottomsheet } from "solid-bottomsheet"
import '../../../../shared/styles/solid-bottomsheet.css'
import $ from 'jquery'

export default ({}) => {
  const [loadingPlaylists, setLoadingPlaylists] = createSignal(false)
  const [existingUserPlaylists, setExistingUserPlaylists] = createSignal([])
  const [selectedPlaylistName, setSelectedPlaylistName] = createSignal('')
  const [newPlaylistName, setNewPlaylistName] = createSignal('')
  const [showCreatePlaylistUI, setShowCreatePlaylistUI] = createSignal(false)
  const [savingToPlaylist, setSavingToPlaylist] = createSignal(false)
  const addToPlaylistBottomsheetDropupChildDivId = `addToPlaylistBottomsheetDropup`
  const addToPlaylistBottomsheetDropupParentDivId = `addToPlaylistBottomsheetDropupParent`

  const getUserPlaylists = async () => {
    setLoadingPlaylists(true)
    await APIService.getRequest({
      url:`/api/v1/playlist/get-users-playlists`,
      onSuccess: (response) => {
        if(response && response?.success) {
          setExistingUserPlaylists(response.playlists)
          if(response.playlists.length === 0){
            setShowCreatePlaylistUI(true) 
          }

        }
      }
    })
    setLoadingPlaylists(false)

  }

  const selectDeselectExistingPlaylist = (playlistName) => {
    if(playlistName.toLowerCase() == selectedPlaylistName().toLowerCase()) {
      setSelectedPlaylistName('')
    } else {
      setSelectedPlaylistName(playlistName)
    }
  }

  const createNewPlaylistOrUpdateExistingPlaylist = async () => {
    setSavingToPlaylist(true)

    let playlistName = ''
    if(selectedPlaylistName().trim() != '') {
      playlistName = selectedPlaylistName()
    } else {
      playlistName = newPlaylistName();
    }

    if(playlistName.trim() == '') {
      Utils.showToast(showCreatePlaylistUI() ? "Please enter playlist name" : "Please select a playlist", "danger", 3000)
    } else {
      const successful = await PlayerActions.addItemToPlaylistFunction({
        playlistName: playlistName, 
        isCreatingANewPlaylist: showCreatePlaylistUI()
      })
      if(successful) {
        PlayerUtils.setAddToPlaylistDropUpOpen(false)
      }
    }

    setSavingToPlaylist(false)
  }

  const listenForBottomsheetActions = () => {
    createEffect(() => {
      //////////////////////////////////////////////////////////////////
      // Detect clicks outside dropup
      $('.sb-overlay').on('click', (event) => {
        if ($(event.target).closest('.sb-content').length > 0) {
          // Click occurred on or inside the content div ('sb-content'), do nothing
          return
        }
        PlayerUtils.setAddToPlaylistDropUpOpen(false)
      })

      //////////////////////////////////////////////////////////////////
      // Detect music actions dropup dismiss by dragging down
      const addToPlaylistBottomsheetDropupChildDiv = document.getElementById(addToPlaylistBottomsheetDropupChildDivId)
      // Get the parent of the addToPlaylistBottomsheetDropupChildDiv and assign id attribute
      addToPlaylistBottomsheetDropupChildDiv.parentNode.setAttribute('id', addToPlaylistBottomsheetDropupParentDivId)

      // Mutation observer to monitor addToPlaylistBottomsheetDropupParentDivId style attribute changes
      let addToPlaylistBottomsheetDropupObserver = new MutationObserver((mutations) => {
        mutations.forEach(function(mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            // Handle the style attribute changes
            const addToPlaylistBottomsheetDropupParentStyle = 
            window.getComputedStyle(document.getElementById(addToPlaylistBottomsheetDropupParentDivId))
            const parentHeight = parseFloat(addToPlaylistBottomsheetDropupParentStyle.height.replace(/px/g, ''))

            // Get the transform attribute from the computed style
            var transformMatrix = addToPlaylistBottomsheetDropupParentStyle.transform

            // Get transalteY to use to compute dropup drag percentage
            const translateY = Utils.getTranslateYValueFromTransformMatrix(transformMatrix)
            // if dropup is dragged down to a third  of the screen, dismiss
            if(translateY/parentHeight >= 0.66) {
              PlayerUtils.setAddToPlaylistDropUpOpen(false)
              // stop observing for mutations
              addToPlaylistBottomsheetDropupObserver.disconnect()
            }
          }
        })
      })
  
      // Observer dropup parent for attribute changes
      addToPlaylistBottomsheetDropupObserver.observe(
        document.getElementById(addToPlaylistBottomsheetDropupParentDivId), 
        { attributes: true }
      )
    })
  }

  onMount(() => {
    getUserPlaylists()
    listenForBottomsheetActions()
  })
  
  return (
    <SolidBottomsheet 
      variant="default"
      onClose={() => {
        PlayerUtils.setAddToPlaylistDropUpOpen(false)
      }}
    >
      <div id={addToPlaylistBottomsheetDropupChildDivId} class="bottomsheet-dropup-container">
        <div class="dropupTopBar">
          <i
          class="ri-close-line dropupCloseIcon"
          onClick={() => PlayerUtils.setAddToPlaylistDropUpOpen(false)}
          ></i>
        </div>

        <div class="addToPlaylistDropupContainer">
          <div class="dropupTitleDiv">
            <span class="dropupTitle">Save to Playlist</span>
          </div>
          <div class="addToPlaylistMainContent">
            {/* <div class="addToPlaylistTopBar">
              <span class="dropupTitle">Save to Playlist</span>
              <i
              class="ri-close-line addToPlaylistDropupCloseIcon"
              onClick={() => PlayerUtils.setAddToPlaylistDropUpOpen(false)}
              ></i>
            </div> */}

            <Show when={!loadingPlaylists()}>
              <>
                <div class="contentBox">
                  <div class="selectedSaveOptionTitleWrapper">
                    <span>{`${showCreatePlaylistUI() ? 'Create new playlist' : 'Select existing playlist'}`}</span>
                    <Show when={showCreatePlaylistUI()} fallback={
                      <></>
                    }>
                      <i class="ri-music-line"></i>
                    </Show>
                  </div>


                  <Show when={showCreatePlaylistUI()}>
                    <div class="inputItem">
                      <span class="inputLabel">Enter name for new playlist</span>
                      <div class="inputInnerWrapper">
                        <input
                          onInput={(e) => {
                            setNewPlaylistName(e.target.value.trim())
                          }}
                          placeholder="Enter name for new playlist"
                          value={newPlaylistName()}
                        ></input>
                      </div>
                    </div>
                  </Show>
                  
                  <Show when={!showCreatePlaylistUI()}>
                    <Show when={existingUserPlaylists().length > 0} fallback={
                      <span class="noPlaylistsMessage">No saved playlists</span>
                    }>
                      <div class="existingPlaylistsContainer no-scrollbar ">
                        <For each={existingUserPlaylists()}>
                          {(playlistName, index) => (
                            <div 
                            class="playlistSongItem"
                            onClick={() => {
                              selectDeselectExistingPlaylist(playlistName)
                            }}
                            >
                              <span>{playlistName}</span>
                              <Show when={selectedPlaylistName().toLowerCase() == playlistName.toLowerCase()}>
                                <i class="ri-checkbox-circle-fill"></i>
                              </Show>
                            </div>
                          )}
                        </For>
                      </div>
                    </Show>
                  </Show>
                </div>

                <Show when={!savingToPlaylist()} fallback={
                  <LoadingPageIndicator centered={false} small={true}/>
                }>
                  <div 
                  class="submitButton"
                  onClick={() => {
                    createNewPlaylistOrUpdateExistingPlaylist()
                  }}
                  >
                    <span>{existingUserPlaylists().includes(newPlaylistName()) ? 'Update playlist' : `${showCreatePlaylistUI() ? 'Save to new playlist' : 'Save to playlist'}`}</span>
                  </div>
                </Show>
                <Show when={existingUserPlaylists().length > 0}>
                  <div 
                  class="alternativeOptionTitleWrapper"
                  onClick={() => {
                    setShowCreatePlaylistUI(!showCreatePlaylistUI())
                  }}
                  >
                    <span>{`${showCreatePlaylistUI() ? 'Or save to existing playlist' : 'Or create new playlist'}`}</span>
                    <Show when={showCreatePlaylistUI()} fallback={
                      <i class="ri-music-line"></i>
                    }>
                      <i class="ri-add-line"></i>
                    </Show>
                  </div>
                </Show>
              </>
            </Show>
          </div>

          <Show when={loadingPlaylists()}>
            <LoadingPageIndicator/>
          </Show>
        </div>
      </div>
    </SolidBottomsheet>
  )
}
