import { createSignal, For, Show, createEffect, onMount } from "solid-js"
import { APIService } from '../../../../../services/_index'
import "./play-queue-dropup.css"
import { Utils } from "../../../../../utils/_index"
import { PlayerUtils, PlayerActions, EntityTypes } from '../../../player/utils/_index'
import LoadingPageIndicator from "../../../widgets/loading-page-indicator"
import { IoPlay, IoPause } from "solid-icons/io"; // IonIcons
import Sortable from 'sortablejs'
import { useNavigate  } from "@solidjs/router"

export default ({}) => {
  const navigate = useNavigate() 
  const [playlistLoading, setPlaylistLoading] = createSignal(false)
  const [playlistName, setPlaylistName] = createSignal('')
  const [startingSongPlay, setStartingSongPlay] = createSignal()
  const [playlists, setPlaylists] = createSignal([])
  const [mainPlaylists, setMainPlaylists] = createSignal([])
  const [openList, setOpenList] = createSignal(false)
  const [updatingPlaylist, setUpdatingPlaylist] = createSignal(false)
  const reorderPlayQueue = async (queue_song_ids) => {
    await APIService.postRequest({url:`/api/v1/playlist/queue/reorder-playlist-queue`, postData:{queue_ids: queue_song_ids.toString()}
      
    })
    PlayerUtils.getPlayQueue()

  }

  const deleteSongFromPlayQueue = async (songId) => {
    if(songId) {
      setPlaylistLoading(true)
      await APIService.postRequest({url:'/api/v1/playlist/queue/remove-song-from-playlist-queue', postData:{
        songid: songId
        }, displayErrorMessage: true
      })
      PlayerUtils.getPlayQueue()
      setPlaylistLoading(false)
    }
    return  Promise.resolve()
  }

  const clearPlayQueue = async () => {
    setPlaylistLoading(true)
    await APIService.postRequest({url:`/api/v1/playlist/queue/clear-playlist-queue`, displayErrorMessage: true
     
    })
    PlayerUtils.getPlayQueue()
    setPlaylistLoading(false)

    PlayerUtils.setPlayQueueDropUpOpen(false)
    
  }

  const savePlaylist = async () => {
    if (playlistName().trim() == '') {
      Utils.showToast("Please enter playlist name", "warning", 3000)
    } else {
      setPlaylistLoading(true)
      let song_ids = []

      PlayerUtils.playQueue().forEach((song, index) => {
        song_ids.push(song.id)
      })

      await APIService.postRequest({url:'/api/v1/playlist/queue/save-playlist-queue-as-playlist', postData:{
        name: playlistName().trim(),
        song_ids: JSON.stringify(song_ids)
        }, onSuccess: async (response) => {
          if(response && response?.success) {
            Utils.showToast(response.message, "success", 5000)
            // delete all cached data related to profile cache
            await Utils.clearCachedAPIdataForSpecificUrls(Utils.urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh())
            setPlaylistName('')
            
            await clearPlayQueue()
            await PlayerUtils.getPlayQueue()
            navigate(`/@${response.profile_keyword}/playlists/${response.playlist_keyword}`)
            PlayerUtils.setPlayQueueDropUpOpen(false)
          }
        }, displayErrorMessage: true
      })
      setPlaylistLoading(false)

      
    }
  }

  const initPlayQueueDropUpSongsDragAndDrop = () => {
    setTimeout(() => {
      if(PlayerUtils.playQueue().length > 0) {
        let el = document.getElementById('playlistDropUpSongsSortableDiv')
        // User can quickly open and close the dropup, so check if element exists
        if (!el) return
        let sortable = Sortable.create(el, {
          group: 'sortablePlaylistSongsGroup',
          handle: '.dragSongIcon',
          animation: 150,
          scroll: true, // Enable the plugin. Can be HTMLElement.
          forceAutoscrollFallback: false, // force autoscroll plugin to enable even when native browser autoscroll is available
          forceFallback: true, // also enables forceAutoscrollFallback: true
          scrollSensitivity: 100, // px, how near the mouse must be to an edge to start scrolling. forceFallback: true option must be set
          scrollSpeed: 20, // px, speed of the scrolling
          bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
          setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
          },
          // Element is chosen
          onChoose: function (/**Event*/evt) {
            evt.oldIndex;  // element index within parent
          },

          // Element is unchosen
          onUnchoose: function(/**Event*/evt) {
            // same properties as onEnd
          },
          
          // Element dragging started
          onStart: function (/**Event*/evt) {
            // evt.oldIndex;  // element index within parent
          },

          // Element dragging ended
          onEnd: (/**Event*/evt) => {
            let elementId = evt.item.id;  // dragged HTMLElement
            // let songId = elementId.split('-')[1]
            // let position = evt.newIndex

            let songElements  = document.querySelector('#playlistDropUpSongsSortableDiv').children
            
            let song_ids = []
            // read from last to first - as db queue ordering is from last index to first index
            for (let i = songElements.length - 1; i >= 0; i--) {
              let elementId = songElements[i].id
              let songId = elementId.split('-')[1]
              song_ids.push(songId)
            }

            reorderPlayQueue(song_ids)
          }
        })
      }
    }, 1000)
  }

  const filterPlaylist = async (entry) => {
    if(entry.trim() == '') {
      setOpenList(false)
      return
    }
    setUpdatingPlaylist(false)
    let updatedList = mainPlaylists().filter((pl) => {
      return pl.toLowerCase().startsWith(entry.toLowerCase())
    })
    setPlaylists(updatedList)
    if(updatedList.length > 0) {
      setOpenList(true)
    }else{
      setOpenList(false)
    }
  }

  createEffect(() => {
    initPlayQueueDropUpSongsDragAndDrop()
  }, [PlayerUtils.playQueue()])

  onMount(async () => {
    Utils.docReady(() => {
      initPlayQueueDropUpSongsDragAndDrop()
    })
 
    await APIService.getRequest({
      url:`/api/v1/playlist/get-users-playlists`,
      onSuccess: (response) => {
        if(response && response?.success) {
          setMainPlaylists(response.playlists)
          setPlaylists(response.playlists)
        }
      },
    })
    
  })

  return (
    <div class="playQueueDropupContainer">
      {/* Dropup title */}
      <div class="playQueueTitle">
        <span>Play Queue</span>
        <i
        class="ri-close-line"
        onClick={() => PlayerUtils.setPlayQueueDropUpOpen(false)}
        ></i>
      </div>

      <Show when={PlayerUtils.playQueue().length > 0} fallback={
        <div class="empytyQueueContainer">
          <span>No songs in queue</span>
        </div>
      }>
        <Show when={openList()}>
        <div class="min-w-[250px]">
          <div class="flex justify-center flex-wrap w-full">
            <div class="bg-zinc-800 w-full mx-6 rounded-lg pl-6 pr-3 mt-2">
              <ul class="h-30 overflow-y-scroll divide-y-8 divide-white">
                <For each={playlists()}>{(name, i) =>
                  <li
                  onClick={() => {
                    setPlaylistName(name)
                    setOpenList(false)
                    setUpdatingPlaylist(true)
                  }}
                  class="cursor-pointer border-b-2 border-white"
                  >
                    <span class="text-white text-base leading-10">{name}</span>
                  </li>
                }
                </For>
              </ul>
            </div>
          </div>
        </div>
        </Show>

        <input
        class="playlistNameInput"
        placeholder="Name your play queue"
        onInput={(e) => {
          setPlaylistName(e.target.value)
          filterPlaylist(e.target.value)
        }}
        value={playlistName()}
        ></input>
       
        <div class="buttonContainer">
          <div onclick={() => clearPlayQueue()}>
            <i class="ri-delete-bin-7-line"></i>
            <span>Clear All</span>
          </div>
          <div onClick={() => savePlaylist()}>
            <i class="ri-save-line"></i>
            <span>{updatingPlaylist() ? "Update Playlist" : "Save as Playlist"}</span>
          </div>
        </div>

        {/* Playlist Songs */}
        <div id="playlistDropUpSongsSortableDiv" class="playQueueSongsContainer">
          <For each={PlayerUtils.playQueue()}>{(song, i) =>
            <div id={`dropupSongId-${song.id}`} class="playQueueSongItem">
              <div class="songLeftSection">
                <i class="ri-draggable dragSongIcon"></i>
                <Show when={startingSongPlay()} fallback={
                  <div
                  onClick={async (e) => {
                    if(Utils.userLoggedIn()) {
                      if(
                      PlayerUtils.itemPlayingDetails.type == EntityTypes.PlayQueue &&
                      PlayerUtils.songPlayingObject() &&
                      PlayerUtils.songPlayingObject().id == song.id
                      ) {
                        PlayerActions.playPauseSong()
                      } else {
                        setStartingSongPlay(true)
                        await PlayerActions.playItem({
                          entityType: EntityTypes.Song, 
                          entityId: song.id, 
                          entityJSON: song, 
                          isFromPlayQueueDropup: true
                        })
                        setStartingSongPlay(false)
                      }
                    }
                  }}
                  >
                    <Show when={
                      !PlayerUtils.songPaused() &&
                      PlayerUtils.itemPlayingDetails.type == EntityTypes.PlayQueue &&
                      PlayerUtils.songPlayingObject() &&
                      PlayerUtils.songPlayingObject().id == song.id
                    }  fallback={
                      <IoPlay class="playSongIcon"></IoPlay>
                    }>
                      <IoPause class="playSongIcon"></IoPause>
                    </Show>
                  </div>
                }>
                  <div class="spinner spinner--small"></div>
                </Show>
                <div class="songDetailsWrapper">
                  <span class="title">{song.title}</span>
                  <a href={song.profileurl} class="artistName">{song.artistName.trim()}</a>
                </div>
              </div>
              <i
              class="ri-close-line deleteSongIcon"
              onClick={() => {
                deleteSongFromPlayQueue(song.id)
              }}></i>
            </div>
          }
          </For>
        </div>
      </Show>

      <Show when={playlistLoading()}>
        <LoadingPageIndicator medium={true}/>
      </Show>
    </div>
  )
}
