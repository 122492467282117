/*-
 * ‌
 * Hedera JavaScript SDK
 * ​
 * Copyright (C) 2020 - 2023 Hedera Hashgraph, LLC
 * ​
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ‍
 */

import AccountId from "../account/AccountId.js";
import ObjectMap from "../ObjectMap.js";

/**
 * @augments {ObjectMap<AccountId, Long>}
 */
export default class TokenTransferAccountMap extends ObjectMap {
    constructor() {
        super((s) => AccountId.fromString(s));
    }

    toJSON() {
        const obj = {};

        this._map.forEach((value, key) => {
            // @ts-ignore
            obj[key] = value.toString();
        });

        return obj;
    }
}
