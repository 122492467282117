export const HEDERA_MIRRORNODE =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'testnet'
        : 'mainnet-public'
export const HASHPACK_NETWORK = 'mainnet'
export const JAM_TOKEN_ID = '0.0.127877'

export const AWS_THUMBNAIL_API =
    'https://1ct1awga9k.execute-api.us-east-1.amazonaws.com/process-nft-visual'
export const AWS_UNLOCKABLES_API =
    'https://w81e5dpmv0.execute-api.us-east-1.amazonaws.com/default/upload-nft-unlockable'
export const STREAMING_ROYALTIES_DESC =
    'If your Collectible includes an audio track, you can choose to receive a portion of the streaming royalties in JAM tokens each time your Collectible is streamed. By using this feature, you can earn passive income from your Collectible and support the growth of the JAM community.'
export const SALES_ROYALTIES_DESC =
    "If your Collectible is resold at a later date, you can earn a percentage of that secondary sale in JAM as a residual royalty each time it happens. This means that even after you've sold your Collectible, you can continue to earn passive income from it as long as it's being traded on the JAM marketplace."
export const UNLOCKABLE_CONTENT_DESC =
  'Make your Collectible stand out with unlockable perks! Add exclusive experiences, goodies, and perks for owners of your Collectible, such as access to a private Discord community, VIP backstage passes, artist meetups, merchandise, and more. Name and describe the perk in the publicly visible sections, and include private details for redeeming the perk in the Locked Content section. This can include invite links, discount codes, contact information, or event invitations that are only visible to the owner of the Collectible.'
