import { PlayerUtils, PlayerActions } from '../../../utils/_index'
import visualizerIcon from "../../../../../../assets/icons/visualizer-icon.svg"
export default ({}) => {

  const toggleVisualizer = () => {
    PlayerUtils.setVisualizerOpen(!PlayerUtils.visualizerOpen())
    PlayerUtils.setFullScreenPlayerOpen(false)
    PlayerUtils.resizePage()
  }

  return (
    <div
    class="visualizerIcon rainbowSvg"
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      toggleVisualizer()
    }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 682.667 682.667" style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
        <g>
          <defs>
            <clipPath id="a" clipPathUnits="userSpaceOnUse">
              <path d="M0 512h512V0H0Z" fill="#7C7C7C" data-original="#7C7C7C"></path>
            </clipPath>
            <clipPath id="b" clipPathUnits="userSpaceOnUse">
              <path d="M0 512h512V0H0Z" fill="#7C7C7C" data-original="#7C7C7C"></path>
            </clipPath>
            <clipPath id="c" clipPathUnits="userSpaceOnUse">
              <path d="M0 512h512V0H0Z" fill="#7C7C7C" data-original="#7C7C7C"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
            <path d="M0 0v-482" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="translate(256 497)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          </g>
          <path d="M0 0v-327.815" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 421.333 122.79)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <path d="M0 0v-191.131" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 501.333 213.913)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <path d="M0 0v-117.548" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 581.333 262.968)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <g clip-path="url(#b)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
            <path d="M0 0v-57.548" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="translate(496 284.774)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          </g>
          <path d="M0 0v-327.815" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 261.333 122.79)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <path d="M0 0v-191.131" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 181.333 213.913)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <path d="M0 0v-117.548" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="matrix(1.33333 0 0 -1.33333 101.333 262.968)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          <g clip-path="url(#c)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
            <path d="M0 0v-57.548" style="stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1" transform="translate(16 284.774)" fill="none" stroke="#7C7C7C" stroke-width="30" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity="" data-original="#7C7C7C" class=""></path>
          </g>
        </g>
      </svg>
    </div>
  )
}
