import { Show } from "solid-js"
import { PlayerUtils, PlayerActions } from '../../../utils/_index'
import { IoShuffleOutline } from "solid-icons/io"; // IonIcons

export default ({}) => {

  return (
    <IoShuffleOutline
    class="shuffleIcon"
    classList={{
      '!text-[#7c7c7c]': !PlayerUtils.shuffleOn(),
      '!text-white': PlayerUtils.shuffleOn()
    }}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      PlayerActions.toggleShuffle()
    }}/>
  )
}