import { APIService } from '../../../../services/_index'
import { createSignal } from "solid-js"
import createLocalStore from "@solid-primitives/local-store"
import { Config } from "../../../../config/base-config"
import { createMutable } from "solid-js/store"
import { Utils } from '../../../../utils/_index'
import { EntityTypes, PlayerUtils, PlayerActions } from './_index'
import * as TransactionsTableUtils  from "../../../pages/dashboard/wallet/widgets/TransactionsTable/TransactionsTableUtils"
import { getJWTAccessToken } from '../../../../services/api.service'


let playerStateDataSendTimer
const [store, setStore] = createLocalStore()
export const [playerStateDataSocket, setPlayerStateDataSocket] = createSignal()

export const [hederaAccountId, setHederaAccountId] = createSignal('')
export const [jamBalance, setJamBalance] = createSignal()
export const [pendingJamAmount, setPendingJamAmount] = createSignal()
export const [jamRate, setJamRate] = createSignal()

export const [playQueueDropUpOpen, setPlayQueueDropUpOpen] = createSignal(false)
export const [walletDropUpOpen, setWalletDropUpOpen] = createSignal(false)
export const [fullScreenPlayerOpen, setFullScreenPlayerOpen] = createSignal(false)
export const [visualizerOpen, setVisualizerOpen] = createSignal(false)

export const [addToPlaylistDropUpOpen, setAddToPlaylistDropUpOpen] = createSignal(false)
export const [addToPlaylistEntityType, setAddToPlaylistEntityType] = createSignal()
export const [addToPlaylistEntityId, setAddToPlaylistEntityId] = createSignal()
export const [mobilePlayerCollapsed, setMobilePlayerCollapsed] = createSignal(false)

export const [playQueue, setPlayQueue] = createSignal([])
export const [activePlayerQueue, setActivePlayerQueue] = createSignal([])
export const [activePlayerQueueIndex, setActivePlayerQueueIndex] = createSignal(0)
export const [unshuffledPlayerQueue, setUnshuffledPlayerQueue] = createSignal([])

export const [shuffleOn, setShuffleOn] = createSignal(false)
export const [repeatOn, setRepeatOn] = createSignal(false)
export const [currentVolume, setCurrentVolume] = createSignal(0.0)

export let itemPlayingDetails = createMutable({id: '', type: ''})
export const [songPlayerInstance, setSongPlayerInstance] = createSignal()
export const [songPlayingObject, setSongPlayingObject] = createSignal()
export const [playerLoadingSong, setPlayerLoadingSong] = createSignal(false)
export const [songDuration, setSongDuration] = createSignal(0)
export const [songProgress, setSongProgress] = createSignal(0)
export const [songPaused, setSongPaused] = createSignal(false)
export const [audioMuted, setAudioMuted] = createSignal(false)

export const [songPlaySeconds, setSongPlaySeconds] = createSignal(0)
export const [seekPerformed, setSeekPerformed] = createSignal(false)
export const [visualizerSeekPosition, setVisualizerSeekPosition] = createSignal()
export const [lastRecordedStepTime, setLastRecordedStepTime] = createSignal(0)
export const [songListenPosted, setSongListenPosted] = createSignal(false)

export const [audioBuffer, setAudioBuffer] = createSignal<AudioBuffer>()
export const [audioFrequencyAnalyzer, setAudioFrequencyAnalyzer] = createSignal()
export const [audioGainNode, setAudioGainNode] = createSignal()
export const [uniqueSongListenId, setUniqueSongListenId] = createSignal<number>()
export const [songIdAttachedToCurrentListenId, setSongIdAttachedToCurrentListenId] = createSignal()
export const [uniqueVideoListenId, setUniqueVideoListenId] = createSignal<number>()
export const [listenAlreadyInitiated, setListenAlreadyInitiated] = createSignal(false)
export const [songDataFromWebSocket, setSongDataFromWebSocket] = createSignal(false)

// volume
export const initVolume = () => {
    if (!store[Config.PLAYER_VOLUME]) {
      setVolume(0.5)
    }  else {
        setCurrentVolume(store[Config.PLAYER_VOLUME])
    }
}

export const setVolume = (volume) => {
    setCurrentVolume(volume)
    setStore(Config.PLAYER_VOLUME, volume)
    if(songPlayerInstance()) {
        songPlayerInstance().volume(volume)
    }
}

export const muteUnmuteVolume = () => {
    if(currentVolume() == 0) {
        setVolume(0.5)
    } else {
        setVolume(0.0)
    }
}

// get play queue for player dropup
export const getPlayQueue = async () => {
    let response = await APIService.getRequest({url:'/api/v1/playlist/queue/playlist-queue',
    })
    let songs = []
    if (response && response?.success) {
        for(const element of response.queue) {
            songs.push(element.song.entity)
        }
        setPlayQueue(songs)
        _updateQueueOrderIfPlaylistQueueCurrentlyPlaying()
    }
    return songs
}

const _updateQueueOrderIfPlaylistQueueCurrentlyPlaying = () => {
    // If play queue playing and queue is updated by adding to playlist, changing song order, or deleting playlist song, 
    // update active queue to reflect those changes
    if(songPlayingObject() && itemPlayingDetails.type == EntityTypes.PlayQueue) {
        const currentSongId = songPlayingObject().id

        setUnshuffledPlayerQueue(playQueue())
        setActivePlayerQueue(playQueue())

        // find index of song on active play queue
        let songIndex = 0
        for(let  i = 0; i < activePlayerQueue().length; i++) {
            let song = activePlayerQueue()[i]
            if(song.id == currentSongId) {
                songIndex = i
                break
            }
        }
        setActivePlayerQueueIndex(songIndex)
    }
}

// GET BY ID FUNCTIONS
export const getPlayableAlbumSongs = async (albumId, entitySongs, includesAllEntitySongs = false, runAsynchronously = false) => {
    let fetchedAllSongs = includesAllEntitySongs
    if(includesAllEntitySongs || (runAsynchronously && entitySongs.length > 0)) {
        return {
            fetchedAllSongs: fetchedAllSongs,
            songs: entitySongs
        }
    }
    let response = await APIService.getRequest({url:`/api/v1/music/player/playable-album-songs`, params:{
        albumid: albumId
    }})
    let songs = []
    if(response && response?.success){
        for(let i = 0; i < response.songs.length; i++) {
            songs.push(response.songs[i].entity)
        }
        fetchedAllSongs = true
    }
    return {
        fetchedAllSongs: fetchedAllSongs,
        songs: songs
    }
}

export const getPlayableProfileSongs = async (profileId, entitySongs, includesAllEntitySongs = false, runAsynchronously = false) => {
    let fetchedAllSongs = includesAllEntitySongs
    if(includesAllEntitySongs || (runAsynchronously && entitySongs.length > 0)) {
        return {
            fetchedAllSongs: fetchedAllSongs,
            songs: entitySongs
        }
    }
    let response = await APIService.getRequest({url:`/api/v1/music/player/playable-profile-songs`, params:{
        profileid: profileId
    }})
    let songs = []
    if(response && response?.success){
        for(let i = 0; i < response.songs.length; i++) {
            songs.push(response.songs[i].entity)
        }
        fetchedAllSongs = true
    }
    return {
        fetchedAllSongs: fetchedAllSongs,
        songs: songs
    }
}

export const getPlayablePlaylistSongs = async (playlistId, entitySongs, includesAllEntitySongs = false, runAsynchronously = false) => {
    let fetchedAllSongs = includesAllEntitySongs
    if(includesAllEntitySongs || (runAsynchronously && entitySongs.length > 0)) {
        return {
            fetchedAllSongs: fetchedAllSongs,
            songs: entitySongs
        }
    }
    let response = await APIService.getRequest({url:`/api/v1/music/player/playable-playlist-songs`, params:{
        playlistid: playlistId
    }})
    let songs = []
    if(response && response?.success){ 
        for(let i = 0; i < response.songs.length; i++) {
            songs.push(response.songs[i].entity)
        }
        fetchedAllSongs = true
    }
    return {
        fetchedAllSongs: fetchedAllSongs,
        songs: songs
    }
}

export const getPlayableRadioSongs = async () => {
    let response = await APIService.getRequest({url:`/api/v1/music/player/playable-radio-songs`})
    let songs = []
    if(response && response?.success){
        for(let i = 0; i < response.songs.length; i++) {
            songs.push(response.songs[i].entity)
        }
    }
    return songs
}

export const getJamBalance = async () => {  
    let jamRate = await APIService.getJamRate()
    setJamRate(jamRate)
     
    await APIService.getRequest({url:'/api/v1/wallet/jam-balance',
        allowAPIDataCaching: true, 
        returnAPICachedData: true, 
        expiryPeriodForAPICachedData:1000 * 30, // cache for 30sec
        onSuccess: (response) => {
            if(response?.success){
                setJamBalance(response.jam_balance)
                setPendingJamAmount(response.unpaid_tokens)
            }
        }
    })
   
    
}

export const fetchTransactionDataforListen = async (response) => { 
    await Utils.getWalletDataAndTokenData();
    await TransactionsTableUtils.getTransactionForRecentListen(response.account_id)
} 

export const recordSongListen = async (listen_id: number, songId: number, paidSong: boolean, secondsPlayed: number) => {
    if(!songListenPosted()) {
        setSongListenPosted(true) // request may take time so set this to true first

        await APIService.postRequest({url:`/api/v1/music/player/record-listen-or-video-play`, postData:{
            song_id: songId,
            seconds: Math.round(secondsPlayed),
            listen_id
        }, onSuccess: (response) => {
                if(response && response?.success) {
                    setJamBalance(response.balance)
                    //Update wallet data after every song listen
                    setTimeout(async () => {
                        await fetchTransactionDataforListen(response)
                        PlayerUtils.getJamBalance()
                    }, 0)

                    if(!paidSong){
                        // clear cache so the next page refresh will fetch data with promoted tag not showing
                        // clear all listings cache
                        Utils.clearAllCAchedHomeAndDiscoverListings()
                        // and profile cache
                        Utils.clearCachedAPIdataForSpecificUrls(Utils.urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh())
                    }


                }
                if (window.gtag) {
                    gtag('event', 'listen', {
                        'event_category': 'engagement',
                        'event_label': 'song',
                        'duration': secondsPlayed,
                        'song_id': songId
                    });
                }
            },
            onError:()=>{}
        
        })

        return Promise.resolve()
    } else {
        return Promise.resolve()
    }
}

export const formatSongImagePath = (path) => {
    if(path.substring(0, 2) == '//') {
        return path.substring(1)
    } else {
        return path
    }
}

export const _convertSecondsToJam = (seconds, jamPerMinute) => {
    return (seconds / 60) * jamPerMinute
}


export const resizePage = (onlyRemoveHeightProperty = false) => {
    // refresh page height - fixes issue where page height does not always update immediately on visualizer close
    const mainContainer = document.getElementById("mainContainer")
    if(mainContainer) {
        mainContainer.style.removeProperty('height')
        if(!onlyRemoveHeightProperty){
            setTimeout(() => mainContainer.style.setProperty('height', '100%'), 500)
        }
    }

    const fanmailContainer = document.getElementsByClassName("fanmailContainer");
    if(fanmailContainer.length > 0) {
        fanmailContainer[0].style.removeProperty('height')
        if(!onlyRemoveHeightProperty){
            setTimeout(() => fanmailContainer[0].style.setProperty('height', '100%'), 500)
        }
    }
 }


 export const _removeCurrentSongFromPlayQueue = async () => {
    const currently_playing_song = activePlayerQueue()[activePlayerQueueIndex()]
    // remove song from play queue from back end
    if (currently_playing_song) {
        const currently_playing_song_id = currently_playing_song.id || currently_playing_song.entity.id
        // remove song from play queue
        await APIService.postRequest({url:`/api/v1/playlist/queue/remove-song-from-playlist-queue`, postData:{
            songid: currently_playing_song_id
            }
        })
        getPlayQueue()
    }
}


export const initiateSongListen = async (song) =>{
    await APIService.postRequest({
        url:'/api/v1/music/player/initiate-listen-record',
        postData:{
            entity_type: "song",
            entity_id: song.id
        },
        onSuccess: (response) => {
            if(response?.success){
                setUniqueSongListenId(response.listen_id)
                setSongIdAttachedToCurrentListenId(song.id)
                // start passing player progress data via websocket now that we have the listen id
                sendPlayerStateDataToServerViaWebSocket() 

            }
        },
    })

}

export const recordListenPause = async (listen_id) =>{
    await APIService.postRequest({
      url:`/api/v1/music/player/record-listen-pause`,
      postData:{
        listen_id : listen_id 
      },
      onSuccess:()=>{},
      onError:()=>{},
    })
}

export const recordListenResumptionAfterPause = async (listen_id) =>{
    if(listen_id){
        await APIService.postRequest({
        url:`/api/v1/music/player/record-listen-resumption`,
        postData:{
            listen_id : listen_id 
        },
        onSuccess:()=>{},
        onError:()=>{},
        })
    }
}

export const recordSlide = async (listen_id, slideData) =>{
    if(listen_id){
        await APIService.postRequest({
        url:`/api/v1/music/player/record-slide`,
        postData:{
            listen_id : listen_id, ...slideData,
        },
        onSuccess:()=>{},
        onError:()=>{},
        })
    }
}

////////////////////////////////////////////////////
// Player state websocket API functions
// Here we track song play progress to ensure user can resume 
// from where they left if they switch devices or close browser window
/////////////////////////////////////
export const openPlayerStateWebsocketConnection = async () => {
    const jwtAccessToken = await getJWTAccessToken()
    // We'll pass access token as a query parameter when initiating the player websocket connection
    if (jwtAccessToken) {
        const playerStateWebsocketUrl = `${Utils.socketHost()}/ws/player/?access_token=${jwtAccessToken}`
        setPlayerStateDataSocket(new WebSocket(playerStateWebsocketUrl))
        playerStateDataSocket().onopen = () => {
            getLastPlayerStateDataForUser()
        }
        playerStateDataSocket().onmessage = (event) => { 
            if(event.data) {
                try {
                    const data = JSON.parse(event.data)
                    if(data) {
                        // check if is response to getLastPlayerStateDataForUser fn request
                        if('is_player_state_message' in data) {
                            _processLastPlayerStateData(data)
                        }
                    }
                } catch (e) {}
            }
        }
    }
    // return jwtAccessToken so we know whether to stop trying to open socket
    // connection when jwtAccessToken is null
    return jwtAccessToken
}

export const sendPlayerStateDataToServerViaWebSocket = async () => {
    if (playerStateDataSendTimer) clearTimeout(playerStateDataSendTimer)

    const webSocket = PlayerUtils.playerStateDataSocket()
    if(webSocket && webSocket.readyState != WebSocket.CLOSED && webSocket.readyState != WebSocket.CLOSING) {
        if(
            PlayerUtils.songPlayerInstance()
            && PlayerUtils.songPlayerInstance().playing()
            && PlayerUtils.uniqueSongListenId()
            && PlayerUtils.songPlayingObject()
            && PlayerUtils.songPlayingObject().id == PlayerUtils.songIdAttachedToCurrentListenId()
        ) {
            try {
                PlayerUtils.playerStateDataSocket().send(JSON.stringify({
                    "listen_id": PlayerUtils.uniqueSongListenId(),
                    "current_play_seconds": PlayerUtils.songProgress()
                }))
                playerStateDataSendTimer = setTimeout(() => {
                    sendPlayerStateDataToServerViaWebSocket()
                }, 5000)
            } catch (e) {
                console.log('Error sending play data via ws' + e)
            }
        }

        // Send play data via websocket every 3 sec
        if(PlayerUtils.songPlayerInstance()) {
            playerStateDataSendTimer = setTimeout(() => {
                sendPlayerStateDataToServerViaWebSocket()
            }, 3000)
        }
    } else {
        const jwtAccessToken = await PlayerUtils.openPlayerStateWebsocketConnection()
        // proceed to retry function call if the connection was successful
        if(jwtAccessToken && PlayerUtils.playerStateDataSocket()) {
            setTimeout(() => {
                sendPlayerStateDataToServerViaWebSocket()
            }, 1000)
        }
    }
}

export const getLastPlayerStateDataForUser = async () => {
    // if no song is set on the player, check from server if user 
    // had been playing any song on this or another device before so it's rendered
    // on the player
    if(!songPlayerInstance()) {
        playerStateDataSocket().send(JSON.stringify({
            "get_last_player_state": true
        }))
    }
}

const _processLastPlayerStateData = (jsonWebsocketData) => {
    const data = jsonWebsocketData
    if(data.is_player_state_message && data.success) {
        const listenData = data.listen_data
        const playerStateData = data.player_state_data
        const lastSongProgressSeconds = playerStateData.last_progress_seconds
        // show last playing song on player
        if(listenData) _showLastPlayingSongOnPlayer(listenData, lastSongProgressSeconds)
    }
}

const _showLastPlayingSongOnPlayer = async (listenData, lastSongProgressSeconds) => {
    const entityType = listenData.entity_type
    const entityId = listenData.entity_id
    if(entityType == EntityTypes.Song) {
        const songPlayData = await getSongPlayData(entityId)
        // Ensure no other song is on player before setting last played song on the player
        if(!songPlayerInstance() && songPlayData) {
            const songPlayStartSeconds = lastSongProgressSeconds || 0
            setListenAlreadyInitiated(true)
            setSongDataFromWebSocket(true)
            setUniqueSongListenId(listenData.listen_id)
            setSongPlaySeconds(listenData.seconds_played)
            setSongIdAttachedToCurrentListenId(songPlayData.id)
            PlayerActions.playItem({
                entityType: EntityTypes.Song, 
                entityId: songPlayData.id, 
                entityJSON: songPlayData,
                playImmediately: false,
                songPlayStartSeconds: songPlayStartSeconds
            })
        }
    }
}

const getSongPlayData = async (songId) => {
    // Ensure no other song is on player
    const query = {
        'songid': songId
    }
    let songPlayData = await APIService.getRequest({
        url:'/api/v1/music/player/song-play-data', params: query,
        onSuccess: (response) => {
            if(response && response?.success){
                return response.song
            }
        }
    })
    return songPlayData
}

export const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

////////////////////////////////
// End Player state functions
////////////////////////////////////////////////////