import { Show, onMount, createSignal } from "solid-js"
import { Utils } from "../../../../../../utils/_index"
import { IoVolumeLowOutline, IoVolumeMediumOutline, IoVolumeHighOutline, IoVolumeMuteOutline } from "solid-icons/io"; // IonIcons
import { PlayerUtils } from '../../../utils/_index'
import Moveable from "moveable"
import "./volume-control.css"

export default ({showOnMobile}) => {
  
  const [isSmallerScreen, setIsSmallerScreen] = createSignal(false)
  const [showTrackerDropup, setShowTrackerDropup] = createSignal(false)
  let volumeTrackerWrapperRef, volumeTrackerRef, volumeDragIconRef
  let volumeDraggedPosition = 0
  let moveable


  const checkScreenSize = () => {
    if(window.innerWidth <= 960) {
      setIsSmallerScreen(true)
      volumeTrackerRef.style.removeProperty('width')
      volumeDragIconRef.style.removeProperty('left')
    } else {
      setIsSmallerScreen(false)
      volumeTrackerRef.style.removeProperty('height')
      volumeDragIconRef.style.removeProperty('bottom')
    }
  }

  const trackerClick = (clientX, clientY) => {
    if(isSmallerScreen()) {
      const clickPosition =  volumeTrackerWrapperRef.getBoundingClientRect().bottom - clientY
      adjustVolume(clickPosition)
    } else {
      const clickPosition = clientX - volumeTrackerWrapperRef.getBoundingClientRect().left
      adjustVolume(clickPosition)
    }
  }

  const adjustVolume = (clickPosition) => {
    let trackerWrapperLength
    let dimensions

    if(isSmallerScreen()) {
      trackerWrapperLength = volumeTrackerWrapperRef.getBoundingClientRect().height
      dimensions = clickPosition / trackerWrapperLength * 100

      volumeTrackerRef.style.height = `${dimensions}%`
      volumeDragIconRef.style.bottom = `${dimensions}%`
    } else {
      trackerWrapperLength = volumeTrackerWrapperRef.getBoundingClientRect().width
      dimensions = clickPosition / trackerWrapperLength * 100

      volumeTrackerRef.style.width = `${dimensions}%`
      volumeDragIconRef.style.left = `${dimensions}%`
    }
    // volume is between 0.0 and 1.0
    const volume = (dimensions/100).toFixed(1)
    PlayerUtils.setVolume(volume)
  }

  const initVolumeDraggableTrackerIcon = () => {
    if(moveable) {
      moveable.destroy()
      moveable = undefined
    }

    moveable = new Moveable(volumeTrackerWrapperRef, {
        target: volumeDragIconRef,
        container: volumeTrackerWrapperRef,
        draggable: true,
        origin: false,
        keepRatio: true,
        hideDefaultLines: true,
        edge: false,
    })

    /* draggable */
    moveable.on("dragStart", ({ target, clientX, clientY }) => {
        // remove existing transitions for smooth dragging
        volumeTrackerRef.style.setProperty('transition', 'none')
        volumeDragIconRef.style.setProperty('transition', 'none')
    })
    .on("drag", ({
        target, transform,
        left, top, right, bottom,
        beforeDelta, beforeDist, delta, dist,
        clientX, clientY,
    }) => {
        let trackerWrapperLength

        if(isSmallerScreen()) {
          trackerWrapperLength = volumeTrackerWrapperRef.getBoundingClientRect().height
          volumeDraggedPosition = bottom
        } else {
          trackerWrapperLength = volumeTrackerWrapperRef.getBoundingClientRect().width
          volumeDraggedPosition = left
        }

        if(volumeDraggedPosition < 0) {
          volumeDraggedPosition = 0
        }

        if(volumeDraggedPosition > trackerWrapperLength) {
          volumeDraggedPosition = trackerWrapperLength
        }

        adjustVolume(volumeDraggedPosition)
    })
    .on("dragEnd", ({ target, isDrag, clientX, clientY }) => {
        // revert back to original transitions
        volumeTrackerRef.style.removeProperty('transition')
        volumeDragIconRef.style.removeProperty('transition')
    })
  }

  onMount(() => {
    PlayerUtils.initVolume()
     // volume drag icon
    Utils.docReady(() => {
      checkScreenSize()
      initVolumeDraggableTrackerIcon()

      const windowResizeObserver = new ResizeObserver(entries => {
          checkScreenSize()
          initVolumeDraggableTrackerIcon()
      })
      windowResizeObserver.observe(document.body);
    })
  })

  return (
    <div
    class="volumeControlsWrapper"
    classList={{
      'volumeHiddenOnDesktop': showOnMobile,
      'volumeHiddenOnMobile': !showOnMobile
    }}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
    }}
    >
      <div onClick={(e) => {
        if(!isSmallerScreen()) {
          PlayerUtils.muteUnmuteVolume()
        } else {
          // volume not adjustable on iOS hence dont show volume tracker dropup
          if(!Utils.deviceIsiOS()) {
            setShowTrackerDropup(!showTrackerDropup())
          } else {
            // mute/unmute for iOS devices
            PlayerUtils.songPlayerInstance().mute(!PlayerUtils.audioMuted())
          }
        }
      }}>
        <Show when={PlayerUtils.currentVolume() > 0 && !PlayerUtils.audioMuted()} fallback={
          <IoVolumeMuteOutline/>
        }>
          <>
            {PlayerUtils.currentVolume() < 0.35  ? <IoVolumeLowOutline/> : 
            (PlayerUtils.currentVolume() < 0.65 ? <IoVolumeMediumOutline/> : <IoVolumeHighOutline/>)} 
          </>
        </Show>
      </div>

      <div
      ref={volumeTrackerWrapperRef}
      class="volumeTrackerWrapper"
      classList={{
        'showVolumeTracker': !isSmallerScreen() || showTrackerDropup()
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        trackerClick(e.clientX, e.clientY)
      }}>
        <div
        ref={volumeTrackerRef}
        class="tracker"
        style={{
          width: `${!isSmallerScreen() ? (PlayerUtils.currentVolume() / 1 * 100): ''}%`,
          height: `${isSmallerScreen() ? (PlayerUtils.currentVolume() / 1 * 100): ''}%`,
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          trackerClick(e.clientX, e.clientY)
        }}>
          <div
          ref={volumeDragIconRef}
          class="dragIcon"
          style={{
            left: `${!isSmallerScreen() ? (PlayerUtils.currentVolume() / 1 * 100): ''}%`,
            bottom: `${isSmallerScreen() ? (PlayerUtils.currentVolume() / 1 * 100): ''}%`,
          }}/>
        </div>
      </div>
    </div>
  )
}
