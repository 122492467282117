import { Show } from "solid-js"
import { PlayerUtils, PlayerActions } from '../../../../../utils/_index'
import { IoPlaySkipBackOutline, IoPlay, IoPlayOutline, IoPauseOutline, IoPlaySkipForwardOutline } from "solid-icons/io"; // IonIcons
import ShuffleControl from "../../../shuffle-control/shuffle-control"
import RepeatControl from "../../../repeat-control/repeat-control"
import { Utils } from "../../../../../../../../utils/_index";
export default ({}) => {

    return (
        <div 
        class="playSkipControlsWrapper"
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }}
        >
            {/* Shuffle - Mobile */}
            <Show when={PlayerUtils.songPlayerInstance()}>
                <ShuffleControl/>
            </Show>
            
            {/* Skip to Previous */}
            <IoPlaySkipBackOutline
            class="skipIcon"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                PlayerActions.skip('previous')
            }}/>

            {/* Play button */}
            <div
            class="playIcon"
            onClick={async (e) => {

                e.preventDefault()
                e.stopPropagation()

                if(Utils.showLoginModal()) {
                    Utils.triggerLoginModal()
                    return
                }

                if (!PlayerUtils.songPlayerInstance()) {
                    let item = Utils.randomItemToPlay
                    await PlayerActions.playItem({
                        entityType: item.entity.type,
                        entityId: item.entity.id,
                        entityJSON: item,
                    })
                }

                if(!PlayerUtils.playerLoadingSong()) {
                    PlayerActions.playPauseSong()
                }


            }}>
                <Show when={PlayerUtils.songPlayingObject() && !PlayerUtils.songPaused() && !PlayerUtils.playerLoadingSong()} fallback={
                    <Show when={!PlayerUtils.playerLoadingSong()} fallback={
                        <div
                        class="spinner spinner--small"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        ></div>
                    }>
                        <IoPlay/>
                    </Show>
                }>
                    <IoPauseOutline/>
                </Show>
            </div>

            {/* Skip to Next */}
            <IoPlaySkipForwardOutline
            class="skipIcon"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                PlayerActions.skip('next')
            }}/>
            
            {/* Repeat - Mobile */}
            <Show when={PlayerUtils.songPlayerInstance()}>
                <RepeatControl/>
            </Show>
        </div>
    )
}
