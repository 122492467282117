import {
  RiSystemCloseLine,
  RiArrowsArrowLeftSLine,
} from 'solid-icons/ri' // Remix Icons
import Dismiss from 'solid-dismiss'
import { createSignal } from 'solid-js'
import { Utils } from '../../../utils/_index'
import { useNavigate } from '@solidjs/router'

export const SidebarHeader = (props) => {
  const navigate = useNavigate()
  let btnInfo
  const [infoOpen, setInfoOpen] = createSignal(false)

  return (
    <div class="sidebarHeader">
      {/* Logo */}
      <div class="logoContainer">
        <div class="logoWrapper">
          <a
            href={Utils.userLoggedIn() ? '/' : '/home'}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(Utils.userLoggedIn() ? '/' : '/home')
              Utils.closeSidebarMobile()
            }}
          >
            <svg
              viewBox="0 0 101 15"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <path
                  d="M8.88671875,13.8671875 L8.88671875,15 L6.89453125,15 C4.6484375,15 3.61328125,13.9648438 3.61328125,11.8164062 L3.61328125,6.69921875 L0.5859375,6.69921875 L0.5859375,5.5859375 L3.61328125,5.5859375 L3.61328125,1.1328125 L4.84375,1.1328125 L4.84375,5.5859375 L8.88671875,5.5859375 L8.88671875,6.69921875 L4.84375,6.69921875 L4.84375,11.6210938 C4.84375,13.1445312 5.390625,13.8671875 7.08984375,13.8671875 L8.88671875,13.8671875 Z M15.9960938,15.234375 C13.3007812,15.234375 11.6796875,13.4960938 11.6796875,11.015625 L11.6796875,5.5859375 L12.9101562,5.5859375 L12.9101562,10.8398438 C12.9101562,12.7929688 14.0234375,14.0625 15.9765625,14.0625 C17.9296875,14.0625 19.0625,12.7929688 19.0625,10.8398438 L19.0625,5.5859375 L20.2929688,5.5859375 L20.2929688,11.015625 C20.2929688,13.4960938 18.6328125,15.234375 15.9960938,15.234375 Z M25.2734375,9.8828125 L25.2734375,15 L24.0429688,15 L24.0429688,5.5859375 L25.2734375,5.5859375 L25.2734375,7.578125 C25.6640625,6.6015625 26.8164062,5.3515625 28.6523438,5.3515625 C31.0742188,5.3515625 32.7539062,7.1484375 32.7539062,9.66796875 L32.7539062,15 L31.5234375,15 L31.5234375,9.8828125 C31.5234375,7.8125 30.2539062,6.50390625 28.3984375,6.50390625 C26.6210938,6.50390625 25.2734375,7.8515625 25.2734375,9.8828125 Z M40.5078125,5.3515625 C43.3007812,5.3515625 45.2148438,7.44140625 45.2539062,10.2734375 L37.03125,10.2734375 L37.03125,10.2929688 C37.03125,12.3242188 38.4570312,14.0820312 40.6835938,14.0820312 C42.8515625,14.0820312 43.671875,12.9101562 43.9453125,12.4804688 L45.1367188,12.4804688 C44.921875,13.28125 43.6914062,15.234375 40.6835938,15.234375 C37.7734375,15.234375 35.78125,13.0078125 35.78125,10.2929688 C35.78125,7.51953125 37.734375,5.3515625 40.5078125,5.3515625 Z M40.5078125,6.42578125 C38.671875,6.42578125 37.4609375,7.578125 37.109375,9.21875 L43.90625,9.21875 C43.5351562,7.55859375 42.3242188,6.42578125 40.5078125,6.42578125 Z"
                  id="path-1"
                ></path>
                <path
                  d="M47.7929687,15 L47.7929687,11.171875 L51.4453125,11.171875 L51.4453125,15 L47.7929687,15 Z M61.5039062,7.48046875 L58.1640625,7.48046875 L58.1640625,15 L54.9804687,15 L54.9804687,7.48046875 L52.3632812,7.48046875 L52.3632812,4.8828125 L54.9804687,4.8828125 L54.9804687,3.515625 C54.9804687,1.73828125 56.015625,0.625 58.125,0.625 L62.734375,0.625 L62.734375,3.0078125 L59.453125,3.0078125 C58.515625,3.0078125 58.1640625,3.41796875 58.1640625,4.2578125 L58.1640625,4.8828125 L61.5039062,4.8828125 L61.5039062,7.48046875 Z M66.5625,9.66796875 L66.5625,15 L63.3984375,15 L63.3984375,4.8828125 L66.5625,4.8828125 L66.5625,6.40625 C66.9335937,5.76171875 68.0273437,4.66796875 69.7851562,4.66796875 C71.4648437,4.66796875 72.7148437,5.46875 73.359375,6.77734375 C73.828125,5.91796875 74.921875,4.66796875 77.03125,4.66796875 C79.5507812,4.66796875 81.1328125,6.46484375 81.1328125,9.0234375 L81.1328125,15 L77.9492187,15 L77.9492187,9.66796875 C77.9492187,8.3203125 77.1679687,7.48046875 75.9179687,7.48046875 C74.6679687,7.48046875 73.8476562,8.33984375 73.8476562,9.66796875 L73.8476562,15 L70.6640625,15 L70.6640625,9.66796875 C70.6640625,8.3203125 69.8828125,7.48046875 68.6328125,7.48046875 C67.3828125,7.48046875 66.5625,8.33984375 66.5625,9.66796875 Z"
                  id="path-2"
                ></path>
              </defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="tune-2"
                  transform="translate(0.976562, -0.414062)"
                  fill-rule="nonzero"
                >
                  <g id="tune">
                    <g id="tune.fm" transform="translate(17.968750, 0.000000)">
                      <g id="tune">
                        <use fill="#000000" xlink:href="#path-1"></use>
                        <use fill="#FF0057" xlink:href="#path-1"></use>
                      </g>
                      <g id=".fm">
                        <use fill="#000000" xlink:href="#path-2"></use>
                        <use fill="#FF0057" xlink:href="#path-2"></use>
                      </g>
                    </g>
                    <g
                      id="hearo-token"
                      transform="translate(0.000000, 0.781250)"
                      fill="#FF0057"
                    >
                      <path
                        d="M7.03125,14.0625 C3.14799785,14.0625 0,10.9145021 0,7.03125 C0,3.14799785 3.14799785,0 7.03125,0 C10.9145021,0 14.0625,3.14799785 14.0625,7.03125 C14.0625,10.9145021 10.9145021,14.0625 7.03125,14.0625 Z M11.8754112,7.10913463 C11.8754112,4.30528846 9.69942434,2.02067309 7.00534539,2.02067309 C4.31126645,2.02067309 2.13527961,4.30528846 2.13527961,7.10913463 C2.13527961,9.03028846 3.17146383,10.7177885 4.69983553,11.5745192 C5.08840461,11.8600962 5.52878289,11.9639423 5.86554275,11.8081731 C6.48725328,11.5225962 6.59087172,10.4841346 6.12458883,9.54951924 C5.65830592,8.5889423 4.80345395,8.06971154 4.18174342,8.35528846 C4.05222039,8.43317309 3.92269736,8.53701924 3.84498355,8.66682691 C3.61184211,8.27740385 3.48231908,7.8360577 3.48231908,7.34278846 C3.48231908,5.68125 5.0625,4.35721154 7.00534539,4.35721154 C8.94819078,4.35721154 10.5283717,5.70721154 10.5283717,7.34278846 C10.5283717,7.8360577 10.3729441,8.30336537 10.1398026,8.71875 C10.0361842,8.53701924 9.90666117,8.40721154 9.75123355,8.32932691 C9.12952303,8.04375 8.27467105,8.5889423 7.83429275,9.54951924 C7.39391447,10.5100962 7.5234375,11.5225962 8.14514803,11.8081731 C8.45600328,11.9639423 8.84457236,11.8860577 9.20723684,11.6264423 C10.7874178,10.7956731 11.8754112,9.08221154 11.8754112,7.10913463 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>

          <div
           class="closeIconWrapper"
           onClick={() => Utils.toggleSidebarDisplay()}
          >
            <RiArrowsArrowLeftSLine
              class="closeIcon primary-color"
            />
          </div>
        </div>

        {/* Beta Message */}
        <div class="logoBottomContainer">
          <span class="betaIcon">Beta</span>
          <i ref={btnInfo} class="ri-information-line"></i>
        </div>
      </div>

      <div class="absolute z-50 w-full mt-4">
        <Dismiss menuButton={btnInfo} open={infoOpen} setOpen={setInfoOpen}>
          <div class="flex justify-center flex-wrap w-full">
            <div class="infoDropdown">
              <div class="w-full px-1 py-1">
                <div
                  onClick={() => setInfoOpen(false)}
                  class=" float-right cursor-pointer"
                >
                  <RiSystemCloseLine class="text-gray-200 text-2xl" />
                </div>
              </div>

              <span class="description">
                Tune.Fm is a global music economy that enables fans to discover
                new music from artists around the world. Support artists
                directly by streaming their music, and earn JAM tokens
                discovering new music. When the music gets played, the artist
                gets paid.
              </span>

              <div class="linksList">
                <a
                  onClick={() => {
                    navigate('/terms')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  Terms of use
                </a>
                <a
                  onClick={() => {
                    navigate('/privacy-policy')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  {' '}
                  Privacy Policy
                </a>
                
                {/* commenting it for now till later
                <a
                  onClick={() => {
                    navigate('/report-content')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  Report content
                </a>
                */} 
                <a
                  onClick={() => {
                    navigate('/artist-agreement')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  Artist agreement
                </a>
                <a
                  onClick={() => {
                    navigate('/copyright')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  Copyright Notice
                </a>
                <a
                  onClick={() => {
                    navigate('/support')
                    setInfoOpen(false)
                    Utils.closeSidebarMobile()
                  }}
                >
                  Contact support
                </a>
              </div>
            </div>
          </div>
        </Dismiss>
      </div>
    </div>
  )
}
