import { onMount, createSignal, Show } from "solid-js"
import "./song-tracker-widget.css"
import { PlayerUtils, PlayerActions } from '../../../../../utils/_index'
import { Utils } from "../../../../../../../../utils/_index"
import Moveable from "moveable"

export default ({}) => {

    let trackerWrapperRef, trackerRef, dragIconRef
    let draggedPosition = 0
    let moveable
    const [isSeeking, setIsSeeking] = createSignal(false)
    const [seekTimeInSeconds, setSeekTimeInSeconds] = createSignal()
    const [seekDragIconLeftMargin, setSeekDragIconLeftMargin] = createSignal(0)
    const [trackerWidthWhileSeeking, setTrackerWidthWhileSeeking] = createSignal(0)



    const initDraggableTrackerIcon = () => {
        if(moveable) {
            moveable.destroy()
            moveable = undefined
        }
        
        moveable = new Moveable(trackerWrapperRef, {
            target: dragIconRef,
            // If the container is null, the position is fixed. (default: parentElement(trackerWrapperRef))
            container: trackerWrapperRef,
            draggable: true,
            origin: false,
            keepRatio: true,
            hideDefaultLines: true,
            // Resize, Scale Events at edges.
            edge: false,
            // throttleDrag: trackerWrapperRef.getBoundingClientRect().width,
        })

        /* draggable */
        moveable.on("dragStart", ({ target, clientX, clientY }) => {
            // PlayerUtils.songPlayerInstance().pause()
          
            // remove existing step transitions for smooth dragging
            trackerRef.style.setProperty('transition', 'none')
            dragIconRef.style.setProperty('transition', 'none')
            // display time tooltip
            setIsSeeking(true)
            setSeekTimeInSeconds(PlayerUtils.songProgress())
            setSeekDragIconLeftMargin(
                PlayerUtils.songPlayerInstance() ? (PlayerUtils.songProgress() / PlayerUtils.songDuration() * 100): 0
            )
            setTrackerWidthWhileSeeking(
                PlayerUtils.songPlayerInstance() ? (PlayerUtils.songProgress() / PlayerUtils.songDuration() * 100): 0
            )
        })
        .on("drag", ({
            target, transform,
            left, top, right, bottom,
            beforeDelta, beforeDist, delta, dist,
            clientX, clientY,
        }) => {
            const trackerWrapperWidth = trackerWrapperRef.getBoundingClientRect().width
            draggedPosition = clientX - trackerWrapperRef.getBoundingClientRect().left

            if(draggedPosition < 0) {
                draggedPosition = 0
            }

            if(draggedPosition > trackerWrapperWidth) {
                draggedPosition = trackerWrapperWidth
            }
            
            // set current seek time for display on drag tooltip
            setSeekTimeInSeconds(calculateCurrentSeekSeconds(draggedPosition))

            let width = draggedPosition / trackerWrapperWidth * 100
            setTrackerWidthWhileSeeking(width)
            setSeekDragIconLeftMargin(width)
        })
        .on("dragEnd", ({ target, isDrag, clientX, clientY }) => {
            // PlayerUtils.songPlayerInstance().play()
           
            // revert back to original transitions used for step
            trackerRef.style.removeProperty('transition')
            dragIconRef.style.removeProperty('transition')

            // hide time tooltip with slight delay
            setTimeout(() => {
                setIsSeeking(false)
            }, 300)
            seekSong(draggedPosition)
        })
    }

    const calculateCurrentSeekSeconds = (clickPosition) => {
        const trackerWrapperWidth = trackerWrapperRef.getBoundingClientRect().width
        let width = clickPosition / trackerWrapperWidth * 100
        trackerRef.style.width = `${width}%`
        dragIconRef.style.left = `${width}%`
        if(clickPosition >= 0 && PlayerUtils.songPlayerInstance()) {
            const seekSeconds = (clickPosition/trackerWrapperWidth) * PlayerUtils.songDuration()
            return seekSeconds
        }
        return null
    }

    const seekSong = (clickPosition) => {
        const currentSeekTime = calculateCurrentSeekSeconds(clickPosition)
        if(currentSeekTime) {
            PlayerActions.seek(currentSeekTime)
        }
    }

    onMount(() => {
        Utils.docReady(() => {
            initDraggableTrackerIcon()

            const windowResizeObserver = new ResizeObserver(entries => {
                initDraggableTrackerIcon()
            })
            windowResizeObserver.observe(document.body);
        })
    })

    return (
        <div 
        class="trackerAndTimeWrapper"
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }}
        >
            <span class="timeElapsed">{Utils.getFormattedSongLength(PlayerUtils.songProgress())}</span>

            <div
            class="trackerWrapperOuter"
            onClick={(e) => {
                const clickPosition = e.clientX - trackerWrapperRef.getBoundingClientRect().left
                seekSong(clickPosition)
            }}>
                <div
                ref={trackerWrapperRef}
                class="trackerWrapper">
                    <div
                    id="songTracker"
                    ref={trackerRef}
                    class="tracker"
                    style={{
                        width: `${
                            isSeeking() 
                                ? trackerWidthWhileSeeking() 
                                : PlayerUtils.songPlayerInstance() ? (PlayerUtils.songProgress() / PlayerUtils.songDuration() * 100): 0
                        }%`,
                    }}
                    onClick={(e) => {
                        const clickPosition = e.clientX - trackerWrapperRef.getBoundingClientRect().left
                        seekSong(clickPosition)
                    }}>
                    </div>

                    <div
                    id="songTrackerDragHandle"
                    ref={dragIconRef}
                    class="dragHandle"
                    style={{
                        left: `${
                            isSeeking() 
                                ? seekDragIconLeftMargin() 
                                : PlayerUtils.songPlayerInstance() ? (PlayerUtils.songProgress() / PlayerUtils.songDuration() * 100): 0
                        }%`,
                    }}>
                        <Show when={isSeeking() && seekTimeInSeconds()}>
                            <div class="dragTimeTooltip">
                                {Utils.getFormattedSongLength(seekTimeInSeconds())}
                            </div>
                        </Show>
                        <div class="dragIcon"/>
                    </div>
                </div>
            </div>

            <span class="timeSongLength">{Utils.getFormattedSongLength(PlayerUtils.songDuration())}</span>
        </div>
    )
}
