import { Show, onMount, createEffect } from "solid-js"
import "./wallet-dropup.css"
import { Utils } from "../../../../../utils/_index"
import { PlayerUtils } from '../../utils/_index'
import TransactionsTable from "../../../../pages/dashboard/wallet/widgets/TransactionsTable/TransactionsTable";
import LoadingPageIndicator from "../../../widgets/loading-page-indicator"
import { A, useNavigate  } from "@solidjs/router"
import { SolidBottomsheet } from "solid-bottomsheet"
import '../../../../shared/styles/solid-bottomsheet.css'
import $ from 'jquery'

export default ({wallet}) => {
  const navigate = useNavigate()
  const walletBottomsheetDropupChildDivId = `walletBottomsheetDropup`
  const walletBottomsheetDropupParentDivId = `walletBottomsheetDropupParent`

  const listenForBottomsheetActions = () => {
    createEffect(() => {
      //////////////////////////////////////////////////////////////////
      // Detect clicks outside dropup
      $('.sb-overlay').on('click', (event) => {
        if ($(event.target).closest('.sb-content').length > 0) {
          // Click occurred on or inside the content div ('sb-content'), do nothing
          return
        }
        PlayerUtils.setWalletDropUpOpen(false)
      })

      //////////////////////////////////////////////////////////////////
      // Detect music actions dropup dismiss by dragging down
      const walletBottomsheetDropupChildDiv = document.getElementById(walletBottomsheetDropupChildDivId)
      // Get the parent of the walletBottomsheetDropupChildDiv and assign id attribute
      walletBottomsheetDropupChildDiv.parentNode.setAttribute('id', walletBottomsheetDropupParentDivId)

      // Mutation observer to monitor walletBottomsheetDropupParentDivId style attribute changes
      let walletBottomsheetDropupObserver = new MutationObserver((mutations) => {
        mutations.forEach(function(mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            // Handle the style attribute changes
            const walletBottomsheetDropupParentStyle = 
            window.getComputedStyle(document.getElementById(walletBottomsheetDropupParentDivId))
            const parentHeight = parseFloat(walletBottomsheetDropupParentStyle.height.replace(/px/g, ''))

            // Get the transform attribute from the computed style
            var transformMatrix = walletBottomsheetDropupParentStyle.transform

            // Get transalteY to use to compute dropup drag percentage
            const translateY = Utils.getTranslateYValueFromTransformMatrix(transformMatrix)
            // if dropup is dragged down to a third  of the screen, dismiss
            if(translateY/parentHeight >= 0.66) {
              PlayerUtils.setWalletDropUpOpen(false)
              // stop observing for mutations
              walletBottomsheetDropupObserver.disconnect()
            }
          }
        })
      })
  
      // Observer dropup parent for attribute changes
      walletBottomsheetDropupObserver.observe(
        document.getElementById(walletBottomsheetDropupParentDivId), 
        { attributes: true }
      )
    })
  }


  onMount(() => {
    Utils.docReady(() => {
      listenForBottomsheetActions()
    })
  })

  return (
    <SolidBottomsheet 
      variant="default"
      onClose={() => {
        PlayerUtils.setWalletDropUpOpen(false)
      }}
    >
      <div id={walletBottomsheetDropupChildDivId} class="bottomsheet-dropup-container">
        <div class="dropupTopBar">
          <A
          class="goToWalletButtonWrapper"
          href={'/my-account/wallet'}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate('/my-account/wallet')
            PlayerUtils.setWalletDropUpOpen(false)
          }}>
            <i class="ri-external-link-line"></i>
            <span>Go to wallet</span>
          </A>

          <i
          class="ri-close-line dropupCloseIcon"
          onClick={() => PlayerUtils.setWalletDropUpOpen(false)}
          ></i>
        </div>
        <div class="walletDropupContainer">
          <Show when={wallet() && PlayerUtils.jamRate()}>
            <TransactionsTable accountId={wallet().hedera_account_id} jamRate={PlayerUtils.jamRate} isWalletDropUp={true}/>
          </Show>

          <Show when={!wallet() || !PlayerUtils.jamRate()}>
            <LoadingPageIndicator/>
          </Show>
        </div>
      </div>
    </SolidBottomsheet>
  )
}
