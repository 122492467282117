import { createSignal } from "solid-js";
import { Config } from '../config/base-config'
import { APIService } from '../services/_index'
import $ from 'jquery'
import createLocalStore from "@solid-primitives/local-store"
import { Observable } from 'rxjs'
import { createMutable } from "solid-js/store"
import { countriesRegions } from "./countriesRegions"
const regionNames = new Intl.DisplayNames(['en'], {type: 'region'})

const [store, setStore] = createLocalStore()
export const filterSearchInputs = createMutable({
  location: "",
  genre: "",
  instrument: "",
});
export const [urlFiltersLoaded, setUrlFiltersLoaded] = createSignal(false)

export let filterSelectionObservable
export let filterSelectionSubscriber

export let mapFiltersObservable
export let mapFiltersSubscriber

export let searchTextObservable
export let searchTextSubscriber

export let filterUrlToNavigateObservable 
export let filterUrlToNavigateSubscriber

export const [instrumentAutocompleteOpen, setInstrumentAutocompleteOpen] = createSignal(false)
export const [genreAutocompleteOpen, setGenreAutocompleteOpen] = createSignal(false)
export const [locationAutocompleteOpen, setLocationAutocompleteOpen] = createSignal(false)


let sidebarSearchTimeout

// SIDEBAR SEARCH
export function setSearchQuery(query) {
  if(sidebarSearchTimeout) clearTimeout(sidebarSearchTimeout)
  
  // run search after 1200 ms of no
  sidebarSearchTimeout = setTimeout(() => {
    if (query === '') {
      removeSearchKey('q')
    } else {
      setSearchKey('q', query)
    }
    // notify subscribers of search event
    if(searchTextSubscriber) searchTextSubscriber.next()
  }, 1200)
}

function setSearchKey(key, value) {
  let currentQueries = window.location.search
  let newQueries

  if (new RegExp(key + '=').test(currentQueries)) {
    // If we have this query already
    newQueries = currentQueries.replace(
      new RegExp(key + '=' + '[^&]*'), key + '=' + value)
  } else if (currentQueries === '') {
    // If we have no queries
    newQueries = '?' + key + '=' + value
  } else {
    // If we have some other queries
    newQueries = currentQueries + '&' + key + '=' + value
  }

  // check if user on other page other than discover page (that has all nouns: artists, albums songs and drops) 
  // so as to navigate search to discover page
  const regex = /(?=.*\artists\b)(?=.*\albums\b)(?=.*\songs\b)(?=.*\drops\b)/
  if (!(regex.test(window.location.pathname)) && key=='q') {
    filterUrlToNavigateSubscriber.next({url : '/artists-albums-songs-drops/' + newQueries})
  } else {
    let newLocation = window.location.toString().match(/^[^\?]*/gi)[0] + newQueries
    history.replaceState({}, window.location, newLocation)
  }
}


function removeSearchKey(key) {
  let newLocation = window.location.toString().replace(new RegExp(key + '=' + '[^\&]*'), '')

  if (/\?$/.test(newLocation)) {
    newLocation = newLocation.replace(/\?$/, '')
  }

  history.replaceState({}, window.location, newLocation)
}


// GENRE/INSTRUMENT LOCATION FILTER HANDLERS
export const searchFilterSelection = (callback, ...params) => {
  let url = window.location.toString().match(/[^\?]*/)[0];
  if (!Config.ALL_NOUNS.some((substring) => url.includes(substring))) {
    setDefaultNoun("artists");
  }

  callback(...params);
}

export const selectLocation = (location) => {
  filterSearchInputs.location = location
  setLocationAutocompleteOpen(false);

  if (location != "") {
      recordFilterSearch(location, "location");

      location = formatLocationFilterString(location);
      setLocationFilter(location);
  } else {
      setLocationFilter(null);
  }
};

export const selectGenre = async (genre) => {
  filterSearchInputs.genre = genre;
  setGenreAutocompleteOpen(false);

  if (genre != "") {
      recordFilterSearch(genre, "genre");

      genre = formatGenreFilterString(genre);
      setGenreFilter(genre);
  } else {
      setGenreFilter(null);
  }
};

export const selectInstrument = (instrument) => {
  filterSearchInputs.instrument = instrument;
  setInstrumentAutocompleteOpen(false);

  if (instrument != "") {
      recordFilterSearch(instrument, "instrument");

      instrument = formatInstrumentFilterString(instrument);
      setInstrumentFilter(instrument);
  } else {
      setInstrumentFilter(null);
  }
};

const recordFilterSearch = async (term, type) => {
  let params = {
      term: term,
      type: type,
  };
  await APIService.getRequest({
      url:"/api/v0/accounts/add_filter_search",
      params,
      addAuthHeader:false
  });
};

export const clearSearchFilterInputs = () => {
  filterSearchInputs.location = ''
  filterSearchInputs.genre = ''
  filterSearchInputs.instrument = ''
};

// end genre/instrument/location filter handlers

// DISCOVER FILTERS RANKING AND TIME

export function setRankingAndTime(ranking, time, timeFullText, has_nfts_promoted_or_free_songs = null) {
  let storedExtraFilters = JSON.parse(store[Config.RANKING_TIME_FILTERS])
  let rankingAndTime
  if(ranking) {
    rankingAndTime = {ranking: ranking, time: storedExtraFilters.time}
    setStore(Config.RANKING_TIME_FILTERS, JSON.stringify(rankingAndTime))
  }
  if(time) {
    setStore(Config.RANKING_TIME_FILTERS, JSON.stringify({ranking: 'hottest', time: time}))
    rankingAndTime = {ranking: 'hottest', time: time}
  }

  if(has_nfts_promoted_or_free_songs) {
    rankingAndTime = {has_nfts_promoted_or_free_songs: has_nfts_promoted_or_free_songs}
  }

  rankingAndTime['timeFullText'] = timeFullText
  filterSelectionSubscriber.next(rankingAndTime)

  $('#hottestNewestDropdownListDesktop').hide()
  $('#hottestNewestDropdownListMobile').hide()
}

export const setDefaultNoun= (noun) => {
  let urlFiltersJSON = JSON.parse(store[Config.URL_FILTERS])
  urlFiltersJSON['noun'] = noun

  setStore(Config.URL_FILTERS, JSON.stringify(urlFiltersJSON))
}

export const setNounFilter = (noun) => {
  // clear any stored url filters when any noun is being set
  setStore(Config.URL_FILTERS, JSON.stringify({}))

  if(noun == 'map') {
    noun = 'artists-map'
  }

  addFilterToLocalStorageAndNavigate(noun, 'noun')
}

export const setGenreFilter = (genre) => {
  if(genre) {
    addFilterToLocalStorageAndNavigate(genre, 'genre')
  } else {
     removeFilterFromLocalstorageAndNavigate('genre')
  }
}

export const setInstrumentFilter = (instrument) => {
  if(instrument) {
    addFilterToLocalStorageAndNavigate(instrument, 'instrument')
  } else {
     removeFilterFromLocalstorageAndNavigate('instrument')
  }
}

export const setLocationFilter = (location) => {
  if(location) {
    addFilterToLocalStorageAndNavigate(location, 'location')
  } else {
     removeFilterFromLocalstorageAndNavigate('location')
  }
}

export const setMapFilter = (mapFilter) => {
  if(mapFilter) {
    // append map to subnoun filter
    mapFilter += '-map'
    
    addFilterToLocalStorageAndNavigate(mapFilter, 'noun')
  }
}

const addFilterToLocalStorageAndNavigate = (filter, filterType) => {
  let urlFiltersJSON = JSON.parse(store[Config.URL_FILTERS])
  urlFiltersJSON[filterType] = filter

  // store url filters
  setStore(Config.URL_FILTERS, JSON.stringify(urlFiltersJSON))
  getUrlPathAndNavigateToFilters()
}


const removeFilterFromLocalstorageAndNavigate = (filterType) => {
  let urlFiltersJSON = JSON.parse(store[Config.URL_FILTERS])

  delete urlFiltersJSON[filterType]

  // store url filters
  setStore(Config.URL_FILTERS, JSON.stringify(urlFiltersJSON))

  getUrlPathAndNavigateToFilters()
}

const getUrlPathAndNavigateToFilters = async () => {
  const urlFiltersJSON = JSON.parse(store[Config.URL_FILTERS])
  const response = await APIService.getRequest({
    url:'/api/v1/discover/url-path-from-filters',
    params: urlFiltersJSON, 
    addAuthHeader:false,
    allowAPIDataCaching: true, 
    returnAPICachedData: true
  })

  if (response && response?.success) {
    let path = await response.path 
    if(path && path != '') {
      if(path.charAt(0) == '/') {
        path = path.slice(1)
      }
      if(path.charAt(path.length - 1) == '/') {
        path = path.slice(0, -1);
      }

      constructUrlFromPathAndNavigate(path)
    }
  }

  return Promise.resolve()
}


export const constructUrlFromPathAndNavigate = (path) => {
  let urlPartsArray = [
    window.location.origin,
    path,
    window.location.search
  ]

  let urlToNavigateTo = urlPartsArray.join('/')
  // Remove trailing slash
  if (urlToNavigateTo.endsWith('/')) {
    urlToNavigateTo = urlToNavigateTo.slice(0, -1)
  }
  let url = new URL(urlToNavigateTo)
  filterUrlToNavigateSubscriber.next({url : url.pathname})

  if(filterSelectionSubscriber) filterSelectionSubscriber.next(undefined)

}


export const initFilterUrlToNavigateObservable = () => {
  // handles filter selection events, sends these to subsribers
  filterUrlToNavigateObservable = new Observable((subscriber) => {
    filterUrlToNavigateSubscriber = subscriber
  })
}

export const initFilterSelectionObservable = () => {
  // handles filter selection events, sends these to subsribers
  filterSelectionObservable = new Observable((subscriber) => {
    filterSelectionSubscriber = subscriber
  })
}

export const initMapFiltersObservable = () => {
  // handles map filter noun updates upon loading content on the discover page, sends these to subsribers
  mapFiltersObservable = new Observable((subscriber) => {
    mapFiltersSubscriber = subscriber
  })
}

export const initSearchTextObservable = () => {
  // handles search text input updates, notifies discover page via subscriber to allow fetching of updated search results
  searchTextObservable = new Observable((subscriber) => {
    searchTextSubscriber = subscriber
  })
}

export const getUrlPathArray = () => {
  let path = window.location.pathname.trim()
  if(path.charAt(0) == '/') {
    path = path.slice(1)
  }
  if(path.charAt(path.length - 1) == '/') {
    path = path.slice(0, -1);
  }
  // sanitize genre in url to be excluded from / split
  if(path.includes('/genre/')) {
    path = path.replace('/genre/', '/genre-')
  }
  // check instrument in url to be excluded from / split
  if(path.includes('/inst/')) {
    path = path.replace('/inst/', '/inst-')
  }
  return path.split('/')
}


export const checkIfNounLocationInstrumentGenreFiltersInUrl = () => {
  let locGenreInstFilters = JSON.parse(store[Config.LOC_INSTR_GENRE_FILTERS])

  let pathArray = getUrlPathArray()


  let countries = []
  // add list of all countries to location filter search - not all are on locGenreInstFilters.locations
  for (let country of countriesRegions) {
    const regionName = regionNames.of(country.countryShortCode)
    const location = formatLocationFilterString(regionName)
    countries.push(location)
  }
  const locationsToSearch = countries.concat(locGenreInstFilters.locations);
  let filteredLocation = checkIfFilterInPath(pathArray, locationsToSearch)
  const location = filteredLocation.filtersFound
  pathArray = filteredLocation.newPathArray

  // check for genre
  let genre = ''
  pathArray.forEach((pathItem, index) => {
    if(pathItem.startsWith('genre-')) {
      genre =pathItem.slice(6)

      let newPathArray = []
      pathArray.forEach((pathItem) => {
        if(pathItem != genre) {
          newPathArray.push(pathItem)
        }
      })
      pathArray = newPathArray
    }
  })

  // check for instrument
  let instrument = ''
  pathArray.forEach((pathItem, index) => {
    if(pathItem.startsWith('inst-')) {
      instrument =pathItem.slice(5)

      let newPathArray = []
      pathArray.forEach((pathItem) => {
        if(pathItem != instrument) {
          newPathArray.push(pathItem)
        }
      })
      pathArray = newPathArray
    }
  })

  // check what nouns exist in path
  let pathNounString = 'artists'
  pathArray.forEach((pathItem, index) => {
    let nounExists
    let pathArrayIndex
    Config.ALL_NOUNS.forEach((noun) => {
      if(pathItem.includes(noun)) {
        nounExists = true
        pathArrayIndex = index
      }
    })
    if(nounExists) {
     pathNounString = pathArray[pathArrayIndex]
    }
  })

  return {
    noun: pathNounString,
    location: location,
    instrument: instrument,
    genre: genre
  }
}

const checkIfFilterInPath = (pathArray, allFilterItems) => {
  allFilterItems = [...new Set(allFilterItems)] // ensure only unique items

  // check if any of the filters exists in path
  let existingFilters = []
  allFilterItems.forEach((item) => {
    pathArray.forEach((pathItem) => {
      if(pathItem == item || pathItem.includes(item)) {
        if(!existingFilters.includes(pathItem)) {
          existingFilters.push(pathItem)
        }
      }
    })
  })
  
  // cleanse path by removing filters found (some items like pop and guitar exist in both instruments and genres)
  let newPathArray = []
  pathArray.forEach((pathItem) => {
    let filterExists
    existingFilters.forEach((existingFilter) => {
      if(pathItem == existingFilter) {
        filterExists = true
      }
    })
    if(!filterExists) {
      newPathArray.push(pathItem)
    }
  })

  return {
    filtersFound: existingFilters.join('/'),
    newPathArray: newPathArray
  }
}

export const getUrlFilters = async () => {
  return new Promise((resolve, reject) =>{
    let params = {
      info: ['instruments', 'genres', 'locations'],
    }
    
    APIService.getRequest({
      url:'/api/v1/dashboard/profile/autocomplete',
      params,
      addAuthHeader:false,
      allowAPIDataCaching:true,
      returnAPICachedData:true,
      onSuccess: (response)=>{
        resolve(response)
      },
      onError:(error)=>{
        resolve({})
      }
    })

  })
  .then((response) => {
    let locations = []
    let genres = []
    let instruments = []
    if(response && response?.success) {
      // filter unique
      locations = [...new Set(response.locations)]
      genres = [...new Set(response.genres)]
      instruments = [...new Set(response.instruments)]
    }
  
    response.locations = locations
    response.genres = genres
    response.instruments = instruments
  
    // store all location/genre/instrument filters
    if (store[Config.LOC_INSTR_GENRE_FILTERS]) {
      let locGenreInstFilters = JSON.parse(
        store[Config.LOC_INSTR_GENRE_FILTERS]
      )
  
      let sanitized_locations = []
      for (let i = 0; i < locations.length; i++) {
        let location = locations[i]
        location = formatLocationFilterString(location)
        sanitized_locations.push(location)
      }
  
      let sanitized_genres = []
      for (let i = 0; i < genres.length; i++) {
        let genre = genres[i]
        genre = formatGenreFilterString(genre)
        sanitized_genres.push(genre)
      }
  
      let sanitized_instruments = []
      for (let i = 0; i < instruments.length; i++) {
        let instrument = instruments[i]
        instrument = formatInstrumentFilterString(instrument)
        sanitized_instruments.push(instrument)
      }
  
      locGenreInstFilters['locations'] = sanitized_locations
      locGenreInstFilters['genres'] = sanitized_genres
      locGenreInstFilters['instruments'] = sanitized_instruments
      setStore(
        Config.LOC_INSTR_GENRE_FILTERS,
        JSON.stringify(locGenreInstFilters)
      )
  
      let filtersInUrl =
        checkIfNounLocationInstrumentGenreFiltersInUrl()
  
      let urlFiltersToStore = {}
  
      if (filtersInUrl.location != '') {
        filterSearchInputs.location = filtersInUrl.location
        urlFiltersToStore['location'] = filtersInUrl.location
      }
  
      if (filtersInUrl.genre != '') {
        filterSearchInputs.genre = filtersInUrl.genre
        urlFiltersToStore['genre'] = filtersInUrl.genre
      }
  
      if (filtersInUrl.instrument != '') {
        filterSearchInputs.instrument = filtersInUrl.instrument
        urlFiltersToStore['instrument'] = filtersInUrl.instrument
      }
  
      if (filtersInUrl.noun != '') {
        urlFiltersToStore['noun'] = filtersInUrl.noun
      }
  
      // store url filters
      setStore(Config.URL_FILTERS, JSON.stringify(urlFiltersToStore))
      setUrlFiltersLoaded(true)
    }

    return response;
  })

}

export const formatLocationFilterString = (location) => {
  location.trim()
  location = location.split(' ,').join(',')
  location = location.split(', ').join(',')
  location = location.replace(/\s+/g, '-')

  return location
}

export const formatGenreFilterString = (genre) => {
  genre.trim()
  genre = genre.replace(/\s+/g, '-').toLowerCase()

  return genre
}

export const formatInstrumentFilterString = (instrument) => {
  instrument.trim()
  instrument = instrument.replace(/\s+/g, '-').toLowerCase()

  return instrument
}