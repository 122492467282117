import { createSignal, Show } from "solid-js"
import "./player-controls.css"
import { Utils } from "../../../../../../utils/_index"
import { PlayerUtils, PlayerActions, EntityTypes } from '../../../utils/_index'
import PlaySkipWidget from "./widgets/play-skip-widget/play-skip-widget"
import SongTrackerWidget from "./widgets/song-tracker-widget/song-tracker-widget"
import VolumeControl from "../volume-control/volume-control"
import VisualizerControl from "../visualizer-control/visualizer-control"
import { A, useNavigate  } from "@solidjs/router";

export default ({}) => {
    const navigate = useNavigate()
    const [showActionButtonsDropup, setShowActionButtonsDropup] = createSignal(false)
    const [likingUnliking, setLikingUnliking] = createSignal(false)

    const likeUnlike = async () => {
        if(Utils.userLoggedIn() && PlayerUtils.songPlayingObject()) {
            setLikingUnliking(true)
            let success = await PlayerActions.likeUnlikeItem({
                entityType: EntityTypes.Song,
                entityId: PlayerUtils.songPlayingObject().id,
                fanned: PlayerUtils.songPlayingObject().fanned
            })
            if(success) {
                const newState = {...PlayerUtils.songPlayingObject(), fanned: !PlayerUtils.songPlayingObject().fanned}
                PlayerUtils.setSongPlayingObject(newState)
            }
            setLikingUnliking(false)
        }
    }

  
    return (
        <div 
        class="playerControlsContainer"
        classList={{
            'cursor-pointer': Boolean(PlayerUtils.songPlayerInstance())
        }}
        onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if(PlayerUtils.songPlayerInstance() && !PlayerUtils.visualizerOpen()) {
                PlayerUtils.setFullScreenPlayerOpen(true)
            }
        }}
        >
            {/* Song Details */}
            {/* Div invisible when no song playing to maintain player height */}
            <div
            class="songContainer"
            classList={{
                'hideSongContainer': !PlayerUtils.songPlayerInstance()
            }}>
                <img
                class="songImage"
                src={PlayerUtils.songPlayingObject() ? PlayerUtils.formatSongImagePath(PlayerUtils.songPlayingObject().artwork): null}
                classList={{
                    'rotate': PlayerUtils.songPlayerInstance() && !PlayerUtils.playerLoadingSong(),
                    'pauseRotation': PlayerUtils.songPlayerInstance() && PlayerUtils.songPaused()
                }}
                />
                <div class="songDetailsWrapper">
                    <a
                    class="songName"
                    href={PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().absolute_url: ''}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().absolute_url: '')
                    }}
                    >
                        {PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().title: ''}
                    </a>

                    <a
                    class="artistName"
                    href={PlayerUtils.songPlayingObject() ? `/@${PlayerUtils.songPlayingObject().artistKeyword}/music`: ''}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(PlayerUtils.songPlayingObject() ? `/@${PlayerUtils.songPlayingObject().artistKeyword}/music`: '')
                    }}
                    >
                        {PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().artistName: ''}
                    </a>
                </div>
                <div class="playerActionButtonsWrapper">
                    <Show when={Utils.browserUserAgentIsMobile()}>
                        <i
                        class="ri-arrow-down-s-line collapseIcon"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            PlayerUtils.setMobilePlayerCollapsed(true)
                        }}></i>
                    </Show>
                    <div class="songActionButtonsWrapper">
                        <i
                        class="ri-more-fill actionButtonsIcon"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowActionButtonsDropup(!showActionButtonsDropup())
                        }}></i>
                        <div
                        class="songActionButtons"
                        classList={{
                            'showActionButtonsDropup': showActionButtonsDropup()
                        }}>
                            {/* Visualizer - Mobile */}
                            <Show when={PlayerUtils.songPlayerInstance()}>
                                <VisualizerControl/>
                            </Show>
                            
                            {/* like */}
                            <Show when={!likingUnliking()} fallback={
                                <div class="spinner spinner--medium"></div>
                            }>
                                <i
                                data-bs-toggle={!Utils.userLoggedIn() ? 'modal': ''}
                                data-bs-target={!Utils.userLoggedIn() ? '#modalLogin': '#'}
                                classList={{
                                    'ri-heart-fill': PlayerUtils.songPlayingObject() && PlayerUtils.songPlayingObject().fanned,
                                    'ri-heart-line': PlayerUtils.songPlayingObject() && !PlayerUtils.songPlayingObject().fanned,
                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    likeUnlike()
                                }}></i>
                            </Show>
                            
                            {/* review */}
                            <div
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                PlayerActions.reviewItem({
                                    entityType: EntityTypes.Song,
                                    entityId: PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().id: '',
                                    title: PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().title: '',
                                    artistKeyword: PlayerUtils.songPlayingObject() ? PlayerUtils.songPlayingObject().artistKeyword: ''
                                })
                            }}>
                                <i class="ri-chat-1-line"></i>
                            </div>
                            
                            {/* share */}
                            <Show when={PlayerUtils.songPlayingObject()}>
                            <i
                            class="ri-share-fill"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                Utils.setShareItem({
                                    title: PlayerUtils.songPlayingObject().title,
                                    subtitle: `by ${PlayerUtils.songPlayingObject().artistName}`,
                                    entity: {
                                        id: PlayerUtils.songPlayingObject().id,
                                        type: EntityTypes.Song,
                                        absolute_url: PlayerUtils.songPlayingObject().absolute_url
                                    }
                                })
                                Utils.setShowShareModal(true)
                            }}
                            ></i>
                            </Show>
                        </div>
                    </div>
                </div>
            </div>

            {/* Controls */}
            <div class="centerDiv">
                {/* Play Skip Controls */}
                <PlaySkipWidget/>

                {/* Song tracker + Volume controls */}
                <div
                class="trackerVolumeControlsContainer"
                classList={{
                    'hideTrackerVolumeControls': !PlayerUtils.songPlayerInstance()
                }}>
                    {/* Song Tracker */}
                    <SongTrackerWidget/>

                    {/* Volume Controls */}
                    <VolumeControl showOnMobile={true}/>
                </div>
            </div>
        </div>
    )
}
