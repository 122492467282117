import { createResource, createSignal, createEffect } from 'solid-js'
import $ from 'jquery'
import { listingCardsColorArray } from '../config/color-config'
import * as ProfileUtils from '../components/pages/profile/profile-utils'
import { Subject } from 'rxjs'
import JSZip from 'jszip';
import moment from 'moment'
import ImageViewer from 'awesome-image-viewer'
import { HASHPACK_NETWORK } from './Constants'
import { createStore } from 'solid-js/store'
import { getJamRate, getMyWalletDetails } from '../services/api.service'
import {
  getHederaAccountDetails,
  getTokenDetails,
} from '../components/pages/dashboard/wallet/utils'
import { APIService } from '../services/_index'
import * as Filters from './filters'
import butterchurnPresets from 'butterchurn-presets'
import { PlayerUtils } from '../components/shared/player/utils/_index'
import { PlayerActions } from '../components/shared/player/utils/_index'
import Sortable from 'sortablejs'
import { toast } from 'solid-sonner'


export const [sidebarDivRef, setSidebarDivRef] = createSignal()
export const [isSidebarShowing, setIsSidebarShowing] = createSignal(false)
export const [userLoggedIn, setUserLoggedIn] = createSignal(false)
export const [userProfileData, setUserProfileData] = createSignal(null)
export const [userLoading, setUserLoading] = createSignal(false)
export const [currentPath, setCurrentPath] = createSignal(
  window.location.pathname
)

export const [profilePageLoading, setProfilePageLoading] = createSignal(false)
export const [musicDashboardPageLoading, setMusicDashboardPageLoading] =
  createSignal(false)
export const [kycDashboardPageLoading, setKycDashboardPageLoading] =
  createSignal(true)
export const [profileDashboardPageLoading, setProfileDashboardPageLoading] =
  createSignal(false)
export const [visualsDashboardPageLoading, setVisualsDashboardPageLoading] =
  createSignal(false)
export const [musicDashboardDragAndDropOngoing, setMusicDashboardDragAndDropOngoing] = createSignal(false)
export let musicDashboardObserverSubject,
  profilePageObserverSubject,
  dashboardProfilePageObserverSubject
export const [buyJAMDashboardPageLoading, setBuyJAMDashboardPageLoading] =
  createSignal(true)
export const [walletData, setWalletData] = createStore()
export const [tokenData, setTokenData] = createStore()
export const [tokenDataLoading, setTokenDataLoading] = createSignal(false)
export const [selectedWallet, setSelectedWallet] = createSignal('tune')
export const [wallet, setWallet] = createSignal()
export const [jamRate] = createResource(getJamRate)
export const [saucerSwapTokens, setSaucerSwapTokens] = createSignal([])
export const [pageTitle, setPageTitle] = createSignal('')
export const [shareItem, setShareItem] = createSignal()
export const [showShareModal, setShowShareModal] = createSignal(false)
export const [lastTimeOfMouseMovement, setLastTimeOfMouseMovement] = createSignal<Date>(new Date())
export const [browserUserAgentIsMobile, setBrowserUserAgentIsMobile] =
  createSignal(false)
export const [browserUserAgentIsTablet, setBrowserUserAgentIsTablet] =
  createSignal(false)
export const pagesToHideAppDownloadPrompt = [
  'player/mobile-app-viz',
  'kyc/desktop-app-sumsub-verification-page',
]

export const [profileName, setProfileName] = createSignal('')
export const [profileType, setProfileType] = createSignal('')
export const [pageSwitch, setPageSwitch] = createSignal(false) // Set true when page switch is triggered
export const [socketProtocol, setSocketProtocol] = createSignal(window.location.protocol === 'https:' ? 'wss:' : 'ws:')
export const [socketHost, setSocketHost] = createSignal(`${socketProtocol()}${window.location.host}`)

export const [timer, setTimer] = createSignal(0)
export const POPUP_TRASHOLD = 30
export const [showLoginModal, setShowLoginModal] = createSignal(false)
export const [urlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh, setUrlsWithCachedAPIDataTobeInvalidDatedOnPageRefresh] = createSignal([])
export const [randomItemToPlay, setRandomItemToPlay] = createStore(null)

export const hideSidebar = () => {
  if(sidebarDivRef()) {
    sidebarDivRef().style.display = 'none'
    setIsSidebarShowing(false)
  }
}

export const showSidebar = () => {
  if(sidebarDivRef()) {
    sidebarDivRef().style.display = 'flex'
    setIsSidebarShowing(true)
  }
}

export const showToast = (message, toastType, durationInMs) => {
  if(toastType == 'success') {
    toast.success(message)
  } else if(toastType == 'danger' || toastType == 'error') {
    toast.error(message)
  } else if(toastType == 'warning') {
    toast.warning(message)
  } else if(toastType == 'info') {
    toast.info(message)
  } else {
    toast(message)
  }
}

export const initMusicDashboardObserverSubject = () => {
  // handles music dashboard page selection events
  musicDashboardObserverSubject = new Subject()
}

export const initProfilePageObserverSubject = () => {
  // handles profile page events
  profilePageObserverSubject = new Subject()
}

export const initDashboardProfilePageObserverSubject = () => {
  // handles dashboard profile page events
  dashboardProfilePageObserverSubject = new Subject()
}

export function navigateTo(dst, jsonData = {}) {
  if (Object.keys(jsonData).length > 0) {
    window.ajaxGo(dst, jsonData)
  } else {
    window.ajaxGo(dst)
  }
}

export function replaceState(url, data = {}) {
  if (location.pathname != url) {
    window.history.replaceState(data, '', url)
  }
}

// Path Matcher
type Params = Record<string, string>

interface PathMatch {
  params: Params
  path: string
}

export function createPathMatcher(path: string, partial?: boolean) {
  const [pattern, splat] = path.split('/*', 2)
  const segments = pattern.split('/').filter(Boolean)
  const len = segments.length

  return (location: string): PathMatch | null => {
    const locSegments = location.split('/').filter(Boolean)
    const lenDiff = locSegments.length - len
    if (lenDiff < 0 || (lenDiff > 0 && splat === undefined && !partial)) {
      return null
    }

    const match: PathMatch = {
      path: len ? '' : '/',
      params: {},
    }

    for (let i = 0; i < len; i++) {
      const segment = segments[i]
      const locSegment = locSegments[i]

      if (segment[0] === ':') {
        match.params[segment.slice(1)] = locSegment
      } else if (
        segment.localeCompare(locSegment, undefined, {
          sensitivity: 'base',
        }) !== 0
      ) {
        return null
      }
      match.path += `/${locSegment}`
    }

    if (splat) {
      match.params[splat] = lenDiff ? locSegments.slice(-lenDiff).join('/') : ''
    }

    return match
  }
}

export const convertToSlug = (phrase) => {
  return phrase.toLowerCase().replace(/ /g, '-');
}


export const toggleSidebarDisplay = () => {
  if(isSidebarShowing()) {
    hideSidebar()
  } else {
    showSidebar()
  }
}

export const closeSidebarMobile = () => {
  if (browserUserAgentIsMobile()) {
    hideSidebar()
  }
}

export function docReady(fn) {
  // if DOM already available
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

export const validateCaptcha = async (recaptcha_site_key) => {
  return new Promise((res, rej) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(recaptcha_site_key, {}).then((token) => {
        return res(token)
      })
    })
  })
}

export const getHashscanUrl = (nft_id) => {
  if (HASHPACK_NETWORK === 'testnet') {
    return 'https://hashscan.io/testnet/token/' + nft_id
  } else {
    return 'https://hashscan.io/mainnet/token/' + nft_id
  }
}

export const getHashscanAccountUrl = (nft_id) => {
  if (HASHPACK_NETWORK === 'testnet') {
    return 'https://hashscan.io/testnet/account/' + nft_id
  } else {
    return 'https://hashscan.io/mainnet/account/' + nft_id
  }
}

export const getIpfsUrl = (ipfs_url) => {
  if (window.ipfs && window.ipfs.enable) {
    return ipfs_url
  } else {
    if (!ipfs_url) return ''
    // If the ipfs_url is a hash, return the nftstorage.link url
    if (ipfs_url.length === 46)
      return `https://${ipfs_url}.ipfs.nftstorage.link`
    return `https://${ipfs_url.split('/').pop()}.ipfs.nftstorage.link`
  }
}

export const colorizeListingsBackgrounds = () => {
  // randomize background colors of listing cards without images
  let carouselContainers = document.querySelectorAll('.carouselContainer')
  let gridContainers = document.querySelectorAll('.grid-container')
  let solidMasonryGrids = document.querySelectorAll('.solid-masonry-grid')
  let playlistTabs = document.querySelectorAll('.playlistTab')
  let directoryListing = document.getElementById('directory-list')

  if (
    directoryListing ||
    carouselContainers.length > 0 ||
    gridContainers.length > 0 ||
    solidMasonryGrids.length > 0 ||
    playlistTabs.length > 0
  ) {
    $('.image-wrapper .listings-image-placeholder').each(
      function(index, element) {
        // const randomColor = Math.floor(Math.random()*16777215).toString(16)
        const randomGradient = getRandomBackgroundGradient()
        if (isTransparent($(this).css('background'))) {
          $(this).css('background', randomGradient)
        }
      }
    )
  }

  function isTransparent(color) {
    switch ((color || '').replace(/\s+/g, '').toLowerCase()) {
      case 'transparent':
      case '':
      case 'rgba(0,0,0,0)':
      case 'rgba(0,0,0,0)nonerepeatscroll0px0px/autopadding-boxborder-box':
      case 'rgba(0,0,0,0)nonerepeatscroll0%0%/autopadding-boxborder-box':
        return true
      default:
        return false
    }
  }
}

export const getRandomBackgroundGradient = () => {
  const randomGradient =
    listingCardsColorArray[
    Math.floor(Math.random() * listingCardsColorArray.length)
    ]
  return randomGradient.bgGradient
}

export const getRandomListingsBgGradients = (listings) => {
  let bgGradients = []
  if(!listings) return bgGradients
  listings.forEach((item) => {
    bgGradients.push(getRandomBackgroundGradient())
  })

  return bgGradients
}

const getOffsetTop = (element) => {
  var bodyRect = document.body.getBoundingClientRect(),
    elemRect = element.getBoundingClientRect(),
    offset = elemRect.top - bodyRect.top

  return offset
}

export const deepMerge = (defaults, saved) => {
  // `deepMerge` recursively merges two objects, overwriting the properties of the first object with
  // values from the second object. If a key is an object, it will recursively merge the properties
  // of that child object. If a key is an array, the second object's array will overwrite the first's.
  for (let key in defaults) {
    if (typeof defaults[key] === 'object' && typeof saved[key] === 'object') {
      saved[key] = deepMerge(defaults[key], saved[key])
    } else if (
      saved[key] === undefined ||
      typeof saved[key] !== typeof defaults[key]
    ) {
      saved[key] = defaults[key]
    }
  }
  return saved
}

export const getToday = () => {
  // get today's date in ISO format, but hour is set to 1 hour from now
  const now = new Date()
  now.setHours(now.getHours() + 1)
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
  now.setMilliseconds(null)
  now.setSeconds(null)
  return now.toISOString().substring(0, 16)
}

export const get30DaysLater = () => {
  const now = new Date()
  now.setHours(now.getHours() + 1)
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
  now.setMilliseconds(null)
  now.setSeconds(null)
  now.setDate(now.getDate() + 30)
  return now.toISOString().substring(0, 16)
}

// custom scrollIntoView function due to inconsitent functionality with JS's scrollIntoView
export const scrollIntoView = (parent, child) => {
  const parentBounding = parent.getBoundingClientRect(),
    clientBounding = child.getBoundingClientRect()

  const parentBottom = parentBounding.bottom,
    parentTop = parentBounding.top,
    clientBottom = clientBounding.bottom,
    clientTop = clientBounding.top

  scrollTo(parent, -(parentTop - clientTop), 300)
}

function scrollTo(element, to, duration) {
  let start = element.scrollTop,
    currentTime = 0,
    increment = 20

  let animateScroll = function() {
    currentTime += increment

    let val = easeInOutQuad(currentTime, start, to, duration)
    element.scrollTop = val

    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }

  animateScroll()
}

export function scrollToTop(smoothAnimation = false) {
  document.getElementById('singlePageWrapper').scrollTo({ top: 0, behavior: smoothAnimation ? 'smooth' : 'instant' })
}
// Function for smooth scroll animation with the time duration
function easeInOutQuad(time, startPos, endPos, duration) {
  time /= duration / 2

  if (time < 1) return (endPos / 2) * time * time + startPos

  time--
  return (-endPos / 2) * (time * (time - 2) - 1) + startPos
}

export const navigateToMyLibrary = (profileKeyword, record_id) => {
  let path = window.location.pathname.trim()
  if(!path.includes(`/@${profileKeyword}`)) {
    Filters.filterUrlToNavigateSubscriber.next({url : `/@${profileKeyword}/#library/${record_id}`})
  } else {
    Filters.filterUrlToNavigateSubscriber.next({url : `/@${profileKeyword}/#library/${record_id}`})
  }
  ProfileUtils.profileSectionSelectionSubscriber?.next({
    section: 'library',
    record_id: record_id,
  })
}

export const getAlbumLength = (songs) => {
  let totalLength = 0
  for (let i = 0; i < songs.length; i++) {
    if (songs[i].length && songs[i].entity.length != '') {
      totalLength += songs[i].entity.length
    }
  }

  const h = Math.floor(totalLength / 3600)
  const m = Math.floor((totalLength % 3600) / 60)
  const s = Math.floor((totalLength % 3600) % 60)

  const hDisplay = h > 0 ? h + (h == 1 ? ' hr, ' : ' hrs, ') : ''
  const mDisplay = m > 0 ? m + (m == 1 ? ' min' : ' mins') : ''

  // since some lengths are empty strings
  const length = hDisplay + mDisplay
  if (length != '') {
    return `, ${length}`
  } else {
    return ''
  }
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalizeString = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  )
export const isValidEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const isValidURL = (str) => {
  try {
    new URL(str)
    return true
  } catch (e) {
    return false
  }
}


export const getFormattedSongLength = (secs) => {
  if (secs >= 3600) {
    return moment().startOf('day').seconds(secs).format('HH:mm:ss')
  }

  return moment().startOf('day').seconds(secs).format('mm:ss')
}

export const formatJamAmount = (amount, precision = 8) => {
  amount = amount / 100000000
  const base = 10 ** precision
  amount = (Math.round(amount * base) / base).toFixed(precision)
  return amount
}

export const jamToUSDAmount = (amount, jamPrice, precision = 8) => {
  amount = (amount / 100000000) * jamPrice
  const base = 10 ** precision
  amount = (Math.round(amount * base) / base).toFixed(precision)
  return amount
}

export function deviceIsiOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const parseUnlockableText = (text) => {
  // This takes the unlockable text, and extracts ipfs://bafy... URLs using regex, then returns normal text and an array of URLs
  let regex = /(ipfs:\/\/[a-zA-Z0-9]*)/g
  let matches = text.match(regex)
  let urls = []
  if (matches) {
    urls = matches.map((match) => {
      return match
    })
  }
  let textWithoutUrls = text.replace(regex, '')
  return { textWithoutUrls, urls }
}

export const getWalletDataAndTokenData = async () => {
  setTokenDataLoading(true)
  let myWallet = await getMyWalletDetails()
  if (myWallet && myWallet.success) {
    if (myWallet.hedera_account_id) {
      let hederaAccountDetails = await getHederaAccountDetails(
        myWallet.hedera_account_id
      )
      setWalletData({ tune: hederaAccountDetails })
      if (hederaAccountDetails.balance) {
        for (let token of hederaAccountDetails.balance.tokens) {
          let tokenDetails = await getTokenDetails(token.token_id)
          setTokenData({ [tokenDetails.token_id]: tokenDetails })
        }
      }
    }
    setWallet(myWallet)
    setTokenDataLoading(false)
  }
}

export const getSaucerSwapPool = async () => {
  const response = await fetch('https://api.saucerswap.finance/pools')
  return await response.json()
}

export const getAllSaucerSwapTokens = async () => {
  await APIService.getRequest({url:'/api/v1/trade-jam/saucerswap-tokens',
  allowAPIDataCaching: true,
  returnAPICachedData: true,
    onSuccess: (response) => {
      if(response && response?.success) {
        let { tokens } = response
        setSaucerSwapTokens(tokens)
      }
    }, onError: (error) => {
      setSaucerSwapTokens([])
    }
  })

}

export const isEmptyObject = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false
    }
  }

  return true
}

export const getQueryParams = () => {
  let params = {}
  let query = window.location.search.substring(1)
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  }
  return params
}

export const getNewAccessToken = async () => {
  let newAccessToken = await getAccessToken()
  return Promise.resolve(newAccessToken) // get a new token from your backend
}

export const getAccessToken = async () => {
  //get access token
  let response = await APIService.getRequest({url:'/api/v1/access-token', params:{
    applicantLevel: 'basic-kyc-and-phone-address-level',
  }})
  return response
}

 export const createZipFile = (files, zip_folder_name) => {
    // Create a new JSZip instance
    const zip = new JSZip();
    // Add files to the zip
    files.forEach(file => {
        zip.file(file.filename, file.content);
    });

    // Generate the ZIP file
    zip.generateAsync({ type: 'blob' }).then(blob => {
        // Create a download link and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = zip_folder_name;
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    });
};


export const addClass = (elementId, className) => {
  let element = document.getElementById(elementId)
  element.classList.add(className)
}

export const removeClass = (elementId, className) => {
  let element = document.getElementById(elementId)
  element.classList.remove(className)
}

export const removeClosestClass = (elementId, className) => {
  let element = document.getElementById(elementId)
  var closestDivWithClass = element.closest(`.${className}`); // Find the closest ancestor with className
  if (closestDivWithClass) {
    closestDivWithClass.classList.remove(className); // Remove className
  }
}

export const shuffleArray = (array) => {
  let count = array.length, randomnumber, temp
  while(count) {
    randomnumber = Math.random() * count-- | 0
    temp = array[count]
    array[count] = array[randomnumber]
    array[randomnumber] = temp
  }
  return array
}

export const getMouseDistancesFromEdgeOfDiv = (event, rect) => {
  // rect arg should be the result of div.getBoundingClientRect()
  const mouseX = event.clientX
  const mouseY = event.clientY

  const distanceFromLeft = mouseX - rect.left
  const distanceFromRight = rect.right - mouseX
  const distanceFromTop = mouseY - rect.top
  const distanceFromBottom = rect.bottom - mouseY

  const distances = {
      left: distanceFromLeft,
      right: distanceFromRight,
      top: distanceFromTop,
      bottom: distanceFromBottom
  }

  return distances
}

export const getAllVisualizerPresetsAsArray = () => {
  // get all visualizer presets
  const allPresetsObject = butterchurnPresets.getPresets()
  let presetsList = []
  for (const [key, value] of Object.entries(allPresetsObject)) {
    presetsList.push(
      {
        name: key,
        item: value
      }
    )
  }
  // shuffle presets array
  presetsList = shuffleArray(presetsList)
  return presetsList
}


export const preloadSiteWideData = () => {
  if(userLoggedIn()) {
    preloadPlayerData()
  }
}

export const preloadPlayerData = (loadPlaylist = true) => {
  if(userLoggedIn()) {
    if(loadPlaylist) PlayerUtils.getPlayQueue()
    PlayerUtils.getJamBalance()
  }
}

export const getTranslateYValueFromTransformMatrix = (matrixValue) => {
  // Split the matrix value into individual components
  var matrixComponents = matrixValue.match(/matrix\((.+)\)/)[1].split(', ')

  // The translateY value is the 6th component of the matrix
  var translateYValue = parseFloat(matrixComponents[5])

  return translateYValue;
}

let intervalId
createEffect(() => {
  if (!userLoggedIn() && timer() < POPUP_TRASHOLD && PlayerUtils.songPlayingObject()) {
    clearInterval(intervalId);

    intervalId = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  } else {
    clearInterval(intervalId);
  }
});


export const triggerLoginModal = () => {
    const modalElement = document.getElementById('modalLogin')
    if (modalElement) {
      modalElement.classList.remove('dont-show')
      modalElement.classList.add('show')
    }
};


createEffect(() => {
      if (!userLoggedIn() && !showLoginModal() && timer() >= POPUP_TRASHOLD) {
        setShowLoginModal(true)
        clearInterval(intervalId)
        if(PlayerUtils.songPlayerInstance().playing()) {
            PlayerUtils.songPlayerInstance().pause()
        }
        triggerLoginModal()
      }
  });

export const createIntArray = (length) => Array.from({ length }, (_, index) => index)

export const initSinglesDragAndDrop = (dragAndDropDivId, singles, setSingles) => {
  let el = document.getElementById(dragAndDropDivId)
  if (!el) {
    return false
  }

  let sortable;
  // Initilize sortable
    sortable = new Sortable.create(el, {
      group: dragAndDropDivId, // make group name unique for each section to prevent confliits
      handle: '.song-drag-handle-icon',
      animation: 150,
      scroll: true, // Enable the plugin. Can be HTMLElement.
      forceAutoscrollFallback: true, // force autoscroll plugin to enable even when native browser autoscroll is available
      forceFallback: true, // also enables forceAutoscrollFallback: true
      scrollSensitivity: 100, // px, how near the mouse must be to an edge to start scrolling. forceFallback: true option must be set
      scrollSpeed: 20, // px, speed of the scrolling
      bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
      setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
      },
      // Element is chosen
      onChoose: function (/**Event*/evt) {
        evt.oldIndex;  // element index within parent
      },

      // Element is unchosen
      onUnchoose: function (/**Event*/evt) {
        // same properties as onEnd
      },

      // Element dragging started
      onStart: function (/**Event*/evt) {
        // evt.oldIndex;  // element index within parent
        setMusicDashboardDragAndDropOngoing(true)
      },

      // Element dragging ended
      onEnd: (/**Event*/evt) => {
        const order = sortable.toArray();
        setMusicDashboardDragAndDropOngoing(false)
        removeClass(dragAndDropDivId, 'dragAndDropDivBorder')

        let elementId = evt.item.id;  // dragged HTMLElement

        // Since area the elementId was dragged to may outside where it was dragged from
        // remove closed drag area border where the class was dragged to
        removeClosestClass(elementId, 'dragAndDropDivBorder')

        const currentOrder = Array.from(el.children).map(item => item.id)
        const items = currentOrder.map((itemId) => ({
          id: itemId.split('-')[1],
          position: currentOrder.indexOf(itemId) + 1
        }))

        const updatedItemsList = [...singles()]
        const itemMap = new Map(updatedItemsList.map(item => [item.entity.id, item]))
        const newOrder = items.map(item => itemMap.get(parseInt(item.id)))

        if (elementId.split("-")[0] === "album") {
          reorderAlbums(items)
        } else {
          let songId = elementId.split('-')[1]
          let fromAlbumId = evt.from.id.split('-')[1]
          let toAlbumId = evt.to.id.split('-')[1]
          let position = evt.newIndex + 1
          // reorder songs
          reorderSongs(songId, toAlbumId, fromAlbumId, position)
        }
        setSingles(newOrder)
        sortable.sort(order);
      }
    })
  }
export const reorderSongs = async (songid, toAlbumId, fromAlbumId, position) => {
  await APIService.postRequest({url:'/api/v1/dashboard/music/reorder-songs', postData:{
    songid: songid,
    albumid: toAlbumId,
    n: position
  },
  onSuccess: (response) => {
    if(response && response.success) {
    }

  }
})

}

export const reorderAlbums = async (albums) => {
  await APIService.postRequest({url:'/api/v1/dashboard/music/album/reorder', postData:{
    albums: JSON.stringify(albums)
  },
  onSuccess: (response) => {
    if(response && response.success) {

    }

  }
})
}


/////////////////////
// IMAGE VIEWER UTILS
/////////////////////
export const openImageViewer = async (imageUrl) => {
  const currentSelected = 0
  const images = [
    {
      mainUrl: imageUrl,
      thumbnailUrl: imageUrl,
      description: '',
    }
  ]
  createImageViewer(
    images,
    currentSelected
  )
}

export const createImageViewer = (images, index) => {
  // This is a custom function that fixes image viewer image load bugs when using 'awesome-image-viewer' npm package.
  // The package was last updated in 2022 and contains unresolved bugs
  new ImageViewer({
    images: images,
    stretchImages: true,
    currentSelected: index,
    style: { background: '#111111' }
  });

  const imagesWrapper = document.getElementsByClassName('imagesWrapper')[0]
  if (imagesWrapper) {
      const imageContainers = imagesWrapper.children;

      // "Disable" lazyloading
      for (let i = 0; i < imageContainers.length; i++) {
          const imageContainer = imageContainers.item(i)
          const url = imageContainer.dataset.url
          const image = imageContainer.getElementsByClassName('image')[0]
          image.src = url
      }

      // Scroll to clicked image (selected by index)
      setTimeout(() => {
          const imageContainer = imageContainers.item(index);
          const imageCenterPosition = imageContainer.offsetLeft - (imagesWrapper.getBoundingClientRect().width - imageContainer.getBoundingClientRect().width)/2
          imagesWrapper.scrollTo({left: imageCenterPosition, behavior: 'instant'})
      }, 100)
  }
}
/////////////////////
// END IMAGE VIEWER UTILS
/////////////////////

export const isNotEmptyObjectOrArray = (value) => {
  if (Array.isArray(value)) {
      return value.length > 0;
  } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
  }else if (!value){
    //if null or undefined
    return false
  }
  return true; // Return true for non-object/non-array values
}



export const saveToLocalStorageWithExpiry = (key:string, data:any, ttl:number) => {
  const now = new Date();
  const item = {
    value: data,
    expiry: now.getTime() + ttl, // Expiry time in milliseconds
  };
  if(isNotEmptyObjectOrArray(data)) {
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export const getDataFromLocalStorageWithExpiry = (key:string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Check if item is expired
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}


export const clearAllCachedAPIdata = async () => {
  await caches.delete("tune-data-cache-v1")
}

export const clearCachedAPIdataForSpecificUrls = async (arrayOfSpecificUrls: string[]) => {
  // function takes list or urls whose cache you to invalidate
  const cache = await caches.open("tune-data-cache-v1");
  const keys = await cache.keys();
  const urls = keys.map(request => request.url);
  for (const url of urls) {
    if (arrayOfSpecificUrls.some(specificUrl => url.includes(specificUrl))) {
      await cache.delete(url);
    }
  }
}


export const clearAllCAchedHomeAndDiscoverListings = async () => {
    const homeAndDiscoverListingsUrls=[
      "/api/v1/discover/listings",
      "/api/v1/home/listings",
      "/api/v1/home/favorites-feed",
      "/api/v1/home/home-sections",
      "/api/v1/discover-page-context",
      "/api/v1/home/popular-playlists",
      "/api/v1/home/recommended-for-user",
      "/api/v1/home/user-recently-played",
    ]
    const cache = await caches.open("tune-data-cache-v1");
    const keys = await cache.keys();
    const urls = keys.map(request => request.url);
    for (const url of urls) {
      if (homeAndDiscoverListingsUrls.some(profileUrl => url.includes(profileUrl))) {
        await cache.delete(url);
      }
    }
}


export const onEnter = (callback) => (event) => {
  if (event.key === "Enter") {
    callback(event);
  }
};
