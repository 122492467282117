import { PlayerUtils, PlayerActions } from '../../../utils/_index'
import { IoRepeatOutline } from "solid-icons/io"; // IonIcons

export default ({}) => {

  return (
    <IoRepeatOutline
    class="repeatIcon"
    classList={{
      '!text-[#7c7c7c]': !PlayerUtils.repeatOn(),
      '!text-white': PlayerUtils.repeatOn()
    }}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      PlayerActions.toggleRepeat()
    }}/>
  )
}
